/* eslint-disable array-callback-return */
import { useEffect, useRef } from "react";
import moment from "moment";
import { Bill } from "../store/types/bill";
import { translations } from "./translations";

export const capitalize = (s: string): string => {
  return s
    ? s.length > 1
      ? s.charAt(0).toUpperCase() + s.slice(1).toLowerCase()
      : s.toUpperCase()
    : "";
};

export const clone = (item: any) => JSON.parse(JSON.stringify(item));

export function addValueToArray(arr: string[], what: string) {
  if (what !== "" && !arr.includes(what)) {
    arr.push(what);
  }
  return arr;
}

export function removeValueFromArray(arr: string[], what: string) {
  let ax;
  while ((ax = arr.indexOf(what)) !== -1) {
    arr.splice(ax, 1);
  }

  return arr;
}

export const useInterval = (callback: () => void, delay: number) => {
  const savedCallback = useRef<typeof callback | null>(null);

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    function tick() {
      if (savedCallback.current) {
        savedCallback.current();
      }
    }
    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [callback, delay]);
};

export function displayShipmentStatus(status: string, savedLang: string) {
  let shipmentStatus =
    status === "receiving_offers" || status === "processing"
      ? savedLang === "ar"
        ? "معلقة"
        : "Pending"
      : status === "planned"
      ? savedLang === "ar"
        ? "مسواة"
        : "Planned"
      : status === "going"
      ? savedLang === "ar"
        ? "قيد الشحن"
        : "On Going"
      : status === "completed"
      ? savedLang === "ar"
        ? "مكتملة"
        : "Completed"
      : status === "cancelled"
      ? savedLang === "ar"
        ? "ملغاة"
        : "Cancelled"
      : capitalize(status);

  return shipmentStatus;
}

export function displayItemType(
  item: string,
  savedLang: string,
  number?: number
) {
  let itemName =
    savedLang === "ar"
      ? item === "pallet"
        ? "لوح تحميل"
        : item === "box"
        ? "صندوق"
        : item === "container"
        ? "حاوية"
        : item === "roll"
        ? "لفافة"
        : "نوع آخر"
      : capitalize(item);

  if (savedLang === "ar" && number && number > 1) {
    if (item === "pallet") itemName = "ألواح تحميل";
    else if (item === "box") itemName = "صناديق";
    else if (item === "container") itemName = "حاويات";
    else if (item === "roll") itemName = "لفات";
  } else if (savedLang !== "ar" && number && number > 1) {
    if (item === "box") itemName = itemName + "es";
    else itemName = itemName + "s";
  }

  return itemName;
}

export function displayBillStatus(bill: Bill) {
  let billStatus = bill.paid
    ? "paid"
    : moment(bill.startDate).format() ===
      moment(getActiveBillDate().start).format()
    ? "active"
    : "late";

  return billStatus;
}

export function isTruckBusy(truckId: string, date: any, offers: any) {
  offers &&
    offers
      .filter((off: any) => off.status === "accepted")
      .map((offer: any) => {
        if (
          moment(offer.deliveringTime).format("DD/MM/yyyy") ===
            moment(date).format("DD/MM/yyyy") &&
          offer.truckId === truckId
        )
          return true;
      });

  return false;
}

export function isDriverBusy(driverId: string, date: any, offers: any) {
  offers &&
    offers
      .filter((off: any) => off.status === "accepted")
      .map((offer: any) => {
        if (
          moment(offer.deliveringTime).format("DD/MM/yyyy") ===
            moment(date).format("DD/MM/yyyy") &&
          offer.driverId === driverId
        )
          return true;
      });

  return false;
}

export function getActiveBillDate() {
  const day = moment().format("DD");
  let start_date;

  if (Number(day) < 25) {
    start_date = moment().subtract(1, "M").format("25/MM/yyyy");
  } else {
    start_date = moment().format("25/MM/yyyy");
  }
  start_date = moment(start_date, "DD/MM/yyyy");
  let end_date;
  end_date = moment(start_date)
    .add(1, "months")
    .subtract(1, "days")
    .endOf("day")
    .format();
  start_date = start_date.format();

  const start = new Date(start_date);
  const end = new Date(end_date);

  return {
    start: start,
    end: end,
  };
}

export function getToastMessage(data: any, savedLang: "en" | "ar" = "en") {
  const content = translations[savedLang];
  if (typeof data === "string") return content[data] || data;
  let errorMessage =
    data.response?.data?.error ||
    data.response?.data?.message ||
    data.error ||
    data.message;

  return content[errorMessage] || errorMessage || content["unknown_error"];
}

export const getContractStatusColor = (status: string) => {
  switch (status) {
    case "receiving_offers":
      return "info";
    case "ongoing":
      return "success";
    case "ended":
      return "warning";
    case "cancelled":
      return "danger";
    default:
      return "info";
  }
};

export const isPdf = (file: string) => {
  let fileSplit = file.split(".");
  if (fileSplit[fileSplit.length - 1] === "pdf") return true;
  return false;
};
