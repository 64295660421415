import { AuthState, AuthAction, SET_USER, SET_LOADING, SET_GLOBAL_LOADING, LOG_OUT, SET_ERROR, SET_SUCCESS, SET_LOGIN_SUCCESS } from '../types/auth';

const initState: AuthState = {
    user: null,
    authenticated: false,
    loading: false,
    globalLoading: true,
    error: '',
    success: '',
}

const authReducer = (state = initState, action: AuthAction) => {
    switch (action.type) {
        case SET_USER:
            return {
                ...state,
                user: action.payload,
                authenticated: true,
                globalLoading: false,
            }
        case SET_LOADING:
            return {
                ...state,
                loading: action.payload
            }
        case SET_GLOBAL_LOADING:
            return {
                ...state,
                globalLoading: action.payload
            }
        case LOG_OUT:
            localStorage.removeItem("token");
            return {
                ...state,
                loading: false,
                user: null,
                authenticated: false,
            }
        case SET_LOGIN_SUCCESS:
            localStorage.setItem("token", action.payload.token);
            return {
                ...state,
                authenticated: true,
                loading: false,
                error: "",
                user: action.payload.userData
            }
        case SET_ERROR:
            return {
                ...state,
                error: action.payload,
            }
        case SET_SUCCESS:
            return {
                ...state,
                success: action.payload,
            }
        default:
            return state;
    }
}

export default authReducer;