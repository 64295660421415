/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect } from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import Select from 'react-select';
import { customStyles } from '../utils/const';
import ReactPaginate from 'react-paginate';
import { useSelector } from "react-redux";
import { RootState } from "../store";

const Pagination: FC<any> = (props) => {

    const { lang } = useSelector((state: RootState) => state.lang);

    let { pagination, setPagination, initPaginatationState, sorted } = props;

    const showPerPage = (number: number) => {
        let output =
            lang === "ar" ?
                `عرض ${number} في كل صفحة`
                : `Show ${number} per page`
        return output;
    }

    const selectOptions = [
        { value: 5, label: showPerPage(5), color: '#00B8D9' },
        { value: 10, label: showPerPage(10), color: '#00B8D9' },
        { value: 15, label: showPerPage(15), color: '#00B8D9' },
        { value: 20, label: showPerPage(20), color: '#00B8D9' },
    ]

    useEffect(() => {
        setPagination((prevState: any) => ({
            ...prevState,
            pageCount: Math.ceil(prevState.data.length / prevState.numberPerPage),
            currentData: prevState.data.slice(pagination.offset, pagination.offset + pagination.numberPerPage)
        }))
    }, [pagination.numberPerPage, pagination.offset])

    useEffect(() => {
        setPagination({
            ...initPaginatationState,
            data: sorted,
            pageCount: Math.ceil(sorted.length / initPaginatationState.numberPerPage),
            currentData: sorted.slice(pagination.offset, pagination.offset + pagination.numberPerPage)
        })
    }, [sorted])

    const handlePageClick = (event: any) => {
        const selected = event.selected;
        const offset = selected * pagination.numberPerPage
        setPagination({ ...pagination, offset })
    }

    const handleSelectChange = (e: any) => {
        const val = e ? e.value : 10
        setPagination({ ...pagination, numberPerPage: Number(val) })
    }

    return (
        <div className="col-12 my-4">
            <div className="d-flex flex-column flex-md-row align-items-center justify-content-end py-2 px-3 pagination-wrapper">
                <Select
                    className="page-select mb-3 mb-md-0"
                    classNamePrefix="select"
                    isSearchable={false}
                    value={selectOptions.filter((option: any) => option.value === pagination.numberPerPage)}
                    options={selectOptions}
                    styles={customStyles}
                    onChange={handleSelectChange}
                />
                <ReactPaginate
                    previousLabel={<FontAwesomeIcon icon={lang === "ar" ? faChevronRight : faChevronLeft} />}
                    nextLabel={<FontAwesomeIcon icon={lang === "ar" ? faChevronLeft : faChevronRight} />}
                    breakLabel={'...'}
                    pageCount={pagination.pageCount}
                    marginPagesDisplayed={1}
                    pageRangeDisplayed={1}
                    onPageChange={handlePageClick}
                    nextClassName={'next-paginate'}
                    previousClassName={'prev-paginate'}
                    containerClassName={"pagination"}
                    previousLinkClassName={"pagination__link"}
                    nextLinkClassName={"pagination__link"}
                    disabledClassName={"pagination__link--disabled"}
                    activeClassName={"pagination__link--active"}
                />
            </div>
        </div>
    )
}

export default Pagination;