import { OfferState, RESET_OFFER, RESET_OFFERS, OfferAction, SET_OFFER, SET_OFFER_REJECTED, SET_OFFER_LOADING, SET_ERROR, SET_SUCCESS, SET_OFFERS, SET_IS_ADDING_OFFER, DELETE_OFFER, SET_OFFER_DELETED, SET_OFFER_UPDATED } from '../types/offer';

const initState: OfferState = {
    offer: null,
    offers: null,
    isAdding: false,
    loading: false,
    offerDeleted: false,
    offerUpdated: false,
    offerRejected: false,
    error: '',
    success: '',
}

const offerReducer = (state = initState, action: OfferAction) => {
    switch (action.type) {
        case SET_OFFER_LOADING:
            return {
                ...state,
                loading: action.payload
            }
        case SET_IS_ADDING_OFFER:
            return {
                ...state,
                isAdding: action.payload
            }
        case RESET_OFFER:
            return {
                ...state,
                offer: null
            }
        case RESET_OFFERS:
            return {
                ...state,
                offers: null
            }
        case SET_OFFER:
            return {
                ...state,
                offer: action.payload
            }
        case SET_OFFERS:
            return {
                ...state,
                offers: action.payload
            }
        case SET_ERROR:
            return {
                ...state,
                error: action.payload,
            }
        case DELETE_OFFER:
            return {
                ...state,
                offerDeleted: true,
                offers: state.offers ? state.offers.filter(offer => offer._id !== action.payload) : []
            }
        case SET_OFFER_DELETED:
            return {
                ...state,
                offerDeleted: action.payload
            }
        case SET_OFFER_REJECTED:
            return {
                ...state,
                offerRejected: action.payload
            }
        case SET_OFFER_UPDATED:
            return {
                ...state,
                offerUpdated: action.payload
            }
        case SET_SUCCESS:
            return {
                ...state,
                success: action.payload,
            }
        default:
            return state;
    }
}

export default offerReducer;