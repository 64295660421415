export const SET_LANGUAGE = "SET_LANGUAGE";

export interface Lang {
    lang: 'en' | 'ar';
}

//actions
interface SetLangAction {
    type: typeof SET_LANGUAGE;
    payload: string;
}

export type LangAction = SetLangAction