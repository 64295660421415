import React, { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faBell,
  faChevronDown,
  faChevronUp,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import NotificationsList from "./NotificationsList";
import UserOptions from "./UserOptions";
import DefaultAvatar from "../../assets/images/blank.png";

import ".../../../src/styles/dashboard.scss";
import { serverBaseURI } from "../../utils/const";
import { tokenConfig } from "../../store/actions/authActions";
import axios from "axios";
import Loader from "../Loader";
import { translations } from "../../utils/translations";

const DashboardNavBar: FC<any> = (props: any) => {
  let { toggled, setToggled, logOut } = props;
  const { user } = useSelector((state: RootState) => state.auth);
  const { lang } = useSelector((state: RootState) => state.lang);

  const [content, setContent] = useState<any>(translations["en"]);
  const [loading, setLoading] = useState<boolean>(false);
  const [notifications, setNotifications] = useState<any>(null);
  const [userOptions, setUserOptions] = useState<boolean>(false);
  const [showNotifications, setshowNotifications] = useState<boolean>(false);

  useEffect(() => {
    if (lang === "ar") setContent(translations["ar"]);
    else setContent(translations["en"]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang]);

  const closeOptions = () => {
    setUserOptions(false);
  };

  const closeNotifs = () => {
    setshowNotifications(false);
  };

  const getMyNotifications = async () => {
    setLoading(true);
    let { data } = await axios.get(
      `/api/v0.1/notifications/my-notifications`,
      tokenConfig()
    );
    setNotifications(data);
    setLoading(false);
  };
  useEffect(() => {
    if (user) getMyNotifications();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 fixed-top shadow-sm">
      <button
        id="sidebarToggleTop"
        className="bawsala-btn btn-primary-border d-md-none rounded-circle mr-3"
        onClick={() => {
          setToggled(!toggled);
          localStorage.setItem("sidebarToggled", (!toggled).toString());
        }}
      >
        <FontAwesomeIcon icon={faBars} />
      </button>
      <ul className="navbar-nav">
        <li
          className={
            "nav-item dropdown no-arrow mx-1" +
            (showNotifications ? " show" : "")
          }
        >
          <Link
            className="nav-link dropdown-toggle"
            to="#"
            id="notificationsDropdown"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded={showNotifications}
            onClick={() => setshowNotifications(!showNotifications)}
          >
            <FontAwesomeIcon icon={faBell} className="fa-fw" />
            {notifications &&
              notifications.filter((n: any) => !n.isRead).length > 0 && (
                <span className="badge badge-primary badge-counter">
                  {notifications.filter((n: any) => !n.isRead).length < 100
                    ? notifications.filter((n: any) => !n.isRead).length
                    : "+99"}
                </span>
              )}
          </Link>
          {showNotifications &&
            (loading ? (
              <Loader height={75} width={75} style={{ marginTop: 30 }} />
            ) : (
              <NotificationsList
                showNotifications={showNotifications}
                notifications={notifications}
                closeNotifs={closeNotifs}
              />
            ))}
        </li>
        <div className="topbar-divider d-none d-sm-block"></div>
        <li
          className={
            "nav-item dropdown no-arrow" + (userOptions ? " show" : "")
          }
        >
          <Link
            className="nav-link dropdown-toggle gap-3"
            to="#"
            id="userDropdown"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded={userOptions}
            onClick={() => setUserOptions(!userOptions)}
          >
            <div className="d-none d-lg-block text-gray-600">
              <p className="font-weight-bold">
                {user?.companyName
                  ? user?.companyName
                  : user?.firstName + " " + user?.firstName}
              </p>
              {user?.role === "client" && (
                <p className="small">
                  {`${(user?.credit || 0).toFixed(2)} ${content["omr"]}`}
                </p>
              )}
            </div>
            <img
              className="img-profile rounded-circle"
              src={
                user?.picture
                  ? `${serverBaseURI}/uploads${user?.picture}`
                  : DefaultAvatar
              }
              alt="Avatar"
            />
            <FontAwesomeIcon
              icon={userOptions ? faChevronUp : faChevronDown}
              className="fa-sm"
            />
          </Link>
          {userOptions && (
            <UserOptions
              userOptions={userOptions}
              closeOptions={closeOptions}
              logOut={logOut}
            />
          )}
        </li>
      </ul>
    </nav>
  );
};

export default DashboardNavBar;
