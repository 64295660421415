import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { has8Chars, hasLowerCase, hasNumber, hasSpecialChar, hasUpperCase } from '../../utils/passwordUtil';
import './style.scss';

interface IProps {
    password: string;
}

const PasswordValidation: FC<IProps> = ({ password }) => {

    const translations = {
        'en': {
            'title': 'Your password must contain:',
            'length': 'A minimum of 8 characters',
            'lower': 'One lower case',
            'upper': 'One capital letter',
            'digital': 'One numeric or special character',
        },
        'ar': {
            'title': 'يجب أن تحتوي كلمة المرور الخاصة بك على:',
            'length': 'ما لا يقل عن 8 أحرف',
            'lower': 'حرف واحد صغير',
            'upper': 'حرف واحد كبير',
            'digital': 'رقم أو رمز واحد',
        },
    }

    const { lang } = useSelector((state: RootState) => state.lang);
    const [content, setContent] = useState<any>(translations['en']);

    useEffect(() => {
        if (lang === 'ar') setContent(translations['ar'])
        else setContent(translations['en'])
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lang]);

    return (
        <div className="password-validation">
            <div className="title">{content['title']}</div>
            <ul>
                <li className={has8Chars(password) ? "valid" : ""}>
                    {content['length']}
                    {has8Chars(password) && <FontAwesomeIcon icon={faCheck} />}
                </li>
                <li className={hasLowerCase(password) ? "valid" : ""}>
                    {content['lower']}
                    {hasLowerCase(password) && <FontAwesomeIcon icon={faCheck} />}
                </li>
                <li className={hasUpperCase(password) ? "valid" : ""}>
                    {content['upper']}
                    {hasUpperCase(password) && <FontAwesomeIcon icon={faCheck} />}
                </li>
                <li className={hasNumber(password) || hasSpecialChar(password) ? "valid" : ""}>
                    {content['digital']}
                    {(hasNumber(password) || hasSpecialChar(password)) && <FontAwesomeIcon icon={faCheck} />}
                </li>
            </ul>
        </div>
    );
}

export default PasswordValidation;