import { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import Loader from "../../../components/Loader";
import axios from "axios";
import { tokenConfig } from "../../../store/actions/authActions";
import { translations } from "../../../utils/translations";
import ContractCard from "../../../components/ContractCard";
import EmptyResults from "../../../components/EmptyResults";
import EmptyContracts from "../../../assets/illustrations/confused.png";

import { Contract } from "../../../store/types/contract";
import Pagination from "../../../components/Pagination";
import ContractsSort from "../../../components/ContractsSort";

const CarrierContracts: FC = () => {
  const { lang } = useSelector((state: RootState) => state.lang);

  const [content, setContent] = useState<any>(translations["en"]);

  useEffect(() => {
    if (lang === "ar") setContent(translations["ar"]);
    else setContent(translations["en"]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang]);

  const [loading, setLoading] = useState<boolean>(false);
  const [contracts, setContracts] = useState<any>(null);

  const getContracts = async () => {
    setLoading(true);
    try {
      let { data } = await axios.get(`/api/v0.1/contracts/requests`, {
        ...tokenConfig(),
        timeout: 5000,
      });
      setContracts(data);
      setPagination((prev) => ({ ...prev, data, currentData: data }));
    } catch (err) {
      setLoading(false);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (!contracts) getContracts();
  }, []);

  const initPaginatationState = {
    data: contracts ?? [],
    offset: 0,
    numberPerPage: 10,
    pageCount: 0,
    currentData: [] as Contract[],
  };
  const [pagination, setPagination] = useState(initPaginatationState);

  return (
    <div className="container metronic-theme">
      {loading ? (
        <Loader height={200} width={200} style={{ marginTop: 30 }} />
      ) : (
        <div className="container">
          <div className="scene-card flex-column flex-md-row">
            <div className="mb-4 mb-md-0">
              <h3 className="scene-title text-center text-md-left mb-2">
                {content["available_contracts"]}
              </h3>
              <div className="scene-subtitle">
                {content["browse_contracts"]}
              </div>
            </div>
            <div>
              <h3 className="scene-title text-center text-md-right mb-2">
                {contracts
                  ? contracts.length < 10
                    ? "0" + contracts.length
                    : contracts.length
                  : 0}
              </h3>
              <div className="scene-subtitle">{content["available_contracts"]}</div>
            </div>
          </div>
          {/* <ContractsSort /> */}

          {loading ? (
            <Loader height={200} width={200} style={{ marginTop: 30 }} />
          ) : (
            <div className="row">
              <div className="col-md-12">
                <div className="row mt-4">
                  {pagination.currentData &&
                  pagination.currentData.length > 0 ? (
                    pagination.currentData.map((contract: any) => (
                      <ContractCard
                        contract={contract}
                        key={"contract-" + contract._id}
                      />
                    ))
                  ) : (
                    <div className="col-12 text-center">
                      <EmptyResults
                        img={EmptyContracts}
                        text={content["no_contracts"]}
                      />
                    </div>
                  )}
                </div>
              </div>
              {contracts && contracts.length > 5 && (
                <Pagination
                  pagination={pagination}
                  setPagination={setPagination}
                  initPaginatationState={initPaginatationState}
                  sorted={contracts}
                />
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default CarrierContracts;
