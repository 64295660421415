import React, { FC, useEffect, useState } from "react";
import * as XLSX from "xlsx";
import { useSelector } from "react-redux";
import { RootState } from "../store";
import { translations } from "../utils/translations";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { serverBaseURI } from "../utils/const";
import Tooltip from "@mui/material/Tooltip";

const ExportToExcel: FC<{ apiData: any; fileName: string }> = ({
  apiData,
  fileName,
}) => {
  const { lang } = useSelector((state: RootState) => state.lang);
  const [content, setContent] = useState<any>(translations["en"]);
  useEffect(() => {
    if (lang === "ar") setContent(translations["ar"]);
    else setContent(translations["en"]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang]);

  const exportToXlsx = () => {
    let rows = apiData.map((data: any) => {
      let { _id, carrierId, __v, created_at, updated_at, ...dataToExport } =
        data;
      return dataToExport;
    });

    let Heading: [string[]] = [[]];
    rows.forEach((data: any, index) => {
      if (index === 0) {
        Object.keys(data).forEach((v: any) => {
          Heading[0].push(content[v]);
        });
      }
      if (data.picture)
        data.picture = `${serverBaseURI}/uploads${data.picture}`;
      if (data.document)
        data.document = `${serverBaseURI}/uploads${data.document}`;
      if (data.workCertificate)
        data.workCertificate = `${serverBaseURI}/uploads${data.workCertificate}`;
      if (data.opalFile)
        data.opalFile = `${serverBaseURI}/uploads${data.opalFile}`;
      if (data.standardFile)
        data.standardFile = `${serverBaseURI}/uploads${data.standardFile}`;
    });

    if (!Heading[0].includes(content["workCertificate"])) {
      Heading[0].push(content["workCertificate"]);
    }

    //Create a new workbook and then add the header
    const wb = XLSX.utils.book_new();
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet([]);
    XLSX.utils.sheet_add_aoa(ws, Heading);

    //Starting in the second row to avoid overriding and skipping headers
    XLSX.utils.sheet_add_json(ws, rows, { origin: "A2", skipHeader: true });

    XLSX.utils.book_append_sheet(wb, ws, "data");

    fileName = `${content[fileName]}_${moment()
      .locale("en-us")
      .format("DD_MM_yyyy_HH_mm_ss")}.xlsx`;

    XLSX.writeFile(wb, fileName);
  };

  return (
    <Tooltip title={content["export"]}>
      <button
        className="bawsala-btn btn-primary align-self-end"
        style={{ height: "-content", fontSize: "12px" }}
        onClick={(e) => exportToXlsx()}
      >
        <FontAwesomeIcon icon={faDownload} />
      </button>
    </Tooltip>
  );
};

export default ExportToExcel;
