import { combineReducers } from 'redux';

import authReducer from './authReducer';
import { FiltersReducer } from './filtersReducer';
import shipmentReducer from './shipmentReducer';
import offerReducer from './offerReducer';
import driverReducer from './driverReducer'
import truckReducer from './truckReducer';
import billReducer from './billReducer';
import profileReducer from './profileReducer';
import notificationReducer from './notificationReducer';
import langReducer from './langReducer';
import usersReducer from './usersReducer';
import contactReducer from './contactReducer';

const rootReducer = combineReducers({
    auth: authReducer,
    shipment: shipmentReducer,
    offer: offerReducer,
    driver: driverReducer,
    truck: truckReducer,
    bill: billReducer,
    profile: profileReducer,
    notification: notificationReducer,
    filters: FiltersReducer,
    lang: langReducer,
    users: usersReducer,
    contact: contactReducer
})

export default rootReducer;