import React, { FC, useEffect, useState } from "react";
import { FormGroup, Modal } from "react-bootstrap";
import Loader from "./Loader";
import { Checkbox, FormControlLabel, TextField } from "@material-ui/core";
import { create } from "jss";
import rtl from "jss-rtl";
import {
  StylesProvider,
  jssPreset,
  ThemeProvider,
  createTheme,
} from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import { RootState } from "../store";
import { translations } from "../utils/translations";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckDouble } from "@fortawesome/free-solid-svg-icons";

const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

const ltrTheme = createTheme({ direction: "ltr" });
const rtlTheme = createTheme({ direction: "rtl" });

const InitialAcceptanceModal: FC<any> = (props: any) => {
  const { lang } = useSelector((state: RootState) => state.lang);

  const [content, setContent] = useState<any>(translations["en"]);

  useEffect(() => {
    if (lang === "ar") setContent(translations["ar"]);
    else setContent(translations["en"]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang]);

  let {
    sameContract,
    setSameContract,
    uploadImage,
    finalContractFile,
    loading,
    initialAcceptance,
    ...rest
  } = props;

  return (
    <Modal
      animation={false}
      {...rest}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>{content["initial_acceptance"]}</Modal.Header>
      <Modal.Body className="py-4">
        <StylesProvider jss={jss}>
          <ThemeProvider theme={lang === "ar" ? rtlTheme : ltrTheme}>
            <FormGroup>
              <FormControlLabel
                className="mb-2"
                control={
                  <Checkbox
                    checked={sameContract}
                    onChange={(e) => setSameContract(!sameContract)}
                    style={{
                      color: "#6e57a5",
                    }}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                }
                label={content["use_same_contract"]}
              />
              {!sameContract && (
                <div
                  className={
                    "d-flex align-items-center upload-asset form-group" +
                    (loading ? " disabled" : "")
                  }
                >
                  <label
                    htmlFor="initialContractFile"
                    className="bawsala-btn btn-primary mt-3 px-2 w-50"
                  >
                    {content["upload_contract_file"]}
                  </label>
                  <input
                    type="file"
                    name="initialContractFile"
                    id="initialContractFile"
                    style={{ display: "none" }}
                    onChange={uploadImage}
                    accept="image/*, application/pdf"
                  />
                  {finalContractFile && (
                    <FontAwesomeIcon
                      icon={faCheckDouble}
                      className="text-success mx-2"
                    />
                  )}
                </div>
              )}
            </FormGroup>
          </ThemeProvider>
        </StylesProvider>
      </Modal.Body>
      <Modal.Footer className="mt-0">
        <button
          className="bawsala-btn btn-primary"
          onClick={initialAcceptance}
          disabled={!sameContract && !finalContractFile}
        >
          {loading ? content["loading"] : content["confirm"]}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default InitialAcceptanceModal;
