import { FC, FormEvent, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useHistory, Link } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import {
  faEdit,
  faTrash,
  faTag,
  faFilePdf,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ImageViewer from "react-simple-image-viewer";
import DeleteModal from "./DeleteModal";
import Loader from "./Loader";
import { RootState } from "../store";
import { tokenConfig, setUser } from "../store/actions/authActions";
import { createNotification } from "../store/actions/notificationActions";
import { Offer } from "../store/types/offer";
import {
  getToastMessage,
  clone,
  getContractStatusColor,
  isPdf,
} from "../utils/helper";
import { translations } from "../utils/translations";
import Pagination from "./Pagination";
import { serverBaseURI } from "../utils/const";
import EditContractModel from "./EditContractModal";
import AddContractOfferModal from "./AddContractOfferModal";
import ContractOfferCard from "./ContractOfferCard";
import EditContractOfferModal from "./EditContractOfferModal";
import Compressor from "compressorjs";
import InitialAcceptanceModal from "./InitialAcceptanceModal";
import SignedContractModal from "./SignedContractModal";
import ContractPreview from "./ContractPreviewModal";

const ContractDetails: FC = () => {
  const { lang } = useSelector((state: RootState) => state.lang);
  const [content, setContent] = useState<any>(translations["en"]);
  useEffect(() => {
    if (lang === "ar") setContent(translations["ar"]);
    else setContent(translations["en"]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang]);

  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const id: string = location.pathname.split("/")[3].split("?")[0];

  const { user } = useSelector((state: RootState) => state.auth);

  const [loading, setLoading] = useState<boolean>(false);
  const [contract, setContract] = useState<any>(null);
  const [offer, setOffer] = useState<any>(null);
  const [offers, setOffers] = useState<any>(null);
  const [addModalShow, setAddModalShow] = useState(false);
  const [editModalShow, setEditModalShow] = useState<boolean>(false);
  const [deleteModalShow, setDeleteModalShow] = useState<boolean>(false);
  const [showInitialAcceptance, setShowInitialAcceptance] =
    useState<boolean>(false);
  const [showUploadSigned, setShowUploadSigned] = useState<boolean>(false);
  const [offerId, setOfferId] = useState<string>();
  const [sameContract, setSameContract] = useState<boolean>(false);
  const [finalContractFile, setFile] = useState<any>(null);
  const [signedContractFile, setSignedContractFile] = useState<any>(null);
  const [previewContract, setPreviewContract] = useState<boolean>(false);
  const [previewContractFile, setPreviewContractFile] = useState<string>("");
  const [previewContractTitle, setPreviewContractTitle] = useState<string>("");

  const initPaginatationState = {
    data: offers || [],
    offset: 0,
    numberPerPage: 10,
    pageCount: 0,
    currentData: [] as Offer[],
  };
  const [pagination, setPagination] = useState(initPaginatationState);

  const getContract = async (id: string) => {
    setLoading(true);
    let { data } = await axios.get(`/api/v0.1/contracts/${id}`, tokenConfig());
    setContract(data);
    setLoading(false);
  };

  const getContractOffers = async (id: string) => {
    let { data } = await axios.get(
      `/api/v0.1/contract-offers/contract/${id}`,
      tokenConfig()
    );
    setOffers(data);
  };

  const getMyOffer = async (id: string) => {
    let { data } = await axios.get(
      `/api/v0.1/contract-offers/my-offer?contractId=${id}`,
      tokenConfig()
    );
    setOffer(data);
  };

  const deleteOffer = async () => {
    const toastId = toast.loading(content["loading"]);
    let { data } = await axios.delete(
      `/api/v0.1/contract-offers/${offer._id}`,
      tokenConfig()
    );
    if (data.status === "success") {
      toast.success(
        lang === "ar"
          ? "تم حذف العرض بنجاح!"
          : "Offer has been deleted successfully!",
        {
          id: toastId,
        }
      );
      setOffer(null);
      setDeleteModalShow(false);
    } else {
      toast.error(getToastMessage(data.message, lang as "en" | "ar"), {
        id: toastId,
      });
    }
  };

  useEffect(() => {
    getContract(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (contract) {
      if (user?.role === "carrier" || user?.role === "hybrid") {
        getMyOffer(id);
      } else if (contract.clientId === user._id || user?.role === "admin") {
        getContractOffers(id);
      }
    }
  }, [contract]);

  const toggleIsOpen = () => setDeleteModalShow((prevState) => !prevState);

  const handleDelete = async () => {
    if (offer && user.role === "carrier") deleteOffer();
    else if (contract.clientId === user._id) deleteContract();
  };

  const deleteContract = async () => {
    const toastId = toast.loading(content["loading"]);
    let { data } = await axios.delete(
      `/api/v0.1/contracts/${id}`,
      tokenConfig()
    );
    if (data.status === "success") {
      toast.success(getToastMessage(data.message, lang as "en" | "ar"), {
        id: toastId,
      });
      history.push("/client/contracts");
      setDeleteModalShow(false);
    } else {
      toast.error(getToastMessage(data.message, lang as "en" | "ar"), {
        id: toastId,
      });
    }
  };

  const acceptOffer = async (offerId: string) => {
    if (!signedContractFile) return;
    const toastId = toast.loading(content["loading"]);
    const config = tokenConfig();
    config.headers["Content-Type"] = "multipart/form-data";

    const formData = new FormData();
    formData.append("signedContractFile", finalContractFile);
    formData.append("offerId", offerId);

    try {
      let { data } = await axios.put(
        `/api/v0.1/contracts/award/${id}`,
        formData,
        config
      );
      if (data.status === "success") {
        toast.success("Offer has been accepted successfuly", {
          id: toastId,
        });
        dispatch(
          createNotification({
            to: data.data.offer.carrierId._id,
            userType: "carrier",
            notificationType: "contract",
            content: "New contract awarded to you",
            objectId: id,
          })
        );
        setContract(data.data.contract);
        if (offers) {
          let newOffers = clone(offers);
          newOffers.find((off: any) => off._id === offerId).status = "accepted";
          setOffers(newOffers);
        } else getContractOffers(id);
        setShowUploadSigned(false);
      } else {
        toast.error(data.message, {
          id: toastId,
        });
      }
    } catch (err: any) {
      toast.error(getToastMessage(err, lang as "en" | "ar"), {
        id: toastId,
      });
    }
  };

  const initialAcceptance = async () => {
    if (!offerId) return;
    const toastId = toast.loading(content["loading"]);
    const config = tokenConfig();
    config.headers["Content-Type"] = "multipart/form-data";

    const formData = new FormData();
    formData.append(
      "finalContractFile",
      finalContractFile ?? contract.initialContractFile
    );

    try {
      let { data } = await axios.put(
        `/api/v0.1/contract-offers/proceed-negociation/${offerId}`,
        formData,
        config
      );
      if (data.status === "success") {
        toast.success("Negociation started", {
          id: toastId,
        });
        dispatch(
          createNotification({
            to: data.data.carrierId._id,
            userType: "carrier",
            notificationType: "contract",
            content: "Contract requires signing",
            objectId: id,
          })
        );
        let newOffers = clone(offers);
        newOffers.find((off: any) => off._id === offerId).finalContractFile =
          data.data.finalContractFile;
        setOffers(newOffers);
        setShowInitialAcceptance(false);
      } else {
        toast.error(data.message, {
          id: toastId,
        });
      }
    } catch (err: any) {
      toast.error(getToastMessage(err, lang as "en" | "ar"), {
        id: toastId,
      });
    }
  };

  const uploadSignedContract = async (offer_id?: string) => {
    if (!signedContractFile || !offer) return;
    offer_id = offer_id ?? offer._id;
    console.log(offer_id);

    const toastId = toast.loading(content["loading"]);
    const config = tokenConfig();
    config.headers["Content-Type"] = "multipart/form-data";

    const formData = new FormData();
    formData.append("signedContractFile", signedContractFile);

    try {
      let { data } = await axios.put(
        `/api/v0.1/contract-offers/sign/${offer_id}`,
        formData,
        config
      );
      if (data.status === "success") {
        toast.success("Contract Uploaded", {
          id: toastId,
        });
        dispatch(
          createNotification({
            to: data.data.clientId,
            userType: "carrier",
            notificationType: "contract",
            content: "Contract has been signed",
            objectId: id,
          })
        );
        setOffer(data.data);
        setShowUploadSigned(false);
      } else {
        toast.error(data.message, {
          id: toastId,
        });
      }
    } catch (err: any) {
      toast.error(getToastMessage(err, lang as "en" | "ar"), {
        id: toastId,
      });
    }
  };

  const rejectOffer = async (oid: string) => {
    const toastId = toast.loading(content["loading"]);
    try {
      let { data } = await axios.put(
        `/api/v0.1/contract-offers/reject/${oid}`,
        {},
        tokenConfig()
      );
      if (data.status === "success") {
        toast.success(lang === "ar" ? "تم رفض العرض!" : "Offer rejected!", {
          id: toastId,
        });
        dispatch(
          createNotification({
            to: data.data.carrierId,
            userType: "carrier",
            notificationType: "offer",
            content: "Your offer has been rejected",
            objectId: id,
          })
        );
        if (offers) {
          let newOffers = clone(offers);
          newOffers.find((off: any) => off._id === oid).status = "rejected";
          setOffers(newOffers);
        }
      } else
        toast.error(getToastMessage(data.message, lang as "en" | "ar"), {
          id: toastId,
        });
    } catch (err: any) {
      toast.error(getToastMessage(err, lang as "en" | "ar"), {
        id: toastId,
      });
    }
  };

  const handleInitialAcceptance = (offerId: string) => {
    setOfferId(offerId);
    setShowInitialAcceptance(true);
  };

  const handleAcceptOffer = (offerId: string) => {
    setOfferId(offerId);
    setShowUploadSigned(true);
  };

  const uploadImage = (e: FormEvent<HTMLInputElement>) => {
    if (e.currentTarget.files) {
      const file = e.currentTarget.files[0];
      if (isPdf(file.name)) setFile(file);
      else
        new Compressor(file, {
          quality: 0.5,
          success: (compressedResult) => {
            setFile(compressedResult);
          },
        });
    }
  };

  const uploadImageSigned = (e: FormEvent<HTMLInputElement>) => {
    if (e.currentTarget.files) {
      const file = e.currentTarget.files[0];
      if (isPdf(file.name)) setSignedContractFile(file);
      else
        new Compressor(file, {
          quality: 0.5,
          success: (compressedResult) => {
            setSignedContractFile(compressedResult);
          },
        });
    }
  };

  const handlePreviewContract = (file: string, title: string) => {
    setPreviewContractFile(`${serverBaseURI}/uploads${file}`);
    setPreviewContractTitle(content[title]);
    setPreviewContract(true);
  };

  if (!contract) return <Loader height={200} width={200} />;

  return (
    <div className="container metronic-theme">
      <div className="scene-card flex-column flex-md-row mb-4">
        <h3 className="scene-title text-center text-md-left mb-2">
          {content["contract_details"]}
        </h3>
        <div className="metronic-theme">
          {(user?._id === contract.clientId || user?.role === "admin") &&
            contract?.status === "receiving_offers" &&
            !contract?.numberOffers && (
              <>
                <a
                  className="bawsala-btn text-warning font-weight-bolder font-size-sm"
                  onClick={() => setEditModalShow(true)}
                >
                  <FontAwesomeIcon icon={faEdit} />
                </a>
                <a
                  className="bawsala-btn text-danger font-weight-bolder font-size-sm ml-3"
                  onClick={() => setDeleteModalShow(true)}
                >
                  <FontAwesomeIcon icon={faTrash} />
                </a>
              </>
            )}
        </div>
      </div>

      <div className="scene-card flex-column flex-md-row justify-content-start align-items-start mb-4">
        <div
          className="cursor-pointer mr-5"
          onClick={() =>
            handlePreviewContract(contract.initialContractFile, "contract")
          }
        >
          {isPdf(contract.initialContractFile) ? (
            <FontAwesomeIcon icon={faFilePdf} className="fa-lg text-success" />
          ) : (
            <img
              className="img-circle bg-light mr-5"
              src={`${serverBaseURI}/uploads${contract.initialContractFile}`}
              alt="Contract"
              style={{
                width: "100px",
                height: "100px",
                cursor: "pointer",
                objectFit: "contain",
              }}
            />
          )}
        </div>
        <div className="flex-grow w-100 relative">
          <div
            className={
              "position-absolute right text-lowercase badge pb-1 px-2 badge-" +
              getContractStatusColor(contract.status)
            }
          >
            {content[contract.status]}
          </div>
          <div className="text-muted">{content["order_date"]}</div>
          <div>{moment(contract.created_at).format("DD MMM yyyy")}</div>
          <div className="text-muted mt-4">
            {content["averageNumberOfShipments"]}
          </div>
          <div>
            {`${contract.averageNumberOfShipments} ${content[
              "shipments"
            ].toLowerCase()}/${content[contract.period].toLowerCase()}`}
          </div>
          <div className="text-muted mt-4">
            {content["averageShipmentDistance"]}
          </div>
          <div>
            {`${contract.averageShipmentDistance} ${content["km"]}/${content[
              "shipment"
            ].toLowerCase()}`}
          </div>
          <div className="text-muted mt-4">{content["priceOffered"]}</div>
          <div>
            {`${contract.priceOffered} ${content["omr"]}/${content[
              contract.priceOfferedRelated
            ].toLowerCase()}`}
          </div>
          <div className="text-muted mt-4">{content["contractDuration"]}</div>
          <div>
            {`${contract.contractDuration} ${content["month"].toLowerCase()}`}
          </div>
        </div>
      </div>

      {user?.role === "carrier" ? (
        <div className="row justify-content-between mt-5 mx-0">
          <div className="scene-card col-12 d-flex align-items-center justify-content-between mb-4">
            <h3>{content["my_offer"]}</h3>
            {(contract?.status === "receiving_offers" ||
              contract?.status === "processing") && (
              <div className="d-flex align-items-center">
                {!offer && (
                  <button
                    className="bawsala-btn btn-primary"
                    disabled={loading}
                    onClick={() => setAddModalShow(true)}
                  >
                    <FontAwesomeIcon icon={faTag} className="mr-2" />
                    {content["add_offer"]}
                  </button>
                )}
              </div>
            )}
          </div>
          {offer && offer.status !== "processing" ? (
            <ContractOfferCard
              offer={offer}
              contract={contract}
              handlePreviewContract={handlePreviewContract}
              setEditModalShow={setEditModalShow}
              setDeleteModalShow={setDeleteModalShow}
              setShowUploadSigned={setShowUploadSigned}
            />
          ) : (
            contract?.status !== "receiving_offers" && (
              <div className="col-12">{content["contract_null"]}</div>
            )
          )}
        </div>
      ) : (
        <div className="row justify-content-between mt-5 mx-0">
          <div className="scene-card col-12 mb-4">
            <div className="mb-4 mb-md-0">
              <h3>
                {content["list_offers"]} (
                {contract?.numberOffers && contract?.numberOffers < 10
                  ? "0" + contract?.numberOffers
                  : contract?.numberOffers}
                )
              </h3>
            </div>
          </div>
          {offers && offers.length ? (
            offers
              .sort((n1: any, n2: any) =>
                n1.status !== "accepted" && n2.status !== "accepted"
                  ? Number(moment(n2.created_at)) -
                    Number(moment(n1.created_at))
                  : n1.status !== "accepted"
                  ? 1
                  : -1
              )
              .map((offer: any) => (
                <ContractOfferCard
                  key={"offer-" + offer._id}
                  offer={offer}
                  contract={contract}
                  handlePreviewContract={handlePreviewContract}
                  setShowUploadSigned={setShowUploadSigned}
                  rejectOffer={rejectOffer}
                  setDeleteModalShow={setDeleteModalShow}
                  handleInitialAcceptance={handleInitialAcceptance}
                  handleAcceptOffer={handleAcceptOffer}
                />
              ))
          ) : (
            <div className="col-12 text-center mt-3 mb-5">
              {content["no_offers"]}
            </div>
          )}
          {offers && offers.length > 5 && (
            <Pagination
              pagination={pagination}
              setPagination={setPagination}
              initPaginatationState={initPaginatationState}
              sorted={offers}
            />
          )}
        </div>
      )}
      {user?.role === "carrier" && (
        <>
          <AddContractOfferModal
            show={addModalShow}
            setModalShow={setAddModalShow}
            setOffer={setOffer}
            contractId={id}
            onHide={() => {
              setAddModalShow(false);
              // resetData();
            }}
          />
          <EditContractOfferModal
            show={editModalShow}
            offer={offer}
            setOffer={setOffer}
            setModalShow={setEditModalShow}
            onHide={() => {
              setEditModalShow(false);
              // resetData();
            }}
          />
        </>
      )}
      {contract.clientId === user._id && (
        <>
          <EditContractModel
            show={editModalShow}
            setEditModalShow={setEditModalShow}
            id={id}
            contract={contract}
            setContract={setContract}
          />
          <InitialAcceptanceModal
            show={showInitialAcceptance}
            onHide={() => setShowInitialAcceptance(false)}
            setShowInitialAcceptance={setShowInitialAcceptance}
            sameContract={sameContract}
            setSameContract={setSameContract}
            finalContractFile={finalContractFile}
            uploadImage={uploadImage}
            initialAcceptance={initialAcceptance}
          />
        </>
      )}
      <SignedContractModal
        show={showUploadSigned}
        onHide={() => setShowUploadSigned(false)}
        setShowUploadSigned={setShowUploadSigned}
        signedContractFile={signedContractFile}
        uploadImageSigned={uploadImageSigned}
        offerId={offerId}
        uploadContract={offerId ? acceptOffer : uploadSignedContract}
      />
      <DeleteModal
        show={deleteModalShow}
        toggleIsOpen={toggleIsOpen}
        handleDelete={handleDelete}
        loading={loading}
        onHide={toggleIsOpen}
      />
      <ContractPreview
        title={previewContractTitle}
        file={previewContractFile}
        setPreviewContract={setPreviewContract}
        content={content}
        show={previewContract}
        onHide={() => setPreviewContract(false)}
      />
    </div>
  );
};

export default ContractDetails;
