import { TruckState, UPDATE_TRUCK, RESET_TRUCK, TruckAction, SET_TRUCK, SET_TRUCK_LOADING, SET_ERROR, SET_SUCCESS, SET_TRUCKS, SET_IS_ADDING_TRUCK, DELETE_TRUCK, SET_TRUCK_DELETED, SET_TRUCK_UPDATED } from '../types/truck';

const initState: TruckState = {
    truck: null,
    trucks: null,
    isAdding: false,
    loading: false,
    truckUpdated: false,
    truckDeleted: false,
    error: '',
    success: '',
}

const truckReducer = (state = initState, action: TruckAction) => {
    switch (action.type) {
        case SET_TRUCK_LOADING:
            return {
                ...state,
                loading: action.payload
            }
        case SET_IS_ADDING_TRUCK:
            return {
                ...state,
                isAdding: action.payload
            }
        case RESET_TRUCK:
            return {
                ...state,
                truck: null
            }
        case SET_TRUCK:
            return {
                ...state,
                truck: action.payload
            }
        case SET_TRUCKS:
            return {
                ...state,
                trucks: action.payload
            }
        case SET_ERROR:
            return {
                ...state,
                error: action.payload,
            }
        case DELETE_TRUCK:
            return {
                ...state,
                truckDeleted: true,
                trucks: state.trucks ? state.trucks.filter(truck => truck._id !== action.payload) : []
            }
        case SET_TRUCK_UPDATED:
            return {
                ...state,
                truckUpdated: action.payload
            }
        case SET_TRUCK_DELETED:
            return {
                ...state,
                truckDeleted: action.payload
            }
        case SET_SUCCESS:
            return {
                ...state,
                success: action.payload,
            }
        case UPDATE_TRUCK:
            return {
                ...state,
                success: "Truck has been updated successfully!"
            }
        default:
            return state;
    }
}

export default truckReducer;