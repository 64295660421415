import { FC, useState, FormEvent, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { RootState } from "../store";
import TextField from "@material-ui/core/TextField";
import { create } from "jss";
import rtl from "jss-rtl";
import {
  StylesProvider,
  jssPreset,
  ThemeProvider,
  createTheme,
} from "@material-ui/core/styles";
import { translations } from "../utils/translations";
import toast from "react-hot-toast";
import axios from "axios";
import { tokenConfig } from "../store/actions/authActions";
import { getToastMessage, isPdf } from "../utils/helper";
import { InputAdornment } from "@mui/material";
import moment from "moment";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckDouble } from "@fortawesome/free-solid-svg-icons";
import Compressor from "compressorjs";

const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

const ltrTheme = createTheme({ direction: "ltr" });
const rtlTheme = createTheme({ direction: "rtl" });

const AddContractOfferModal: FC<any> = (props: any) => {
  const { lang } = useSelector((state: RootState) => state.lang);
  const [content, setContent] = useState<any>(translations["en"]);
  useEffect(() => {
    if (lang === "ar") setContent(translations["ar"]);
    else setContent(translations["en"]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang]);

  let { contractId, setModalShow, setOffer, ...rest } = props;

  const [priceOffered, setPriceOffered] = useState<number>();
  const [priceOfferedRelated, setPriceOfferedRelated] =
    useState<string>("shipment");
  const [startDate, setStartDate] = useState<any>(
    moment().format("MM/DD/yyyy")
  );
  const [offerContractFile, setFile] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const createOffer = async (body: any) => {
    const toastId = toast.loading(content["loading"]);
    setLoading(true);
    try {
      const config = tokenConfig();
      config.headers["Content-Type"] = "multipart/form-data";

      const formData = new FormData();
      for (const property in body) {
        if (body[property] !== null && body[property] !== "")
          formData.append(property, body[property]);
      }

      let { data } = await axios.post(
        "/api/v0.1/contract-offers/create",
        formData,
        config
      );
      if (data.status === "success") {
        toast.success(getToastMessage(data.message, lang as "en" | "ar"), {
          id: toastId,
        });
        setOffer(data.data);
        setModalShow(false);
        setLoading(false);
        resetData();
      } else {
        toast.error(content[data.message], {
          id: toastId,
        });
        setLoading(false);
      }
    } catch (err: any) {
      toast.error(getToastMessage(err, lang as "en" | "ar"), {
        id: toastId,
      });
      setLoading(false);
    }
  };

  const submitHandler = async (e: FormEvent) => {
    e.preventDefault();
    createOffer({
      priceOffered,
      priceOfferedRelated,
      startDate,
      contractId,
      offerContractFile,
    });
  };

  const uploadImage = (e: FormEvent<HTMLInputElement>) => {
    if (e.currentTarget.files) {
      const file = e.currentTarget.files[0];
      if (isPdf(file.name)) setFile(file);
      else
        new Compressor(file, {
          quality: 0.5,
          success: (compressedResult) => {
            setFile(compressedResult);
          },
        });
    }
  };

  const resetData = () => {
    setPriceOffered(undefined);
    setPriceOfferedRelated("shipment");
    setFile(null);
    setStartDate(moment().format("MM/DD/yyyy"));
  };

  const onClose = () => {
    resetData();
    setModalShow(false);
  };

  return (
    <Modal
      animation={false}
      {...rest}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={onClose}
    >
      <Modal.Body className="px-4">
        <h4 className="w-100 mb-5">{content["add_offer"]}</h4>
        <StylesProvider jss={jss}>
          <ThemeProvider theme={lang === "ar" ? rtlTheme : ltrTheme}>
            <form onSubmit={submitHandler}>
              <div className="row">
                <div className="col-8">
                  <div className="form-group mb-4">
                    <TextField
                      id="priceOffered"
                      className="w-100"
                      name="priceOffered"
                      label={content["priceOffered"]}
                      variant="outlined"
                      size="small"
                      value={priceOffered}
                      required
                      onChange={(e: any) => setPriceOffered(e.target.value)}
                      disabled={loading}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {content["omr"]}
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                </div>
                <div className="col-4">
                  <div className="form-group mb-4">
                    <select
                      id="priceOfferedRelated"
                      name="priceOfferedRelated"
                      className="form-control"
                      value={priceOfferedRelated}
                      onChange={(e) => setPriceOfferedRelated(e.target.value)}
                      disabled={loading}
                      required
                    >
                      <option value="" disabled>
                        {content["priceOfferedRelated"]}
                      </option>
                      <option value="shipment">{content["shipment"]}</option>
                      <option value="month">{content["month"]}</option>
                    </select>
                  </div>
                </div>
                <div className="col-12">
                  <div className="form-group mb-4">
                    <MuiPickersUtilsProvider locale={lang} utils={DateFnsUtils}>
                      <DatePicker
                        label={content["startDate"]}
                        className="w-100"
                        size="small"
                        inputVariant="outlined"
                        format="MM/DD/yyyy"
                        disablePast={true}
                        value={moment(startDate).format("MM/DD/yyyy")}
                        onChange={(date) => {
                          setStartDate(date);
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </div>
                </div>
              </div>
              <div
                className={
                  "d-flex align-items-center upload-asset form-group" +
                  (loading ? " disabled" : "")
                }
              >
                <label
                  htmlFor="offerContractFile"
                  className="bawsala-btn btn-primary mt-3 px-2 w-50"
                >
                  {content["upload_contract_file"]}
                </label>
                <input
                  type="file"
                  name="offerContractFile"
                  id="offerContractFile"
                  style={{ display: "none" }}
                  onChange={uploadImage}
                  accept="image/*, application/pdf"
                />
                {offerContractFile && (
                  <FontAwesomeIcon
                    icon={faCheckDouble}
                    className="text-success mx-2"
                  />
                )}
              </div>
            </form>
          </ThemeProvider>
        </StylesProvider>
      </Modal.Body>
      <Modal.Footer className="mt-2">
        <button
          className="bawsala-btn btn-secondary px-4"
          onClick={onClose}
          disabled={loading}
        >
          {content["cancel"]}
        </button>
        <button
          className="bawsala-btn btn-primary px-4"
          onClick={submitHandler}
          disabled={loading}
        >
          {loading ? content["loading"] : content["create"]}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddContractOfferModal;
