
import { FiltersState, FiltersActionsType } from '../types/filters'
const initialeState: FiltersState = {
    id: '',
    status: '',
    pickUpDate: '',
    dropOffDate: '',
    carrier: '',
    type: '',
    name: '',
    truck: '',
    usersFilter: undefined,
    trucksFilter: undefined,
    shipmentsFilter: undefined,
    offersFilter: undefined,
    notificationsFilter: undefined,
}

export const FiltersReducer = (state = initialeState, action: FiltersActionsType): FiltersState => {
    switch (action.type) {
        case 'SET_NAME':
            return {
                ...state,
                name: action.payload
            }
        case 'SET_STATUS':
            return {
                ...state,
                status: action.payload
            }
            case "SET_USERS_FILTER":
            return {
                ...state,
                usersFilter: action.payload
            }
        case "SET_SHIPMENTS_FILTER":
            return {
                ...state,
                shipmentsFilter: action.payload
            }
        case "SET_OFFERS_FILTER":
            return {
                ...state,
                offersFilter: action.payload
            }
        case "SET_NOTIFICATIONS_FILTER":
            return {
                ...state,
                notificationsFilter: action.payload
            }
        case 'RESET_FILTERS':
            return {
                id: '',
                status: '',
                pickUpDate: '',
                dropOffDate: '',
                carrier: '',
                type: '',
                name: '',
                truck: '',
                usersFilter: undefined,
                trucksFilter: undefined,
                shipmentsFilter: undefined,
                offersFilter: undefined,
                notificationsFilter: undefined,
            }
        default:
            return state
    }
}