
import React, { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { faBars, faChevronDown, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-scroll'

import Logo from '../../assets/logo.svg'

import '../../styles/landing_page.scss'
import LanguageToggle from '../../components/LanguageToggle';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';


const MainHeader: FC<any> = (props: any) => {

    const translations = {
        'en': {
            'home': 'Home',
            'about': 'About us',
            'values': 'Values',
            'why-us': 'Why us',
            'services': 'Services',
            'contact': 'Contact us',
            'auth': 'Sign up / Log in',
            'auth-carrier': 'Join as a Carrier',
        },
        'ar': {
            'home': 'الرئيسية',
            'about': 'نبذة عنا',
            'values': 'قيمنا',
            'why-us': 'لماذا نحن',
            'services': 'خدماتنا',
            'contact': 'تواصل معنا',
            'auth': 'تسجيل الدخول / اشتراك',
            'auth-carrier': 'التحق كناقل',
        },
    }

    let { setShowAuthModal, setShowCarrierAuthModal, setAuthSection } = props;
    const { lang } = useSelector((state: RootState) => state.lang);

    const [showNavBar, setShowNavBar] = useState<boolean>(false)
    const [scrollPosition, setScrollPosition] = useState(0);
    const handleScroll = () => {
        const position = window.pageYOffset;
        setScrollPosition(position);
    };

    const history = useHistory();
    let path = history.location.pathname;
    //let prefix = path.split("/")[1];

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true });
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const [content, setContent] = useState<any>(translations['en']);

    useEffect(() => {
        if (lang === 'ar') setContent(translations['ar'])
        else setContent(translations['en'])
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lang]);

    return (
        <header id="header" className={scrollPosition > 100 ? "header-fixed" : ""}>
            <div className={"container" + (showNavBar ? " mobile-nav-active" : "")}>

                <div id="logo" className="pull-left">
                    <a href="/"><img src={Logo} alt="Bawsala Logo" /></a>
                </div>

                <nav id="nav-menu-container" className="d-none d-md-flex justify-content-end align-items-center">
                    <ul className="nav-menu d-flex">
                        {path === "/"
                            &&
                            <>
                                <li>
                                    <Link activeClass="menu-active" to="hero" spy={true} smooth={true} offset={-72}>
                                        {content['home']}
                                    </Link>
                                </li>
                                <li>
                                    <Link activeClass="menu-active" to="about" spy={true} smooth={true} offset={-72}>
                                        {content['about']}
                                    </Link>
                                </li>
                                <li>
                                    <Link activeClass="menu-active" to="values" spy={true} smooth={true} offset={-72}>
                                        {content['values']}
                                    </Link>
                                </li>
                                <li>
                                    <Link activeClass="menu-active" to="why-us" spy={true} smooth={true} offset={-72}>
                                        {content['why-us']}
                                    </Link>
                                </li>
                                <li>
                                    <Link activeClass="menu-active" to="services" spy={true} smooth={true} offset={-72}>
                                        {content['services']}
                                    </Link>
                                </li>
                                <li>
                                    <Link activeClass="menu-active" to="contact" spy={true} smooth={true} offset={-72}>
                                        {content['contact']}
                                    </Link>
                                </li>
                            </>}
                        <li>
                            <Link to="" className="ml-md-4" onClick={() => { setShowAuthModal(true); setAuthSection("login") }}>{content['auth']}</Link>
                        </li>
                    </ul>
                    <button className="bawsala-btn btn-primary" onClick={() => { setShowCarrierAuthModal(true); setAuthSection("login") }}>{content['auth-carrier']}</button>
                    <LanguageToggle />
                </nav>

                <div>
                    <nav id="mobile-nav">
                        <ul>
                            {path === "/"
                                &&
                                <>
                                    <li>
                                        <Link activeClass="menu-active" to="hero" spy={true} smooth={true} offset={-72} onClick={() => setShowNavBar(false)}>
                                            {content['home']}
                                        </Link>
                                    </li>
                                    <li>
                                        <Link activeClass="menu-active" to="about" spy={true} smooth={true} offset={-72} onClick={() => setShowNavBar(false)}>
                                            {content['about']}
                                        </Link>
                                    </li>
                                    <li>
                                        <Link activeClass="menu-active" to="values" spy={true} smooth={true} offset={-72} onClick={() => setShowNavBar(false)}>
                                            {content['values']}
                                        </Link>
                                    </li>
                                    <li>
                                        <Link activeClass="menu-active" to="why-us" spy={true} smooth={true} offset={-72}>
                                            {content['why-us']}
                                        </Link>
                                    </li>
                                    <li>
                                        <Link activeClass="menu-active" to="services" spy={true} smooth={true} offset={-72} onClick={() => setShowNavBar(false)}>
                                            {content['services']}
                                        </Link>
                                    </li>
                                    <li>
                                        <Link activeClass="menu-active" to="contact" spy={true} smooth={true} offset={-72} onClick={() => setShowNavBar(false)}>
                                            {content['contact']}
                                        </Link>
                                    </li>
                                </>}
                            <li>
                                <Link to="#!" className={(path === "/") ? "mt-4" : ""} onClick={() => { setShowAuthModal(true); setAuthSection("login") }}>{content['auth']}</Link>
                            </li>
                        </ul>
                        <button className="bawsala-btn btn-primary" onClick={() => { setShowCarrierAuthModal(true) }}>{content['auth-carrier']}</button>
                    </nav>
                    <div id="mobile-body-overly" onClick={() => setShowNavBar(false)}></div>
                </div>
                <div className="language-holder">
                    <FontAwesomeIcon icon={faChevronDown} />
                    <LanguageToggle />
                </div>
                <button type="button" id="mobile-nav-toggle" onClick={() => setShowNavBar(!showNavBar)}><FontAwesomeIcon icon={showNavBar ? faTimes : faBars} /> </button>
            </div >
        </header >
    )
}

export default MainHeader;