import { FC, lazy } from "react";
import { BrowserRouter, Switch, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../store";

import AdminRoute from "./AdminRoute";
import CarrierRoute from "./CarrierRoute";
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";
import ResetPassword from "../pages/ResetPassword";
import ClientContracts from "../scenes/client/Contracts";
import ContractDetails from "../components/ContractDetails";
import ContractRequests from "../scenes/carrier/Contracts";
import CarrierContracts from "../scenes/carrier/Contracts/MyContracts";

const LandingPage = lazy(() => import("../pages/LandingPage/"));
const Terms = lazy(() => import("../pages/Terms"));
const Privacy = lazy(() => import("../pages/PrivacyPolicy"));

const ClientShipments = lazy(() => import("../scenes/client/Shipments"));
const AddShipment = lazy(
  () => import("../scenes/client/Shipments/AddShipment")
);
const EditShipment = lazy(
  () => import("../scenes/client/Shipments/EditShipment")
);

const CarrierHome = lazy(() => import("../scenes/carrier/Trucks"));
const CarrierNewRequests = lazy(() => import("../scenes/carrier/Shipments"));
const CarrierShipments = lazy(
  () => import("../scenes/carrier/Shipments/MyShipments")
);
const CarrierOffers = lazy(() => import("../scenes/carrier/Offers"));
const CarrierDrivers = lazy(() => import("../scenes/carrier/Drivers"));

const AdminDashboard = lazy(() => import("../scenes/admin/Home"));
const AdminShipments = lazy(() => import("../scenes/admin/Shipments"));
const AdminOffers = lazy(() => import("../scenes/admin/Offers"));
const AdminPartners = lazy(() => import("../scenes/admin/Partners"));
const AdminClients = lazy(() => import("../scenes/admin/Clients"));
const AdminDrivers = lazy(() => import("../scenes/admin/Drivers"));
const AdminTrucks = lazy(() => import("../scenes/admin/Trucks"));
const AdminCredits = lazy(() => import("../scenes/admin/BawsalaCredit"));

const ShipmentDetails = lazy(() => import("../components/ShipmentDetails"));
const MyBill = lazy(() => import("../scenes/Bills/Bill"));
const MyActiveBill = lazy(() => import("../scenes/Bills"));
const BillDetails = lazy(() => import("../scenes/Bills/BillDetails"));
const BillsHistory = lazy(() => import("../scenes/Bills/History"));
const Notifications = lazy(() => import("../scenes/Notifications"));
const PersonalInformation = lazy(() => import("../scenes/PersonalInformation"));

const Router: FC = () => {
  const { user } = useSelector((state: RootState) => state.auth);
  return (
    <BrowserRouter>
      <Switch>
        <PublicRoute path="/" component={LandingPage} exact />
        <PublicRoute path="/terms-and-conditions" component={Terms} exact />
        <PublicRoute path="/privacy-policy" component={Privacy} exact />
        <PublicRoute
          path="/new-password/:token"
          component={ResetPassword}
          exact
        />

        <PrivateRoute path="/client/home" component={ClientShipments} exact />
        <PrivateRoute
          path="/client/shipments"
          component={ClientShipments}
          exact
        />
        <PrivateRoute
          path="/client/shipments/add"
          component={AddShipment}
          exact
        />
        <PrivateRoute
          path="/client/shipments/update/:id"
          component={EditShipment}
          exact
        />
        <PrivateRoute
          path="/client/shipments/:id"
          component={ShipmentDetails}
          exact
        />
        <PrivateRoute
          path="/client/contracts"
          component={ClientContracts}
          exact
        />
        <PrivateRoute
          path="/client/contracts/:id"
          component={ContractDetails}
          exact
        />
        {!user?.isContracted && (
          <PrivateRoute path="/client/bills" component={MyBill} exact />
        )}
        {user?.isContracted && (
          <>
            <PrivateRoute path="/client/bills" component={MyActiveBill} exact />
            <PrivateRoute
              path="/client/bills/history"
              component={BillsHistory}
              exact
            />
            <PrivateRoute
              path="/client/bills/:id"
              component={BillDetails}
              exact
            />
          </>
        )}
        <PrivateRoute
          path="/client/notifications"
          component={Notifications}
          exact
        />
        <PrivateRoute
          path="/client/profile"
          component={PersonalInformation}
          exact
        />

        <CarrierRoute
          path="/carrier/home"
          component={CarrierNewRequests}
          exact
        />
        <CarrierRoute
          path="/carrier/new-requests"
          component={CarrierNewRequests}
          exact
        />
        <CarrierRoute
          path="/carrier/new-requests/:id"
          component={ShipmentDetails}
          exact
        />
        <CarrierRoute path="/carrier/offers" component={CarrierOffers} exact />
        <CarrierRoute
          path="/carrier/shipments"
          component={CarrierShipments}
          exact
        />
        <CarrierRoute
          path="/carrier/contracts"
          component={ContractRequests}
          exact
        />
        <CarrierRoute
          path="/carrier/my-contracts"
          component={CarrierContracts}
          exact
        />
        <CarrierRoute
          path="/carrier/contracts/:id"
          component={ContractDetails}
          exact
        />
        <CarrierRoute
          path="/carrier/drivers"
          component={CarrierDrivers}
          exact
        />
        <CarrierRoute path="/carrier/trucks" component={CarrierHome} exact />
        <CarrierRoute path="/carrier/partners" component={CarrierHome} exact />
        {!user?.isContracted && (
          <CarrierRoute path="/carrier/bills" component={MyBill} exact />
        )}
        {user?.isContracted && (
          <>
            <CarrierRoute
              path="/carrier/bills"
              component={MyActiveBill}
              exact
            />
            <CarrierRoute
              path="/carrier/bills/history"
              component={BillsHistory}
              exact
            />
            <CarrierRoute
              path="/carrier/bills/:id"
              component={BillDetails}
              exact
            />
          </>
        )}
        <CarrierRoute
          path="/carrier/notifications"
          component={Notifications}
          exact
        />
        <CarrierRoute
          path="/carrier/profile"
          component={PersonalInformation}
          exact
        />

        <AdminRoute path="/admin/home" component={AdminDashboard} exact />
        <AdminRoute path="/admin/shipments" component={AdminShipments} exact />
        <AdminRoute
          path="/admin/shipments/:id"
          component={ShipmentDetails}
          exact
        />
        <AdminRoute path="/admin/offers" component={AdminOffers} exact />
        <AdminRoute path="/admin/partners" component={AdminPartners} exact />
        <AdminRoute path="/admin/clients" component={AdminClients} exact />
        <AdminRoute path="/admin/drivers" component={AdminDrivers} exact />
        <AdminRoute path="/admin/trucks" component={AdminTrucks} exact />
        <AdminRoute path="/admin/bills" component={BillsHistory} exact />
        <AdminRoute path="/admin/bills/:id" component={BillDetails} exact />
        <AdminRoute
          path="/admin/bawsala-credit"
          component={AdminCredits}
          exact
        />
        <AdminRoute
          path="/admin/notifications"
          component={Notifications}
          exact
        />

        <Redirect to="/" />
      </Switch>
    </BrowserRouter>
  );
};

export default Router;
