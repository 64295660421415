export const SET_ERROR = "SET_ERROR";
export const SET_SUCCESS = "SET_SUCCESS";
export const SET_PROFILE_LOADING = "SET_PROFILE_LOADING";
export const SET_PROFILE_UPDATED = "SET_PROFILE_UPDATED";

export interface ProfileState {
    loading: boolean;
    error: string;
    success: string;
    profileUpdated: boolean;
}

export interface UpdateProfileData {
    accountType: string;
    firstName?: string;
    lastName?: string;
    companyName?: string;
    companyLocation?: string;
    isCompany?: boolean;
    crNumber?: string;
    email?: string;
    phoneNumber?: string;
    workingField?: string;
    picture?: File;
}

//actions
interface SetLoadingAction {
    type: typeof SET_PROFILE_LOADING;
    payload: boolean;
}

interface SetErrorAction {
    type: typeof SET_ERROR;
    payload: string;
}

interface SetSuccessAction {
    type: typeof SET_SUCCESS;
    payload: string;
}

interface SetProfileUpdatedAction {
    type: typeof SET_PROFILE_UPDATED;
    payload: boolean;
}

export type ProfileAction = SetLoadingAction | SetErrorAction | SetSuccessAction | SetProfileUpdatedAction