export const emailRegex = /^[^\s()<>@,;:/]+@\w[\w.-]+\.[a-z]{2,}$/i;
export const phoneRegex = /^\+?(?:[0-9] ?){6,14}[0-9]$/;

export const serverBaseURI = process.env.REACT_APP_BASE_URI || "http://localhost:5000";

export const customStyles = {
  control: (provided: any, state: any) => ({
    ...provided,
    background: "#fff",
    borderColor: "transparent",
    minHeight: "35px",
    height: "35px",
    cursor: "Pointer",
    boxShadow: state.isFocused ? null : null,
  }),

  valueContainer: (provided: any, state: any) => ({
    ...provided,
    height: "35px",
    width: "150px",
    padding: "0 6px",
  }),

  input: (provided: any, state: any) => ({
    ...provided,
    margin: "0px",
  }),
  indicatorSeparator: (state: any) => ({
    display: "none",
  }),
  indicatorsContainer: (provided: any, state: any) => ({
    ...provided,
    height: "35px",
  }),
};

export const locationsTypes = ["cwrwof", "cworwf", "cwo", "cw", "rwf", "rwof"];

export const trucksTypes = [
  { title: "Van", icon: "" },
  { title: "1-T Open Pickup", icon: "" },
  { title: "1-T Closed Pickup", icon: "" },
  { title: "1-T Open Bakkie", icon: "" },
  { title: "1-T Closed Bakkie", icon: "" },
  { title: "1-T Reefer 15C - 25C", icon: "" },
  { title: "2-T Open Pickup", icon: "" },
  { title: "2-T Closed Sprinter", icon: "" },
  { title: "3-T Open Pickup", icon: "" },
  { title: "3-T Closed Pickup", icon: "" },
  { title: "3-T Covered Truck", icon: "" },
  { title: "3-T Reefer 15C - 25C", icon: "" },
  { title: "3-T Chiller 0C - 8C", icon: "" },
  { title: "3-T Frozen -20C - 0C", icon: "" },
  { title: "3-T Closed Pickup-Taillift", icon: "" },
  { title: "4-T Open Pickup", icon: "" },
  { title: "4-T Closed Pickup", icon: "" },
  { title: "4-T Reefer 15C - 25C", icon: "" },
  { title: "4-T Chiller 0C - 8C", icon: "" },
  { title: "4-T Frozen -20C - 0C", icon: "" },
  { title: "4-T Closed Pickup-Taillift", icon: "" },
  { title: "5-T Open Pickup", icon: "" },
  { title: "5-T Closed Pickup", icon: "" },
  { title: "5-T Reefer 15C - 25C", icon: "" },
  { title: "5-T Chiller 0C - 8C", icon: "" },
  { title: "5-T Frozen -20C - 0C", icon: "" },
  { title: "5-T Closed Pickup-Taillift", icon: "" },
  { title: "7-T Open Pickup", icon: "" },
  { title: "7-T Closed Pickup", icon: "" },
  { title: "7-T Covered Truck", icon: "" },
  { title: "7-T Reefer 15C - 25C", icon: "" },
  { title: "7-T Chiller 0C - 8C", icon: "" },
  { title: "7-T Frozen -20C - 0C", icon: "" },
  { title: "7-T Closed Pickup-Taillift", icon: "" },
  { title: "8-T Open Pickup", icon: "" },
  { title: "8-T Closed Pickup", icon: "" },
  { title: "8-T Reefer 15C - 25C", icon: "" },
  { title: "8-T Chiller 0C - 8C", icon: "" },
  { title: "8-T Frozen -20C - 0C", icon: "" },
  { title: "8-T Closed Pickup-Split Deck", icon: "" },
  { title: "8-T Closed Pickup-Taillift", icon: "" },
  { title: "10-T Open Pickup", icon: "" },
  { title: "10-T Closed Pickup", icon: "" },
  { title: "10-T Covered Truck", icon: "" },
  { title: "10-T Reefer 15C - 25C", icon: "" },
  { title: "10-T Chiller 0C - 8C", icon: "" },
  { title: "10-T Frozen -20C - 0C", icon: "" },
  { title: "10-T Closed Pickup-Taillift", icon: "" },
  { title: "12-T Open Pickup", icon: "" },
  { title: "12-T Closed Pickup", icon: "" },
  { title: "12-T Reefer 15C - 25C", icon: "" },
  { title: "12-T Chiller 0C - 8C", icon: "" },
  { title: "12-T Frozen -20C - 0C", icon: "" },
  { title: "12-T Closed Pickup-Split Deck", icon: "" },
  { title: "12-T Closed Pickup-Taillift", icon: "" },
  { title: "6 M Flatbed Trailer", icon: "" },
  { title: "12 M Flatbed Trailer", icon: "" },
  { title: "13.5 M Flatbed Trailer", icon: "" },
  { title: "14 M Flatbed Trailer", icon: "" },
  { title: "15 M Flatbed Trailer", icon: "" },
  { title: "18 M Flatbed Trailer", icon: "" },
  { title: "20 M Flatbed Trailer", icon: "" },
  { title: "55-T Multiaxle Trailer", icon: "" },
  { title: "12 M Triaxle Flatbed Trailer", icon: "" },
  { title: "13.5 M Triaxle Flatbed Trailer", icon: "" },
  { title: "15 M Triaxle Flatbed Trailer", icon: "" },
  { title: "6 M Container Transport (20 ft)", icon: "" },
  { title: "12 M Container Transport (40 ft)", icon: "" },
  { title: "13.5 M Container Transport (45 ft)", icon: "" },
  { title: "Side Loader Container Transport", icon: "" },
  { title: "12 M Bogey Container Transport", icon: "" },
  { title: "12 M Couple Container Transport'", icon: "" },
  { title: "12 M Curtainside Trailer", icon: "" },
  { title: "13.5 M Curtainside Trailer", icon: "" },
  { title: "15 M Curtainside Trailer", icon: "" },
  { title: "12 M Triaxle Curtainside Trailer", icon: "" },
  { title: "15 M Triaxle Curtainside Trailer", icon: "" },
  { title: "12 M Box Trailer", icon: "" },
  { title: "13.5 M Box Trailer", icon: "" },
  { title: "15 M Box Trailer", icon: "" },
  { title: "12 M Box Trailer-Taillift", icon: "" },
  { title: "13.5 M Reefer Box Trailer +15C - +25C", icon: "" },
  { title: "13.5 M Chiller Box Trailer +0C - +8C", icon: "" },
  { title: "13.5 M Frozen Box Trailer-20C - +0C", icon: "" },
  { title: "13.5 M Highbox Trailer", icon: "" },
  { title: "15 M Highbox Trailer", icon: "" },
  { title: "3-T Open Recovery", icon: "" },
  { title: "7-T Open Recovery", icon: "" },
  { title: "10-T Open Recovery", icon: "" },
  { title: "3-T Closed Recovery", icon: "" },
  { title: "7-T Closed Recovery", icon: "" },
  { title: "10-T Closed Recovery", icon: "" },
  { title: "13.5 M Double Deck Car Carrier", icon: "" },
  { title: "13.5 M Single Deck Car Carrier", icon: "" },
  { title: "25-T Lowbed Trailer", icon: "" },
  { title: "35-T Lowbed Trailer", icon: "" },
  { title: "50-T Lowbed Trailer", icon: "" },
  { title: "80-T Lowbed Trailer", icon: "" },
  { title: "100-T Lowbed Trailer", icon: "" },
  { title: "35-T Lowbed Trailer-ARS", icon: "" },
  { title: "50-T Lowbed Trailer-ARS", icon: "" },
  { title: "35-T Lowbed Trailer with Ramp", icon: "" },
  { title: "50-T Lowbed Trailer with Ramp", icon: "" },
  { title: "35-T Low-Lowbed Trailer", icon: "" },
  { title: "50-T Low-Lowbed Trailer", icon: "" },
  { title: "80-T Low-Lowbed Trailer", icon: "" },
  { title: "100-T Low-Lowbed Trailer", icon: "" },
  { title: "3-T Hiab", icon: "" },
  { title: "7-T Hiab", icon: "" },
  { title: "10-T Hiab", icon: "" },
  { title: "6 M + 12 M Superlink Flatbed Trailer", icon: "" },
  { title: "6 M + 12 M Superlink Closed Box Trailer", icon: "" },
  { title: "6 M + 12 M Superlink Reefer Box Trailer", icon: "" },
  { title: "6 M + 12 M Superlink Curtainside Trailer", icon: "" },
  { title: "Freezer" },
];
