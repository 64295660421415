import { LangAction, SET_LANGUAGE } from "../types/lang";

const initState: any = {
  lang: "en",
};

const langReducer = (state = initState, action: LangAction) => {
  switch (action.type) {
    case SET_LANGUAGE:
      return {
        ...state,
        lang: action.payload,
      };
    default:
      return state;
  }
};

export default langReducer;
