export const SET_ERROR = "SET_ERROR";
export const SET_SUCCESS = "SET_SUCCESS";
export const SET_BILL_LOADING = "SET_BILL_LOADING";
export const SET_BILL = "SET_BILL";
export const RESET_BILL = "RESET_BILL";
export const SET_BILLS = "SET_BILLS";
export const RESET_TRUCK = "RESET_TRUCK";

export interface Bill {
    id: string;
    _id: string;
    userId: string;
    startDate: any;
    endDate: any;
    amount: number;
    paid: boolean;
}

export interface BillState {
    loading: boolean;
    error: string;
    success: string;
    bill: Bill | null;
    bills: Bill[] | null;
}

export interface UpdateBillData {
    amount?: string;
    paid?: false;
}

//actions
interface SetLoadingAction {
    type: typeof SET_BILL_LOADING;
    payload: boolean;
}

interface SetErrorAction {
    type: typeof SET_ERROR;
    payload: string;
}

interface SetSuccessAction {
    type: typeof SET_SUCCESS;
    payload: string;
}

//actions
interface SetBillAction {
    type: typeof SET_BILL;
    payload: Bill;
}

interface ResetBillAction {
    type: typeof RESET_BILL;
}

interface SetLoadingAction {
    type: typeof SET_BILL_LOADING;
    payload: boolean;
}

interface SetErrorAction {
    type: typeof SET_ERROR;
    payload: string;
}

interface SetSuccessAction {
    type: typeof SET_SUCCESS;
    payload: string;
}

interface SetBillsAction {
    type: typeof SET_BILLS;
    payload: any | any[];
}

export type BillAction = SetLoadingAction | SetErrorAction | SetSuccessAction | SetBillAction | SetBillsAction | ResetBillAction