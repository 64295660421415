import { User } from "./auth";

export const SET_LOADING = "SET_LOADING";
export const SET_ERROR = "SET_ERROR";
export const SET_SUCCESS = "SET_SUCCESS";
export const SET_PARTNER = "SET_PARTNER";
export const SET_CLIENT = "SET_CLIENT";
export const SET_PARTNERS = "SET_PARTNERS";
export const SET_CLIENTS = "SET_CLIENTS";

export interface UsersState {
  partner: any | null;
  client: User | null;
  partners: User[] | null;
  clients: User[] | null;
  loading: boolean;
  error: string;
  success: string;
  fcm_token?: string;
}

//actions
interface SetPartnerAction {
  type: typeof SET_PARTNER;
  payload: any;
}

interface SetClientAction {
  type: typeof SET_CLIENT;
  payload: User;
}

interface SetPartnersAction {
  type: typeof SET_PARTNERS;
  payload: User[] | [];
}

interface SetClientsAction {
  type: typeof SET_CLIENTS;
  payload: User[] | [];
}

interface SetErrorAction {
  type: typeof SET_ERROR;
  payload: string;
}

interface SetSuccessAction {
  type: typeof SET_SUCCESS;
  payload: string;
}

interface SetLoadingAction {
  type: typeof SET_LOADING;
  payload: boolean;
}

export type UsersAction =
  | SetLoadingAction
  | SetSuccessAction
  | SetErrorAction
  | SetPartnersAction
  | SetClientsAction
  | SetPartnerAction
  | SetClientAction;
