import { DriverState, RESET_DRIVER, DriverAction, SET_DRIVER, SET_DRIVER_LOADING, SET_ERROR, SET_SUCCESS, SET_DRIVERS, SET_IS_ADDING_DRIVER, DELETE_DRIVER, SET_DRIVER_DELETED, SET_DRIVER_UPDATED } from '../types/driver';

const initState: DriverState = {
    driver: null,
    drivers: null,
    isAdding: false,
    loading: false,
    driverDeleted: false,
    driverUpdated: false,
    error: '',
    success: '',
}

const driverReducer = (state = initState, action: DriverAction) => {
    switch (action.type) {
        case SET_DRIVER_LOADING:
            return {
                ...state,
                loading: action.payload
            }
        case SET_IS_ADDING_DRIVER:
            return {
                ...state,
                isAdding: action.payload
            }
        case RESET_DRIVER:
            return {
                ...state,
                driver: null
            }
        case SET_DRIVER:
            return {
                ...state,
                driver: action.payload
            }
        case SET_DRIVERS:
            return {
                ...state,
                drivers: action.payload
            }
        case SET_ERROR:
            return {
                ...state,
                error: action.payload,
            }
        case DELETE_DRIVER:
            return {
                ...state,
                driverDeleted: true,
                drivers: state.drivers ? state.drivers.filter(driver => driver._id !== action.payload) : []
            }
        case SET_DRIVER_DELETED:
            return {
                ...state,
                driverDeleted: action.payload
            }
        case SET_DRIVER_UPDATED:
            return {
                ...state,
                driverUpdated: action.payload
            }
        case SET_SUCCESS:
            return {
                ...state,
                success: action.payload,
            }
        default:
            return state;
    }
}

export default driverReducer;