import { FC, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFilter,
  faPlus,
  faSortAmountDown,
} from "@fortawesome/free-solid-svg-icons";

import ArrowIcon from "../assets/icons/select_arrow.svg";
import { useSelector } from "react-redux";
import { RootState } from "../store";
import { translations } from "../utils/translations";
import ExportToExcel from "./ExportToExcel";

const ContractsSort: FC<any> = (props: any) => {
  const { lang } = useSelector((state: RootState) => state.lang);

  const [content, setContent] = useState<any>(translations["en"]);

  useEffect(() => {
    if (lang === "ar") setContent(translations["ar"]);
    else setContent(translations["en"]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang]);

  let { setSortBy, handleChange, client, admin, contracts, setModalShow } = props;

  return (
    <div className="filters-card flex-column flex-md-row my-4">
      {/* <div className="inputs-holder flex-column flex-md-row">
        <div className="input-holder pr-0 w-100">
          <div className="relative">
            <div>
              <FontAwesomeIcon icon={faFilter} />
            </div>
            <select
              name="sort_by"
              id="sort_by"
              onChange={(e) => handleChange(e.target.value, "type")}
            >
              <option value="" defaultValue="true">
                {content["all"]}
              </option>
              <option value="immediate">{content["asap"]}</option>
              <option value="scheduled">{content["scheduled"]}</option>
            </select>
            <label htmlFor="sort_by">
              <img src={ArrowIcon} alt="Select" />
            </label>
          </div>
        </div>
        <div className="mx-md-3 my-3 my-md-0 input-holder pr-0 w-100">
          <div className="relative">
            <div>
              <FontAwesomeIcon icon={faSortAmountDown} />
            </div>
            <select
              name="sort_by"
              id="sort_by"
              onChange={(e) => setSortBy(e.target.value)}
            >
              <option value="newest" defaultValue="true">
                {content["newest"]}
              </option>
              <option value="oldest">{content["oldest"]}</option>
              <option value="most_offers">{content["most_offers"]}</option>
              <option value="fewest_offers">{content["fewest_offers"]}</option>
            </select>
            <label htmlFor="sort_by">
              <img src={ArrowIcon} alt="Select" />
            </label>
          </div>
        </div>
      </div> */}
      <div></div>
      {client && (
        <a 
        className="bawsala-btn btn-primary text-decoration-none outline-none"
        onClick={() => setModalShow(true)}>
          <FontAwesomeIcon icon={faPlus} className="mr-2" />
          {content["add_contract"]}
        </a>
      )}
      {admin && <ExportToExcel apiData={contracts} fileName="contracts" />}
    </div>
  );
};

export default ContractsSort;
