import React, { FC, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { RootState } from "../store";
import Loader from "./Loader";
import { translations } from "../utils/translations";

const DeleteModal: FC<any> = (props: any) => {
  const { lang } = useSelector((state: RootState) => state.lang);

  const [content, setContent] = useState<any>(translations["en"]);

  useEffect(() => {
    if (lang === "ar") setContent(translations["ar"]);
    else setContent(translations["en"]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang]);

  let { handleDelete, setDeleteModalShow, done, toggleIsOpen, loading } = props;

  useEffect(() => {
    if (done) {
      setTimeout(() => {
        setDeleteModalShow(false);
      }, 2000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [done]);

  return (
    <Modal
      animation={false}
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <h4 className="w-100 text-center my-5">{content["warning"]}</h4>
        <p>
          {content["are_you_sure"]}{" "}
          <span className="text-weight-bold text-danger">
            {content["irreversible_action"]}
          </span>
        </p>
        {loading && <Loader height={75} width={75} />}
      </Modal.Body>
      <Modal.Footer>
        <button
          className="bawsala-btn btn-secondary px-4"
          onClick={toggleIsOpen}
          disabled={loading}
        >
          {content["cancel"]}
        </button>
        <button
          className="bawsala-btn btn-danger px-4"
          onClick={() => handleDelete()}
        >
          {loading ? content["loading"] : content["delete"]}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteModal;
