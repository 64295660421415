import React, { FC, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClipboard,
  faFileInvoiceDollar,
  faTag,
} from "@fortawesome/free-solid-svg-icons";
import { Link, useHistory } from "react-router-dom";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import Loader from "../Loader";
import moment from "moment";
import { capitalize } from "../../utils/helper";
import { readNotification } from "../../store/actions/notificationActions";
import EmptyResults from "../../components/EmptyResults";
import EmptyNotifications from "../../assets/illustrations/ringbell.png";
import { translations } from "../../utils/translations";
import { Notification } from "../../store/types/notification";

const NotificationsList: FC<{
  showNotifications: boolean;
  notifications: any;
  closeNotifs: any;
}> = ({ showNotifications, notifications, closeNotifs }) => {
  const { lang } = useSelector((state: RootState) => state.lang);

  const [content, setContent] = useState<any>(translations["en"]);

  useEffect(() => {
    if (lang === "ar") setContent(translations["ar"]);
    else setContent(translations["en"]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang]);

  const dispatch = useDispatch();
  const history = useHistory();
  const { user } = useSelector((state: RootState) => state.auth);
  const { loading } = useSelector((state: RootState) => state.notification);

  const getNotificationUrl = (notification: Notification) => {
    if (
      notification.userType === "carrier" &&
      notification.notificationType === "shipment"
    )
      return "new-requests/";
    else return notification.notificationType + "s/";
  };

  const handleNotif = async (notification: any) => {
    if (!notification.isRead) dispatch(readNotification(notification._id));
    history.push(
      "/" +
        notification.userType +
        "/" +
        getNotificationUrl(notification) +
        +notification.objectId
    );
    closeNotifs();
  };

  const handleAllNotifs = () => {
    history.push("/" + user?.role + "/notifications");
    closeNotifs();
  };

  return (
    <ClickAwayListener onClickAway={closeNotifs}>
      <div
        className={
          "dropdown-list dropdown-menu dropdown-menu-right shadow animated--grow-in" +
          (showNotifications ? " show" : "")
        }
      >
        <h6 className="dropdown-header">{content["notifications"]}</h6>
        {loading ? (
          <Loader height={75} width={75} />
        ) : notifications && notifications.length ? (
          <>
            {notifications
              .sort((n1: any, n2: any) =>
                n1.isRead === n2.isRead
                  ? Number(moment(n2.created_at)) -
                    Number(moment(n1.created_at))
                  : n1.isRead
                  ? 1
                  : -1
              )
              .slice(0, 5)
              .map((notification: any) => (
                <Link
                  className="dropdown-item d-flex align-items-center"
                  to="#"
                  key={"notif-" + notification._id}
                  onClick={() => handleNotif(notification)}
                >
                  <div className="mr-3">
                    <div
                      className={
                        "icon-circle " +
                        (notification.notificationType === "shipment"
                          ? "btn-primary"
                          : notification.notificationType === "offer"
                          ? "bg-success"
                          : "bg-warning")
                      }
                    >
                      <FontAwesomeIcon
                        icon={
                          notification.notificationType === "shipment"
                            ? faClipboard
                            : notification.notificationType === "offer"
                            ? faTag
                            : faFileInvoiceDollar
                        }
                        className="fas fa-file-alt text-white m-0"
                      />
                    </div>
                  </div>
                  <div>
                    <div className="small text-gray-500">
                      {moment(notification.created_at).fromNow()}
                    </div>
                    <span
                      className={notification.isRead ? "" : "font-weight-bold"}
                    >
                      {capitalize(notification.content)}
                    </span>
                  </div>
                </Link>
              ))}
            <Link
              className="dropdown-item d-flex justify-content-center small text-gray-500"
              to={"#"}
              onClick={() => handleAllNotifs()}
            >
              {content["see_all"]}
            </Link>
          </>
        ) : (
          <div className="dropdown-item empty_results d-flex align-items-center justify-content-center">
            <EmptyResults
              img={EmptyNotifications}
              text={content["no_notifications"]}
              width={120}
            />
          </div>
        )}
      </div>
    </ClickAwayListener>
  );
};

export default NotificationsList;
