import { FC, useState, FormEvent, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { RootState } from "../store";
import TextField from "@material-ui/core/TextField";
import "react-phone-input-2/lib/bootstrap.css";
import { create } from "jss";
import rtl from "jss-rtl";
import {
  StylesProvider,
  jssPreset,
  ThemeProvider,
  createTheme,
} from "@material-ui/core/styles";
import { translations } from "../utils/translations";
import toast from "react-hot-toast";
import axios from "axios";
import { tokenConfig } from "../store/actions/authActions";
import { getToastMessage } from "../utils/helper";
import Loader from "./Loader";
import { InputAdornment } from "@mui/material";

const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

const ltrTheme = createTheme({ direction: "ltr" });
const rtlTheme = createTheme({ direction: "rtl" });

const EditContractModel: FC<any> = (props: any) => {
  const { lang } = useSelector((state: RootState) => state.lang);
  const [content, setContent] = useState<any>(translations["en"]);
  useEffect(() => {
    if (lang === "ar") setContent(translations["ar"]);
    else setContent(translations["en"]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang]);

  let { setEditModalShow, id, contract, setContract, ...rest } = props;

  const [loading, setLoading] = useState<boolean>(false);
  const [isSubmit, setIsSubmit] = useState<boolean>(false);
  const [averageNumberOfShipments, setAverageNumberOfShipments] =
    useState<number>(contract.averageNumberOfShipments);
  const [period, setPeriod] = useState<string>(contract.period);
  const [priceOffered, setPriceOffered] = useState<number>(
    contract.priceOffered
  );
  const [priceOfferedRelated, setPriceOfferedRelated] = useState<string>(
    contract.priceOfferedRelated
  );
  const [averageShipmentDistance, setAverageShipmentDistance] =
    useState<number>(contract.averageShipmentDistance);
  const [contractDuration, setContractDuration] = useState<number>(
    contract.contractDuration
  );

  const periodOptions = ["week", "month", "year"];

  const resetData = () => {
    setAverageNumberOfShipments(contract.averageNumberOfShipments);
    setPeriod(contract.period);
    setPriceOffered(contract.priceOffered);
    setPriceOfferedRelated(contract.priceOfferedRelated);
    setAverageShipmentDistance(contract.averageShipmentDistance);
    setContractDuration(contract.contractDuration);
  };

  const updateContract = async (trk: any) => {
    const toastId = toast.loading(content["loading"]);
    setLoading(true);
    setIsSubmit(true);
    try {
      const config = tokenConfig();
      config.headers["Content-Type"] = "multipart/form-data";

      const body: any = trk;
      const formData = new FormData();
      for (const property in body) {
        if (body[property] !== null && body[property] !== "")
          formData.append(property, body[property]);
      }

      let { data } = await axios.put(
        `/api/v0.1/contracts/update/${id}`,
        formData,
        config
      );
      if (data.status === "success") {
        setContract(data.data);
        setEditModalShow(false);
        setLoading(false);
        toast.success(getToastMessage(data.message, lang as "en" | "ar"), {
          id: toastId,
        });
      } else {
        toast.error(getToastMessage(data.message, lang as "en" | "ar"), {
          id: toastId,
        });
        setLoading(false);
        setIsSubmit(false);
      }
    } catch (err: any) {
      toast.error(getToastMessage(err, lang as "en" | "ar"), {
        id: toastId,
      });
      setLoading(false);
      setIsSubmit(false);
    }
  };

  const submitHandler = async (e: FormEvent) => {
    e.preventDefault();
    updateContract({
      averageNumberOfShipments,
      period,
      priceOffered,
      priceOfferedRelated,
      averageShipmentDistance,
      contractDuration,
    });
  };

  const onClose = () => {
    resetData();
    setEditModalShow(false);
  };

  return (
    <Modal
      animation={false}
      {...rest}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={onClose}
    >
      <Modal.Body className="px-4">
        <h4 className="w-100 text-center mb-5">{content["edit_contract"]}</h4>
        {loading && !isSubmit ? (
          <Loader height={200} width={200} style={{ marginTop: 30 }} />
        ) : (
          <StylesProvider jss={jss}>
            <ThemeProvider theme={lang === "ar" ? rtlTheme : ltrTheme}>
              <form onSubmit={submitHandler}>
                <div className="row">
                  <div className="col-8">
                    <div className="form-group mb-4">
                      <TextField
                        id="averageNumberOfShipments"
                        className="w-100"
                        name="averageNumberOfShipments"
                        label={content["averageNumberOfShipments"]}
                        variant="outlined"
                        size="small"
                        value={averageNumberOfShipments}
                        required
                        onChange={(e: any) =>
                          setAverageNumberOfShipments(e.target.value)
                        }
                        disabled={loading}
                      />
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="form-group mb-4">
                      <select
                        id="period"
                        name="period"
                        className="form-control"
                        value={period}
                        onChange={(e) => setPeriod(e.target.value)}
                        disabled={loading}
                        required
                      >
                        <option value="" disabled>
                          {content["period"]}
                        </option>
                        {periodOptions.map((opt) => (
                          <option value={opt} key={opt}>
                            {content[opt]}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-8">
                    <div className="form-group mb-4">
                      <TextField
                        id="priceOffered"
                        className="w-100"
                        name="priceOffered"
                        label={content["priceOffered"]}
                        variant="outlined"
                        size="small"
                        value={priceOffered}
                        required
                        onChange={(e: any) => setPriceOffered(e.target.value)}
                        disabled={loading}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              {content["omr"]}
                            </InputAdornment>
                          ),
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="form-group mb-4">
                      <select
                        id="priceOfferedRelated"
                        name="priceOfferedRelated"
                        className="form-control"
                        value={priceOfferedRelated}
                        onChange={(e) => setPriceOfferedRelated(e.target.value)}
                        disabled={loading}
                        required
                      >
                        <option value="" disabled>
                          {content["priceOfferedRelated"]}
                        </option>
                        <option value="shipment">{content["shipment"]}</option>
                        <option value="month">{content["month"]}</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-group mb-4">
                      <TextField
                        id="averageShipmentDistance"
                        className="w-100"
                        name="averageShipmentDistance"
                        label={content["averageShipmentDistance"]}
                        variant="outlined"
                        size="small"
                        value={averageShipmentDistance}
                        required
                        onChange={(e: any) =>
                          setAverageShipmentDistance(e.target.value)
                        }
                        disabled={loading}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              {content["km"]}
                            </InputAdornment>
                          ),
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-group mb-4">
                      <TextField
                        id="contractDuration"
                        className="w-100"
                        name="contractDuration"
                        label={content["contractDuration"]}
                        variant="outlined"
                        size="small"
                        value={contractDuration}
                        required
                        onChange={(e: any) =>
                          setContractDuration(e.target.value)
                        }
                        disabled={loading}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              {content["month"]}
                            </InputAdornment>
                          ),
                        }}
                      />
                    </div>
                  </div>
                </div>
              </form>
            </ThemeProvider>
          </StylesProvider>
        )}
      </Modal.Body>
      <Modal.Footer className="mt-2">
        <button
          className="bawsala-btn btn-secondary px-4"
          onClick={onClose}
          disabled={loading}
        >
          {content["cancel"]}
        </button>
        <button
          className="bawsala-btn btn-primary px-4"
          onClick={submitHandler}
          disabled={loading}
        >
          {loading ? content["loading"] : content["update"]}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditContractModel;
