import { FC, Suspense, useEffect, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import Loader from "./components/Loader";
import Router from "./routes/Router";
import { loadUser } from "./store/actions/authActions";
import { RootState } from "./store";
import { setLanguage } from "./store/actions/langActions";
import { Toaster } from "react-hot-toast";
import moment from "moment";
import "moment/locale/ar";
import "moment/locale/en-gb";

const App: FC = () => {
  const dispatch = useDispatch();
  const { globalLoading } = useSelector((state: RootState) => state.auth);
  const { lang } = useSelector((state: RootState) => state.lang);

  useLayoutEffect(() => {
    const savedLang = localStorage.getItem("language") || "en";
    if (savedLang) {
      dispatch(setLanguage(savedLang));
      moment.locale(savedLang === "ar" ? "ar" : "en-gb");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang]);

  useEffect(() => {
    dispatch(loadUser());
  }, [dispatch]);

  if (globalLoading)
    return <Loader height={200} width={200} style={{ margin: "auto" }} />;
  return (
    <>
      <Suspense
        fallback={
          <Loader height={200} width={200} style={{ margin: "auto" }} />
        }
      >
        <Toaster position="top-center" reverseOrder={false} />
        <Router />
      </Suspense>
    </>
  );
};

export default App;
