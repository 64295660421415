import { FC, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import moment from "moment";
import {
  faChevronRight,
  faChevronLeft,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../styles/shipment_card.scss";
import { useSelector } from "react-redux";
import { RootState } from "../store";
import { translations } from "../utils/translations";
import { getContractStatusColor } from "../utils/helper";

const ContractCard: FC<any> = (props: any) => {
  const { lang } = useSelector((state: RootState) => state.lang);

  const [content, setContent] = useState<any>(translations["en"]);

  useEffect(() => {
    if (lang === "ar") setContent(translations["ar"]);
    else setContent(translations["en"]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang]);

  let { contract } = props;

  const { user } = useSelector((state: RootState) => state.auth);

  const history = useHistory();

  return (
    <div className="col-md-6 mb-4">
      <div
        className="shipment-card"
        onClick={() => history.push(`/${user?.role}/contracts/${contract._id}`)}
      >
        <div>
          <div>
            <div className="title">{content["order_date"]}</div>
            <div>{moment(contract.created_at).format("DD MMM yyyy")}</div>
            <div className="title">{content["averageNumberOfShipments"]}</div>
            <div>
              {`${contract.averageNumberOfShipments} ${content[
                "shipments"
              ].toLowerCase()}/${content[contract.period].toLowerCase()}`}
            </div>
            <div className="title">
              {content["averageShipmentDistance_short"]}
            </div>
            <div>
              {`${contract.averageShipmentDistance} ${content["km"]}/${content[
                "shipment"
              ].toLowerCase()}`}
            </div>
            <div className="title">{content["priceOffered"]}</div>
            <div>
              {`${contract.priceOffered} ${content["omr"]}/${content[
                contract.priceOfferedRelated
              ].toLowerCase()}`}
            </div>
            <div className="title">{content["contractDuration"]}</div>
            <div>
              {`${contract.contractDuration} ${content["month"].toLowerCase()}`}
            </div>
          </div>
          <div className="position-absolute right mr-3 d-flex flex-column">
            <span
              className={
                "text-white bawsala-btn bg-" +
                getContractStatusColor(contract.status)
              }
            >
              {content[contract.status]}
            </span>
            <span className="bawsala-btn btn-primary mt-2">
              {`${contract.numberOffers} ${
                contract.numberOffers !== 1
                  ? content["offers"]
                  : content["offer"]
              }`}
            </span>
          </div>

          <Link to={`/${user?.role}/contracts/${contract._id}`}>
            {content["view_details"]}
            <FontAwesomeIcon
              icon={lang === "ar" ? faChevronLeft : faChevronRight}
            />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ContractCard;
