import { FC } from "react";
import { Modal } from "react-bootstrap";
import { isPdf } from "../utils/helper";

const ContractPreview: FC<any> = (props: any) => {
  let { title, file, setPreviewContract, content } = props;

  const downloadContract = (e: any) => {
    e.preventDefault();
    let fileSplit = file.split("/");

    fetch(e.target.href, {
      method: "GET",
      headers: {},
    })
      .then((response) => {
        response.arrayBuffer().then(function (buffer) {
          const url = window.URL.createObjectURL(new Blob([buffer]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", fileSplit[fileSplit.length - 1]);
          document.body.appendChild(link);
          link.click();
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Modal
      animation={false}
      size="lg"
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="contract-modal"
    >
      <Modal.Body className="d-flex flex-column justify-content-between">
        <div className="d-flex align-items-center justify-content-between mt-2 mb-4 pb-3 border-bottom">
          <h4>{title}</h4>
          {!isPdf(file) && (
            <a
              className="bawsala-btn btn-primary px-4"
              href={file}
              download
              onClick={(e) => downloadContract(e)}
            >
              {content["download"]}
            </a>
          )}
        </div>

        <object width="100%" height="70%" data={file}></object>
        <div className="d-flex justify-content-end pt-3 border-top mt-3">
          <button
            className="bawsala-btn btn-secondary px-4"
            onClick={() => setPreviewContract(false)}
          >
            {content["cancel"]}
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ContractPreview;
