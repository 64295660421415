import React, { FC, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useHistory } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { faLinkedinIn, faTwitter } from '@fortawesome/free-brands-svg-icons'
import { faFacebookF } from '@fortawesome/free-brands-svg-icons'
import { faInstagram } from '@fortawesome/free-brands-svg-icons'
import { faYoutube } from '@fortawesome/free-brands-svg-icons'

import Logo from '../assets/logo.svg'
import Trust1 from '../assets/images/trust1.png'
import Trust2 from '../assets/images/trust2.png'
import Trust3 from '../assets/images/trust3.png'

import { Link as ScrollLink, animateScroll as scroll } from 'react-scroll'
import { useSelector } from 'react-redux'
import { RootState } from '../store'
import AppStoreBadge from '../assets/images/app-store-badge.png'
import GooglePlayBadge from '../assets/images/google-play-badge.png'

const Footer: FC = () => {

    const translations = {
        'en': {
            'get-in-touch': 'Get in touch with us',
            'work-hours': 'Sun - Thu: 9:00 - 17:00',
            'subscribe-title': 'SUBSCRIBE VIA EMAIL',
            'subscribe-placeholder': 'Enter your Email Address',
            'terms-title': 'TERMS OF USE',
            'terms': 'Terms and Conditions',
            'service': 'Service Providers',
            'privacy': 'Privacy Policy',
            'navigation-title': 'NAVIGATION',
            'home': 'Home',
            'about': 'About us',
            'values': 'Values',
            'services': 'Services',
            'contact': 'Contact us',
            'copyright': 'Copyright © 2021 by Bawsala.com'
        },
        'ar': {
            'get-in-touch': 'ابق على تواصل معنا',
            'work-hours': 'الأحد - الخميس: 9:00 - 17:00',
            'subscribe-title': 'اشترك من خلال البريد الإلكتروني',
            'subscribe-placeholder': 'أدخل عنوان بريدك الالكتروني',
            'terms-title': 'تعليمات الاستخدام',
            'terms': 'الأحكام والشروط',
            'service': 'مقدمي الخدمة',
            'privacy': 'سياسة خاصة',
            'navigation-title': 'التنقل',
            'home': 'الرئيسية',
            'about': 'نبذة عنا',
            'values': 'قيمنا',
            'services': 'خدماتنا',
            'contact': 'تواصل معنا',
            'copyright': 'حقوق النشر © 2021 بواسطة Bawsala.com'
        },
    }

    const { lang } = useSelector((state: RootState) => state.lang);
    const [content, setContent] = useState<any>(translations['en']);

    useEffect(() => {
        if (lang === 'ar') setContent(translations['ar'])
        else setContent(translations['en'])
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lang]);

    const scrollToTop = () => {
        scroll.scrollToTop()
    }

    const history = useHistory();
    let path = history.location.pathname;

    return (
        <section id="footer">
            <div className="container footer-wrapper px-5">
                <div className="row">
                    <div className="col-md-4 d-flex flex-column justify-content-between">
                        <div>
                            <div className="footer-title">{content['get-in-touch']}</div>
                            <a href="tel:+96895347502">+968 9534 7502</a>
                            <p>contact@bawsala.com</p>
                            <p>{content['work-hours']}</p>
                        </div>
                        <div>
                            <div className="footer-title">{content['subscribe-title']}</div>
                            <div className="subscribe-holder">
                                <input type="email" placeholder={content['subscribe-placeholder']} required />
                                <div className="arrow"><FontAwesomeIcon icon={lang === 'en' ? faArrowRight : faArrowLeft} /></div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 d-flex justify-content-md-center my-5 my-md-0">
                        <div className="d-flex flex-column justify-content-between">
                            <div>
                                <div className="footer-title" onClick={scrollToTop}>{content['terms-title']}</div>
                                <Link to="/terms-and-conditions" onClick={scrollToTop}>{content['terms']}</Link>
                                <Link to="/privacy-policy" onClick={scrollToTop}>{content['privacy']}</Link>
                            </div>
                            {path === "/" ?
                                < div >
                                    <div className="footer-title mt-4">{content['navigation-title']}</div>
                                    <ScrollLink activeClass="menu-active" to="hero" spy={true} smooth={true} offset={-72}>
                                        {content['home']}
                                    </ScrollLink>
                                    <ScrollLink activeClass="menu-active" to="about" spy={true} smooth={true} offset={-72}>
                                        {content['about']}
                                    </ScrollLink>
                                    <ScrollLink activeClass="menu-active" to="values" spy={true} smooth={true} offset={-72}>
                                        {content['values']}
                                    </ScrollLink>
                                    <ScrollLink activeClass="menu-active" to="services" spy={true} smooth={true} offset={-72}>
                                        {content['services']}
                                    </ScrollLink>
                                    <ScrollLink activeClass="menu-active" to="contact" spy={true} smooth={true} offset={-72}>
                                        {content['contact']}
                                    </ScrollLink>
                                </div>
                                :
                                <div>
                                    <div className="footer-title mt-4">Navigation</div>
                                    <Link to="/" onClick={scrollToTop}>{content['home']}</Link>
                                    <Link to="/" onClick={scrollToTop}>{content['about']}</Link>
                                    <Link to="/" onClick={scrollToTop}>{content['values']}</Link>
                                    <Link to="/" onClick={scrollToTop}>{content['services']}</Link>
                                    <Link to="/" onClick={scrollToTop}>{content['contact']}</Link>
                                </div>
                            }
                        </div>
                    </div>
                    <div className="col-md-4 d-flex flex-column justify-content-between align-items-md-center">
                        <div className="text-md-center w-100">
                            <Link to="/" onClick={scrollToTop}><img src={Logo} alt="Bawsala Logo" className='w-50' /></Link>
                            <div className="social justify-content-between justify-content-md-around mb-4">
                                <Link to={{ pathname: "https://www.linkedin.com/company/bawsala-بوصلة/" }} target="_blank"><FontAwesomeIcon icon={faLinkedinIn} /> </Link>
                                <Link to={{ pathname: "https://www.facebook.com/Bawsala.Co" }} target="_blank"><FontAwesomeIcon icon={faFacebookF} /> </Link>
                                <Link to={{ pathname: "https://twitter.com/Bawsala_co" }} target="_blank"><FontAwesomeIcon icon={faTwitter} /> </Link>
                                <Link to={{ pathname: "https://www.instagram.com/bawsala.co/" }} target="_blank"><FontAwesomeIcon icon={faInstagram} /> </Link>
                                <Link to={{ pathname: "https://www.youtube.com/channel/UCq1b927ZnIKP4UR0UaTvfXA" }} target="_blank"><FontAwesomeIcon icon={faYoutube} /> </Link>
                            </div>
                        </div>
                        <div className="w-100 d-flex justify-content-between justify-content-md-around align-items-center mb-4">
                            <img src={Trust1} alt="Trust 1" />
                            <img src={Trust2} alt="Trust 2" />
                            <img src={Trust3} alt="Trust 3" />
                        </div>
                        <div className='w-100 d-flex flex-column justify-content-center align-items-end'>
                            <a href="https://play.google.com/store/apps/details?id=bawsala.app.bawsala" target='_blank' rel="noreferrer">
                                <img src={GooglePlayBadge} alt="PlayStore" width={"150px"} />
                            </a>
                            <a href="https://apps.apple.com/om/app/bawsala-logistics/id1605562174" target='_blank' rel="noreferrer">
                                <img src={AppStoreBadge} alt="AppStore" width={"150px"} />
                            </a>
                        </div>
                    </div>
                </div>
                <div className="row mt-4">
                    <p className="col copyrights">{content['copyright']}</p>
                </div>
            </div>
        </section >
    )
}

export default Footer