export const SET_SHIPMENT = "SET_SHIPMENT";
export const SET_SHIPMENT_ITEMS = "SET_SHIPMENT_ITEMS";
export const SET_SHIPMENTS = "SET_SHIPMENTS";
export const SET_ERROR = "SET_ERROR";
export const SET_SUCCESS = "SET_SUCCESS";
export const SET_SHIPMENT_LOADING = "SET_SHIPMENT_LOADING";
export const SET_IS_ADDING_SHIPMENT = "SET_IS_ADDING_SHIPMENT";
export const DELETE_SHIPMENT = "DELETE_SHIPMENT";
export const SET_SHIPMENT_DELETED = "SET_SHIPMENT_DELETED";
export const SET_SHIPMENT_UPDATED = "SET_SHIPMENT_UPDATED";
export const RESET_SHIPMENT = "RESET_SHIPMENT";

export interface Item {
    id: string;
    _id: string;
    clientId?: string;
    description?: string;
    height?: number;
    includingADR?: boolean;
    isStackable?: boolean;
    length?: number;
    numberItems: number;
    truckType?: string;
    type: string;
    weight?: number;
    width?: number;
}

export interface Shipment {
    id: string;
    _id: string;
    carrierId: string;
    clientId: string;
    currentLocation: any;
    driverId: string;
    dropOffLocation: any;
    dropOffLocationName: string;
    dropOffLocationType: string;
    numberOffers: number;
    numberProcessing: number;
    paymentReceived: boolean;
    paymentSent: boolean;
    pickUpLocation: any;
    pickUpLocationName: string;
    pickUpLocationType: string;
    pickUpDate?: string;
    pickUpTimeEndRange: string;
    pickUpTimeStartRange: string;
    priceAgreed: number;
    deliveringTime: any;
    status: string;
    type: string;
    truckType?: string;
    distance: number;
    created_at: any;
    transportItemIds: Array<string>;
    isTemplate: boolean;
    templateName: string
}

export interface ShipmentState {
    shipment: Shipment | null;
    shipmentItems: any[] | null;
    shipments: Shipment[] | null;
    loading: boolean;
    isAdding: boolean;
    error: string;
    success: string;
    shipmentDeleted: boolean;
    shipmentUpdated: boolean
}

export interface AddShipmentData {
    dropOffLocation: any;
    dropOffLocationName: string;
    dropOffLocationType: string;
    pickUpLocation: any;
    pickUpLocationName: string;
    pickUpLocationType: string;
    pickUpDate: string;
    pickUpTimeEndRange: string;
    pickUpTimeStartRange: string;
    transportItemIds: Array<string>;
    type: string;
    truckType?: string;
    distance: number;
    isTemplate?: boolean;
    templateName?: string
}

export interface UpdateShipmentData {
    currentLocation?: any;
    driverId?: string;
    carrierId?: string
    dropOffLocation?: any;
    dropOffLocationName?: string;
    dropOffLocationType?: string;
    numberOffers?: number;
    paymentReceived?: boolean;
    paymentSent?: boolean;
    pickUpLocation?: any;
    pickUpLocationName?: string;
    pickUpLocationType?: string;
    pickUpTimeEndRange?: string;
    pickUpTimeStartRange?: string;
    priceAgreed?: number;
    status?: string;
    transportItemIds?: Array<string>;
    pickUpDate?: string;
    type?: string;
    truckType?: string;
    distance?: number;
}

//actions
interface SetShipmentAction {
    type: typeof SET_SHIPMENT;
    payload: Shipment;
}

interface SetShipmentItemsAction {
    type: typeof SET_SHIPMENT_ITEMS;
    payload: any | any[];
}

interface ResetShipmentAction {
    type: typeof RESET_SHIPMENT;
}

interface SetDeleteShipmentAction {
    type: typeof DELETE_SHIPMENT;
    payload: string;
}

interface SetLoadingAction {
    type: typeof SET_SHIPMENT_LOADING;
    payload: boolean;
}

interface SetIsAddingAction {
    type: typeof SET_IS_ADDING_SHIPMENT;
    payload: boolean;
}

interface SetShipmentDeletedAction {
    type: typeof SET_SHIPMENT_DELETED;
    payload: boolean;
}

interface SetShipmentUpdatedAction {
    type: typeof SET_SHIPMENT_UPDATED;
    payload: boolean;
}

interface SetErrorAction {
    type: typeof SET_ERROR;
    payload: string;
}

interface SetSuccessAction {
    type: typeof SET_SUCCESS;
    payload: string;
}

interface SetShipmentsAction {
    type: typeof SET_SHIPMENTS;
    payload: any | any[];
}

export type ShipmentAction = SetLoadingAction | SetErrorAction | SetSuccessAction | SetShipmentAction | SetShipmentItemsAction | SetShipmentsAction | SetIsAddingAction | SetDeleteShipmentAction | SetShipmentUpdatedAction | SetShipmentDeletedAction | ResetShipmentAction