export const SET_TRUCK = "SET_TRUCK";
export const SET_TRUCKS = "SET_TRUCKS";
export const SET_ERROR = "SET_ERROR";
export const SET_SUCCESS = "SET_SUCCESS";
export const SET_TRUCK_LOADING = "SET_TRUCK_LOADING";
export const SET_IS_ADDING_TRUCK = "SET_IS_ADDING_TRUCK";
export const DELETE_TRUCK = "DELETE_TRUCK";
export const SET_TRUCK_UPDATED = "SET_TRUCK_UPDATED";
export const SET_TRUCK_DELETED = "SET_TRUCK_DELETED";
export const UPDATE_TRUCK = "UPDATE_TRUCK";
export const RESET_TRUCK = "RESET_TRUCK";

export interface Truck {
  id: string;
  _id: string;
  truckId: string;
  brand: string;
  carrierId: string;
  make: string;
  plateNumber: string;
  picture: string;
  document: string;
  truckType: string;
  weight: number;
  opal: boolean;
  opalFile?: string;
  standard: boolean;
  standardFile?: string;
  workCertificate?: string;
}

export interface TruckState {
  truck: Truck | null;
  trucks: Truck[] | null;
  loading: boolean;
  isAdding: boolean;
  error: string;
  success: string;
  truckUpdated: boolean;
  truckDeleted: boolean;
}

export interface AddTruckData {
  truckId: string;
  brand: string;
  make: string;
  plateNumber: string;
  picture?: File;
  document?: File;
  truckType: string;
  weight: number;
  opal?: boolean;
  opalFile?: File;
  standard?: boolean;
  standardFile?: File;
  workCertificate?: File;
}

export interface UpdateTruckData {
  truckId?: string;
  brand?: string;
  make?: string;
  plateNumber?: string;
  picture?: File;
  document?: File;
  truckType?: string;
  weight?: number;
  opal?: boolean;
  opalFile?: File;
  standard?: boolean;
  standardFile?: File;
  workCertificate?: File;
}

//actions
interface SetTruckAction {
  type: typeof SET_TRUCK;
  payload: Truck;
}

interface ResetTruckAction {
  type: typeof RESET_TRUCK;
}

interface SetDeleteTruckAction {
  type: typeof DELETE_TRUCK;
  payload: string;
}

interface SetLoadingAction {
  type: typeof SET_TRUCK_LOADING;
  payload: boolean;
}

interface SetIsAddingAction {
  type: typeof SET_IS_ADDING_TRUCK;
  payload: boolean;
}

interface SetTruckUpdatedAction {
  type: typeof SET_TRUCK_UPDATED;
  payload: boolean;
}

interface SetTruckDeletedAction {
  type: typeof SET_TRUCK_DELETED;
  payload: boolean;
}

interface SetTruckUpdated {
  type: typeof UPDATE_TRUCK;
}

interface SetErrorAction {
  type: typeof SET_ERROR;
  payload: string;
}

interface SetSuccessAction {
  type: typeof SET_SUCCESS;
  payload: string;
}

interface SetTrucksAction {
  type: typeof SET_TRUCKS;
  payload: any | any[];
}

export type TruckAction =
  | SetLoadingAction
  | SetErrorAction
  | SetSuccessAction
  | SetTruckAction
  | SetTrucksAction
  | SetIsAddingAction
  | SetDeleteTruckAction
  | SetTruckUpdated
  | SetTruckUpdatedAction
  | SetTruckDeletedAction
  | ResetTruckAction;
