import { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Redirect, RouteProps, useHistory } from "react-router-dom";
import { RootState } from "../store";
import "../styles/client.scss";

import { ReactComponent as IconDashboard } from "../assets/icons/dashboard_icon.svg";
import { ReactComponent as IconOffers } from "../assets/icons/offers_icon.svg";
import { ReactComponent as IconDrivers } from "../assets/icons/drivers.svg";
import { ReactComponent as IconShipments } from "../assets/icons/shipments_icon.svg";
import { ReactComponent as IconBills } from "../assets/icons/bills_icon.svg";
import { ReactComponent as IconTrucks } from "../assets/icons/truck_icon.svg";
import { ReactComponent as IconNotifications } from "../assets/icons/notifications_icon.svg";

import DashboardSideBar from "../components/dashboard/DashboardSideBar";
import DashboardNavBar from "../components/dashboard/DashboardNavBar";
import { logout } from "../store/actions/authActions";
import { translations } from "../utils/translations";

interface Props extends RouteProps {
  component: any;
}

const CarrierRoute: FC<Props> = ({ component: Component, ...rest }) => {
  const { lang } = useSelector((state: RootState) => state.lang);
  const [content, setContent] = useState<any>(translations["en"]);

  useEffect(() => {
    if (lang === "ar") setContent(translations["ar"]);
    else setContent(translations["en"]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang]);

  const { authenticated, user } = useSelector((state: RootState) => state.auth);

  const history = useHistory();
  let path = history.location.pathname;
  let prefix = path.split("/")[2];

  const links = [
    {
      title: content["requests"],
      prefix: "/carrier/new-requests",
      link: "/",
      active: prefix === "home" || prefix === "new-requests" ? true : false,
      icon: IconDashboard,
    },
    {
      title: content["contracts"],
      prefix: "/carrier/contracts",
      link: "/",
      active: prefix === "contracts" ? true : false,
      icon: IconDashboard,
    },
    {
      title: content["my_contracts"],
      prefix: "/carrier/my-contracts",
      link: "/",
      active: prefix === "my-contracts" ? true : false,
      icon: IconOffers,
    },
    {
      title: content["my_offers"],
      prefix: "/carrier/offers",
      link: "/",
      active: prefix === "offers" ? true : false,
      icon: IconOffers,
    },
    {
      title: content["my_shipments"],
      prefix: "/carrier/shipments",
      link: "/",
      active: prefix === "shipments" ? true : false,
      icon: IconShipments,
    },
    {
      title: content["my_drivers"],
      prefix: "/carrier/drivers",
      link: "/",
      active: prefix === "drivers" ? true : false,
      icon: IconDrivers,
    },
    {
      title: content["my_trucks"],
      prefix: "/carrier/trucks",
      link: "/",
      active: prefix === "trucks" ? true : false,
      icon: IconTrucks,
    },
    {
      title: content["my_bills"],
      prefix: "/carrier/bills",
      link: "/",
      active: prefix === "bills" ? true : false,
      icon: IconBills,
    },
    {
      title: content["my_notifications"],
      prefix: "/carrier/notifications",
      link: "/",
      active: prefix === "notifications" ? true : false,
      icon: IconNotifications,
    },
  ];

  const [toggled, setToggled] = useState<boolean>(false);

  const dispatch = useDispatch();

  const logOut = () => {
    dispatch(logout());
  };

  return (
    <div className="user-layout">
      <main className="main-content">
        <div className="content">
          <div id="wrapper">
            <DashboardSideBar
              toggled={toggled}
              setToggled={setToggled}
              links={links}
            />
            <div
              id="content-wrapper"
              className={
                "d-flex flex-column pb-5" + (toggled ? " toggled" : "")
              }
              style={{ paddingTop: "calc(70px + 1.5rem" }}
            >
              <DashboardNavBar
                toggled={toggled}
                setToggled={setToggled}
                logOut={logOut}
              />
              <Route
                render={(props) =>
                  authenticated &&
                  (user?.role === "carrier" || user?.role === "hybrid") ? (
                    <Component {...props} links={links} user={user} />
                  ) : (
                    <Redirect to="/" />
                  )
                }
                {...rest}
              />
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default CarrierRoute;
