import { BillState, BillAction, SET_BILL_LOADING, SET_ERROR, SET_SUCCESS, SET_BILL, SET_BILLS } from '../types/bill';

const initState: BillState = {
    loading: false,
    error: '',
    success: '',
    bill: null,
    bills: null,
}

const billReducer = (state = initState, action: BillAction) => {
    switch (action.type) {
        case SET_BILL_LOADING:
            return {
                ...state,
                loading: action.payload
            }
        case SET_ERROR:
            return {
                ...state,
                error: action.payload,
            }
        case SET_SUCCESS:
            return {
                ...state,
                success: action.payload,
            }
        case SET_BILL:
            return {
                ...state,
                bill: action.payload
            }
        case SET_BILLS:
            return {
                ...state,
                bills: action.payload
            }
        default:
            return state;
    }
}

export default billReducer;