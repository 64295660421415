import { ProfileState, ProfileAction, SET_PROFILE_LOADING, SET_ERROR, SET_SUCCESS, SET_PROFILE_UPDATED } from '../types/profile';

const initState: ProfileState = {
    loading: false,
    profileUpdated: false,
    error: '',
    success: '',
}

const profileReducer = (state = initState, action: ProfileAction) => {
    switch (action.type) {
        case SET_PROFILE_LOADING:
            return {
                ...state,
                loading: action.payload
            }
        case SET_ERROR:
            return {
                ...state,
                error: action.payload,
            }
        case SET_PROFILE_UPDATED:
            return {
                ...state,
                profileUpdated: action.payload
            }
        case SET_SUCCESS:
            return {
                ...state,
                success: action.payload,
            }
        default:
            return state;
    }
}

export default profileReducer;