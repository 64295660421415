import React, { FC, FormEvent, useEffect, useState } from 'react'
import { setSuccess, setError, Register } from '../store/actions/authActions';
import { useDispatch, useSelector } from 'react-redux';
//import IconPlay from '../assets/icons/play_icon.svg'
import { RootState } from '../store';
import Loader from './Loader';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import PhoneInput from 'react-phone-input-2';
import PasswordValidation from './PasswordValidation';
import '../styles/auth_popup.scss'
import 'react-phone-input-2/lib/bootstrap.css';

const CarrierAuthPopup: FC<any> = (props: any) => {

    let { setShowCarrierAuthModal, setShowAuthModal, setAuthSection } = props;

    const [isCompany, setIsCompany] = useState<boolean>(true);
    const [role, setRole] = useState<string>('carrier');
    const [firstName, setFirstName] = useState<string>('');
    const [lastName, setLastName] = useState<string>('');
    const [companyName, setCompanyName] = useState<string>('');
    const [companyLocation, setCompanyLocation] = useState<string>('');
    const [crNumber, setCrNumber] = useState<string>("");
    const [email, setEmail] = useState<string>('');
    const [phoneNumber, setPhoneNumber] = useState<any>('');
    const [password, setPassword] = useState<string>('');
    const [confirmPassword, setConfirmPassword] = useState<string>('');
    const [showSignupPassword, setShowSignupPassword] = useState<boolean>(false)
    const [showSignupConfirmPassword, setShowSignupConfirmPassword] = useState<boolean>(false)

    const dispatch = useDispatch();
    const { success, error, loading } = useSelector((state: RootState) => state.auth);

    const translations = {
        'en': {
            'account': 'You have an account?',
            'cta': 'You are one step from expermenting extraordinary',
            'cta-span': 'business experience',
            'title': 'Register new account',
            'independent': "I'm an independent carrier and not a company",
            'email': 'Email Address',
            'company_name': 'Company Name',
            'first-name': 'First Name',
            'last-name': 'Last Name',
            'company-location': 'Company Location',
            'crNumber': 'CR Number',
            'phone': 'Phone Number',
            'password': 'Password',
            'confirm-password': 'Confirm password',
            'loading': 'Loading...',
            'submit': 'Register Now',
            'account2': 'already have an account?',
            'sign-in': 'Sign In',
            'terms-text': 'by registing your account, you agree to our',
            'privacy': 'privacy rules',
            'and': 'and',
            'terms': 'terms of use',
        },
        'ar': {
            'account': 'لديك حساب؟',
            'cta': 'أنت على بعد خطوة واحدة من عيش',
            'cta-span': 'تجربة عمل غير عادية',
            'title': 'تسجيل حساب جديد',
            'independent': "أنا ناقل مستقل ولست شركة",
            'email': 'عنوان البريد الإلكتروني',
            'company_name': 'اسم الشركة',
            'first-name': 'الاسم',
            'last-name': 'اسم العائلة',
            'company-location': 'موقع الشركة',
            'crNumber': 'رقم السجل التجاري',
            'phone': 'رقم الهاتف',
            'password': 'كلمة المرور',
            'confirm-password': 'تأكيد كلمة المرور',
            'loading': 'جاري التحميل ...',
            'submit': 'سجل الآن',
            'account2': 'هل لديك حساب بالفعل؟',
            'sign-in': 'تسجيل الدخول',
            'terms-text': 'من خلال تسجيل حسابك ، فإنك توافق على',
            'privacy': 'قواعد الخصوصية',
            'and': 'و',
            'terms': 'شروط الاستخدام',
        },
    }

    const { lang } = useSelector((state: RootState) => state.lang);
    const [content, setContent] = useState<any>(translations['en']);

    useEffect(() => {
        if (lang === 'ar') setContent(translations['ar'])
        else setContent(translations['en'])
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lang]);

    useEffect(() => {
        return () => {
            if (error) {
                dispatch(setError(''));
            }
            if (success) {
                dispatch(setSuccess(''));
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const goToLogin = () => {
        setAuthSection("login")
        setShowCarrierAuthModal(false)
        setShowAuthModal(true)
    }

    const handleToggleCompany = () => {
        setIsCompany(!isCompany)
        setFirstName("")
        setLastName("")
        setCompanyName("")
        setCrNumber("")
    }

    const submitSignUpHandler = (e: FormEvent) => {
        e.preventDefault();
        setRole(role)
        dispatch(
            Register({
                firstName,
                lastName,
                companyName,
                companyLocation,
                crNumber,
                email,
                phoneNumber,
                password,
                confirmPassword,
                role
            })
        );
    }

    return (
        <div className={"cd-signin-modal js-signin-modal cd-signin-modal--is-visible"}>
            <div className="exm" onClick={() => setShowCarrierAuthModal(false)}></div>
            <div className="cd-signin-modal__container carrier-modal">
                <div className="cd-signin-modal__block js-signin-modal-block cd-signin-modal__block--is-selected" >
                    <div className="row flex-column-reverse flex-md-row m-0">
                        <div className="col-md-6 px-4 px-md-5">
                            <div>
                                <button className="bawsala-btn bawsala-btn-bordered" onClick={() => goToLogin()}>{content['account']}</button>
                                <h4>{content['cta']} <span>{content['cta-span']}</span></h4>
                                {/*<button className="video">
                                    Watch Video
                                    <img src={IconPlay} alt="Watch video" />
                                </button>*/}
                            </div>
                        </div>
                        <div className="col-md-6 p-4 p-md-5">
                            <h5>{content['title']}</h5>
                            <form className="cd-signin-modal__form" onSubmit={submitSignUpHandler}>

                                <div className="form-group d-flex">
                                    <input type="checkbox" id="is-individual-carrier" checked={!isCompany} className="is-individual mr-2 mt-2" onChange={handleToggleCompany} disabled={loading} />
                                    <label htmlFor="is-individual-carrier">{content['independent']}</label>
                                </div>

                                <div className="form-group">
                                    <input className="form-control"
                                        id="client-signup-email"
                                        type="email"
                                        name="email"
                                        required
                                        value={email}
                                        onChange={(e) => setEmail(e.currentTarget.value)}
                                        placeholder={content['email']}
                                        disabled={loading} />
                                </div>

                                {!isCompany ?
                                    <>
                                        <div className="form-group row">
                                            <div className="col-md-6 mb-3 mb-md-0">
                                                <input className="form-control"
                                                    id="firstName"
                                                    type="text"
                                                    name="firstName"
                                                    required
                                                    value={firstName}
                                                    onChange={(e) => setFirstName(e.currentTarget.value)}
                                                    placeholder={content['first-name']}
                                                    disabled={loading} />
                                            </div>
                                            <div className="col-md-6">
                                                <input className="form-control"
                                                    id="lastName"
                                                    type="text"
                                                    name="lastName"
                                                    required
                                                    value={lastName}
                                                    onChange={(e) => setLastName(e.currentTarget.value)}
                                                    placeholder={content['last-name']}
                                                    disabled={loading} />
                                            </div>
                                        </div>
                                    </>
                                    :
                                    <>
                                        <div className="form-group row">
                                            <div className="col-md-6 mb-3 mb-md-0">
                                                <input className="form-control"
                                                    id="companyName"
                                                    type="text"
                                                    name="companyName"
                                                    required
                                                    value={companyName}
                                                    onChange={(e) => setCompanyName(e.currentTarget.value)}
                                                    placeholder={content['company_name']}
                                                    disabled={loading} />
                                            </div>
                                            <div className="col-md-6">
                                                <input className="form-control"
                                                    id="crNumber"
                                                    type="text"
                                                    name="crNumber"
                                                    required
                                                    value={crNumber}
                                                    onChange={(e) => setCrNumber(e.currentTarget.value)}
                                                    placeholder={content['crNumber']}
                                                    disabled={loading} />
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <input className="form-control"
                                                id="client-signup-companyLocation"
                                                type="companyLocation"
                                                name="companyLocation"
                                                required
                                                value={companyLocation}
                                                onChange={(e) => setCompanyLocation(e.currentTarget.value)}
                                                placeholder={content['company-location']}
                                                disabled={loading} />
                                        </div>
                                    </>
                                }
                                <div className="form-group">
                                    <PhoneInput
                                        country={'om'}
                                        countryCodeEditable={false}
                                        enableSearch
                                        disableSearchIcon
                                        value={phoneNumber}
                                        onChange={phone => setPhoneNumber("+" + phone)}
                                        placeholder={content['phone']}
                                        disabled={loading}
                                    />
                                </div>

                                <div className="form-group pwd">
                                    <input className="form-control"
                                        id="carrier-signup-password"
                                        type={showSignupPassword ? "text" : "password"}
                                        name="password"
                                        required
                                        value={password}
                                        onChange={(e) => setPassword(e.currentTarget.value)}
                                        placeholder={content['password']}
                                        disabled={loading} />
                                    <FontAwesomeIcon icon={showSignupPassword ? faEyeSlash : faEye} onClick={() => setShowSignupPassword(!showSignupPassword)} />
                                </div>
                                {password !== "" && <PasswordValidation password={password} />}

                                <div className="form-group pwd">
                                    <input className="form-control"
                                        id="carrier-signup-confirm"
                                        type={showSignupConfirmPassword ? "text" : "password"}
                                        name="confirm-password"
                                        required
                                        value={confirmPassword}
                                        onChange={(e) => setConfirmPassword(e.currentTarget.value)}
                                        placeholder={content['confirm-password']}
                                        disabled={loading} />
                                    <FontAwesomeIcon icon={showSignupConfirmPassword ? faEyeSlash : faEye} onClick={() => setShowSignupConfirmPassword(!showSignupConfirmPassword)} />
                                </div>

                                {error && <div className="alert alert-danger text-center">{error}</div>}
                                {success && <div className="alert alert-success text-center">{success}</div>}
                                {loading && <Loader height={75} width={75} />}

                                <div className="form-group">
                                    <button className="bawsala-btn btn-primary w-100" type="submit" disabled={loading} >{loading ? content['loading'] : content['submit']}</button>
                                    <p className={(lang === "en" ? "float-right" : "float-left") + " mt-1"}>{content['account2']} <Link to="#!" onClick={() => goToLogin()}>{content['sign-in']}</Link></p>
                                </div>
                                <p className="text-center w-100 mt-5 px-md-4">{content['terms-text']} <Link to="/privacy-policy" target="_blank">{content['privacy']}</Link> {content['and']} <Link to="/terms-and-conditions" target="_blank">{content['terms']}</Link></p>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default CarrierAuthPopup