import { ThunkAction } from "redux-thunk";

import {
  AddNotificationData,
  SET_ERROR,
  SET_SUCCESS,
  NotificationAction,
  SET_NOTIFICATION_LOADING,
  SET_NOTIFICATIONS,
  RESET_NOTIFICATIONS,
  SET_NOTIFICATION_READ,
} from "../types/notification";
import { RootState } from "..";
import toast from "react-hot-toast";
import axios from "axios";
import { tokenConfig } from "./authActions";
import { getToastMessage } from "../../utils/helper";

const config = tokenConfig();

// Set loading
export const setLoading = (
  value: boolean
): ThunkAction<void, RootState, null, NotificationAction> => {
  return (dispatch) => {
    dispatch({
      type: SET_NOTIFICATION_LOADING,
      payload: value,
    });
  };
};

//reset notification to null
export const resetNotification = (): ThunkAction<
  void,
  RootState,
  null,
  NotificationAction
> => {
  return (dispatch) => {
    dispatch({
      type: RESET_NOTIFICATIONS,
    });
  };
};

// Set notification deleted
export const setNotificationDeleted = (
  value: boolean
): ThunkAction<void, RootState, null, NotificationAction> => {
  return (dispatch) => {
    dispatch({
      type: SET_NOTIFICATION_READ,
      payload: value,
    });
  };
};

// Add new notification
export const createNotification = (
  data: AddNotificationData
): ThunkAction<void, RootState, null, NotificationAction> => {
  return async (dispatch) => {
    try {
      await axios.post(
        "/api/v0.1/notifications",
        { ...data, isRead: false },
        config
      );
    } catch (err) {
      console.log(err);
    }
  };
};

// Set error
export const setError = (
  msg: string
): ThunkAction<void, RootState, null, NotificationAction> => {
  return (dispatch) => {
    dispatch({
      type: SET_ERROR,
      payload: msg,
    });
  };
};

// Set success
export const setSuccess = (
  msg: string
): ThunkAction<void, RootState, null, NotificationAction> => {
  return (dispatch) => {
    dispatch({
      type: SET_SUCCESS,
      payload: msg,
    });
  };
};

//Get all notifications
export const getAllNotifications = (): ThunkAction<
  void,
  RootState,
  null,
  NotificationAction
> => {
  return async (dispatch) => {
    try {
      dispatch(setLoading(true));

      const notifications = await axios.get("/api/v0.1/notifications", config);
      dispatch(setLoading(false));

      dispatch({
        type: SET_NOTIFICATIONS,
        payload: notifications,
      });
    } catch (err) {
      console.log(err);
    }
  };
};

//Read notification
export const readNotification = (
  id: string
): ThunkAction<void, RootState, null, NotificationAction> => {
  return async (dispatch) => {
    const savedLang = localStorage.getItem("language");
    const toastId = toast.loading(
      savedLang === "ar" ? "جاري التحميل..." : "Loading..."
    );
    try {
      dispatch(setLoading(true));
      await axios.put("/api/v0.1/notifications/" + id, {}, config);

      toast.dismiss(toastId);
      dispatch(setLoading(false));
    } catch (err: any) {
      toast.error(getToastMessage(err, savedLang as "en" | "ar"), {
        id: toastId,
      });
    }
  };
};
