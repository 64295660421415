import { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../store";
import Loader from "./Loader";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf, faTimes } from "@fortawesome/free-solid-svg-icons";
import Avatar from "react-avatar";
import "../styles/offer_card.scss";
import { Link } from "react-router-dom";
import { displayShipmentStatus } from "../utils/helper";
import { translations } from "../utils/translations";
import { Box, Tooltip } from "@material-ui/core";

const ContractOfferCard: FC<any> = (props: any) => {
  const { lang } = useSelector((state: RootState) => state.lang);
  const [content, setContent] = useState<any>(translations["en"]);
  useEffect(() => {
    if (lang === "ar") setContent(translations["ar"]);
    else setContent(translations["en"]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang]);

  let {
    offer,
    contract,
    setEditModalShow,
    setDeleteModalShow,
    setShowUploadSigned,
    rejectOffer,
    handlePreviewContract,
    handleInitialAcceptance,
    handleAcceptOffer,
  } = props;

  const { user } = useSelector((state: RootState) => state.auth);
  const { loading } = useSelector((state: RootState) => state.offer);

  const handleClick = () => {
    if (offer.carrierId._id === user?._id) setDeleteModalShow(true);
    else rejectOffer(offer._id);
  };

  return loading ? (
    <Loader width={150} height={150} />
  ) : (
    <>
      <div className="col-md-6 mb-4 h-100" key={"offer-" + offer._id}>
        <div className="offer-card">
          <div className="d-flex align-items-center mb-4">
            <div className="driver-avatar show-details d-flex align-items-center">
              <Avatar
                src={offer.carrierId?.picture}
                name={
                  offer.carrierId?.companyName
                    ? offer.carrierId?.companyName
                    : offer.carrierId?.firstName +
                        " " +
                        offer.carrierId?.lastName || content["carrier"]
                }
                size="30"
                round={true}
              />
              <span>
                {offer.carrierId?.companyName
                  ? offer.carrierId?.companyName
                  : offer.carrierId?.firstName +
                      " " +
                      offer.carrierId?.lastName || content["carrier"]}
              </span>
            </div>

            {contract.status === "receiving_offers" &&
              offer.status === "pending" &&
              user?.role !== "admin" && (
                <Link
                  to="#!"
                  className="text-danger ml-auto"
                  title={
                    offer.carrierId._id === user?._id
                      ? content["remove_offer"]
                      : content["reject_offer"]
                  }
                  onClick={handleClick}
                >
                  <FontAwesomeIcon icon={faTimes} className="fa-lg" />
                </Link>
              )}
          </div>
          <div className="row mb-3">
            {offer.finalContractFile && (
              <div className="col-6">
                <Tooltip
                  title={
                    <span className={lang === "ar" ? " cairo" : ""}>
                      {content["client_contract"]}
                    </span>
                  }
                  placement="top-end"
                  arrow
                >
                  <Box
                    component="div"
                    className="d-flex align-items-center cursor-pointer"
                    onClick={() =>
                      handlePreviewContract(
                        offer.finalContractFile,
                        "client_contract"
                      )
                    }
                  >
                    <FontAwesomeIcon
                      icon={faFilePdf}
                      className="cursor-pointer mx-3 fa-lg text-success mb-1"
                    />
                    <span>{content["client_contract"]}</span>
                  </Box>
                </Tooltip>
              </div>
            )}
            {offer.signedContractFile && (
              <div className="col-6">
                <Tooltip
                  title={
                    <span className={lang === "ar" ? " cairo" : ""}>
                      {content["signed_contract"]}
                    </span>
                  }
                  placement="top-end"
                  arrow
                >
                  <Box
                    component="div"
                    className="d-flex align-items-center cursor-pointer"
                    onClick={() =>
                      handlePreviewContract(
                        offer.signedContractFile,
                        "signed_contract"
                      )
                    }
                  >
                    <FontAwesomeIcon
                      icon={faFilePdf}
                      className="mx-3 fa-lg text-success mb-1"
                    />
                    <span>{content["signed_contract"]}</span>
                  </Box>
                </Tooltip>
              </div>
            )}
          </div>
          <div className="row m-0">
            <div className="col-5 d-flex align-items-end">
              <div className="offer-info-card w-100">
                <div className="date">
                  {moment(new Date(offer.startDate)).format("MM/DD/yyyy")}
                </div>
                <p>{content["date"]}</p>
              </div>
            </div>
            <div className="col-3 d-flex align-items-end">
              <div className="offer-info-card">
                <div className="price">
                  {offer.priceOffered} <small>{content["omr"]}</small>
                </div>
                <p>{"/" + content[offer.priceOfferedRelated].toLowerCase()}</p>
              </div>
              {offer.offerContractFile && (
                <Tooltip
                  title={
                    <span className={lang === "ar" ? " cairo" : ""}>
                      {content["offer_contract"]}
                    </span>
                  }
                  placement="top-end"
                  arrow
                >
                  <Box component="span">
                    <FontAwesomeIcon
                      icon={faFilePdf}
                      className="cursor-pointer ml-3 fa-lg text-success mb-1"
                      onClick={() =>
                        handlePreviewContract(
                          offer.offerContractFile,
                          "offer_contract"
                        )
                      }
                    />
                  </Box>
                </Tooltip>
              )}
            </div>
            <div className="col-4 p-0 d-flex align-items-end">
              {offer.carrierId._id === user?._id &&
                contract.status === "receiving_offers" &&
                offer.status === "pending" &&
                (!offer.finalContractFile ? (
                  <button
                    className="bawsala-btn btn-warning ml-auto text-white"
                    onClick={() => setEditModalShow(true)}
                  >
                    {content["edit_offer"]}
                  </button>
                ) : !offer.signedContractFile ? (
                  <button
                    className="bawsala-btn btn-warning ml-auto text-white"
                    onClick={() => setShowUploadSigned(true)}
                  >
                    {content["upload_signed_contract"]}
                  </button>
                ) : (
                  <></>
                ))}

              {contract.clientId === user?._id &&
                contract.status === "receiving_offers" &&
                offer.status === "pending" &&
                (offer.signedContractFile ? (
                  <div
                    className="offer-badge award-btn"
                    onClick={() => handleAcceptOffer(offer._id)}
                  >
                    {content["award_contract"]}
                  </div>
                ) : !offer.finalContractFile ? (
                  <div
                    className="offer-badge award-btn"
                    onClick={() => handleInitialAcceptance(offer._id)}
                  >
                    {content["initial_acceptance"]}
                  </div>
                ) : (
                  <div className="offer-badge pending">
                    {content["pending"]}
                  </div>
                ))}
              {(contract.status !== "receiving_offers" ||
                offer.status !== "pending") && (
                <div className={"offer-badge " + offer.status}>
                  {displayShipmentStatus(offer.status, lang)}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {offer.status === "accepted" && (
        <div className="col-12 mt-0 mb-4">
          <hr />
        </div>
      )}
    </>
  );
};

export default ContractOfferCard;
