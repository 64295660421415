import React, { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store";
import { setLanguage } from "../store/actions/langActions";

import '../styles/language-toggler.scss'

const languages = [
    { code: 'en', name: 'English' },
    { code: 'ar', name: 'العريية' },
]
const LanguageToggle: FC = () => {

    const dispatch = useDispatch()
    const { lang } = useSelector((state: RootState) => state.lang);

    const toggleLang = () => {
        if (lang === "ar") dispatch(setLanguage("en"))
        else dispatch(setLanguage("ar"))
    }

    const options = languages.map(language => {
        if (language.code !== lang) {
            return (
                <li onClick={toggleLang} key={language.code}>
                    <div className="d-flex align-items-center">
                        <div className={language.code}></div>
                        <span>{language.code === "en" ? language.code : "ع"}</span>
                    </div>
                </li>
            )
        } else return null
    });

    return (
        <div className="lang">
            <div className="d-flex align-items-center">
                <div className={lang}></div>
                <span>{lang === "en" ? lang : "ع"}</span>
            </div>
            <ul className="dropdown" >
                {options}
            </ul>
        </div>
    );
};
export default LanguageToggle;