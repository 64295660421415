export const has8Chars = (password: string) => {
    return password.length > 7
}

export const hasLowerCase = (password: string) => {
    return password.toUpperCase() !== password
}

export const hasUpperCase = (password: string) => {
    return password.toLowerCase() !== password
}

export const hasNumber = (password: string) => {
    return /\d/.test(password);
}

export const hasSpecialChar = (password: string) => {
    return /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(password); //eslint-disable-line
}

export const isValidPassword = (pass: string) => {
    return has8Chars(pass) && hasLowerCase(pass) && hasUpperCase(pass) && (hasNumber(pass) || hasSpecialChar(pass))
}
