import React, { FC, FormEvent, useEffect, useState } from "react";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { useHistory, useLocation } from "react-router-dom";
import toast from "react-hot-toast";
import axios from "axios";
import { useSelector } from "react-redux";
import { RootState } from "../store";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { translations } from "../utils/translations";

import "../styles/auth.scss";
import { isValidPassword } from "../utils/passwordUtil";
import { getToastMessage } from "../utils/helper";

const ResetPassword: FC = () => {
  const { lang } = useSelector((state: RootState) => state.lang);
  const [content, setContent] = useState<any>(translations["en"]);
  useEffect(() => {
    if (lang === "ar") setContent(translations["ar"]);
    else setContent(translations["en"]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang]);

  const history = useHistory();

  const location = useLocation();
  const resetToken: string = location.pathname.split("/")[2];

  const [loading, setLoading] = useState<boolean>(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] =
    useState<boolean>(false);

  const submitHandler = async (e: FormEvent) => {
    setLoading(true);
    e.preventDefault();
    const toastId = toast.loading(content["loading"]);

    if (!isValidPassword(password)) {
      setLoading(false);
      return toast.error(content["invalid_pass_format"], {
        id: toastId,
      });
    } else if (password !== confirmPassword) {
      setLoading(false);
      return toast.error(content["pass_match"], {
        id: toastId,
      });
    }

    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const body = JSON.stringify({ resetToken, password });

      axios
        .put("/api/v0.1/auth/reset-password", body, config)
        .then((res: any) => {
          toast.success(content["password_updated"], {
            id: toastId,
          });
          setTimeout(() => {
            history.push("/");
          }, 2000);
        })
        .catch((err: any) => {
          toast.error(getToastMessage(err, lang as "en" | "ar"), {
            id: toastId,
          });
        });
      setLoading(false);
    } catch (err: any) {
      setLoading(false);
      toast.error(getToastMessage(err, lang as "en" | "ar"), {
        id: toastId,
      });
    }
  };

  return (
    <div className="auth container auth px-5 flex-column">
      <div className="row justify-content-md-between my-5">
        <div className="col-md-7 content-text mb-4">
          <h1>{content["reset_pwd_title"]}</h1>
          <p>{content["reset_pwd_desc"]}</p>
        </div>
        <div className="col-md-5 form-wrapper d-flex align-items-center mb-4">
          <form className="w-100" onSubmit={submitHandler}>
            <div className="form-group">
              <label htmlFor="password">{content["new_password"]}</label>
              <div className="password-input-holder">
                <input
                  placeholder={content["password_placeholder"]}
                  type={showPassword ? "text" : "password"}
                  className="form-control"
                  name="password"
                  id="password"
                  value={password}
                  onChange={(e) => setPassword(e.currentTarget.value)}
                  autoComplete="off"
                  disabled={loading}
                  required
                />
                <FontAwesomeIcon
                  icon={showPassword ? faEyeSlash : faEye}
                  onClick={() => setShowPassword(!showPassword)}
                  color="#6e57a5"
                />
              </div>
            </div>

            <div className="form-group">
              <label htmlFor="confirmPassword">
                {content["confirm_password"]}
              </label>
              <div className="password-input-holder">
                <input
                  placeholder={content["confirm_password_placeholder"]}
                  type={showConfirmPassword ? "text" : "password"}
                  className="form-control"
                  name="confirmPassword"
                  id="confirm-password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.currentTarget.value)}
                  autoComplete="off"
                  disabled={loading}
                  required
                />
                <FontAwesomeIcon
                  icon={showConfirmPassword ? faEyeSlash : faEye}
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  color="#6e57a5"
                />
              </div>
            </div>
            <button
              className="bawsala-btn btn-primary w-100 mt-4"
              disabled={loading}
            >
              {content["reset_pwd_submit"]}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
