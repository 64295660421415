import { ShipmentState, RESET_SHIPMENT, ShipmentAction, SET_SHIPMENT, SET_SHIPMENT_ITEMS, SET_SHIPMENT_LOADING, SET_ERROR, SET_SUCCESS, SET_SHIPMENTS, SET_IS_ADDING_SHIPMENT, DELETE_SHIPMENT, SET_SHIPMENT_DELETED, SET_SHIPMENT_UPDATED } from '../types/shipment';

const initState: ShipmentState = {
    shipment: null,
    shipmentItems: null,
    shipments: null,
    isAdding: false,
    loading: false,
    shipmentDeleted: false,
    shipmentUpdated: false,
    error: '',
    success: '',
}

const shipmentReducer = (state = initState, action: ShipmentAction) => {
    switch (action.type) {
        case SET_SHIPMENT_LOADING:
            return {
                ...state,
                loading: action.payload
            }
        case SET_IS_ADDING_SHIPMENT:
            return {
                ...state,
                isAdding: action.payload
            }
        case RESET_SHIPMENT:
            return {
                ...state,
                shipment: null
            }
        case SET_SHIPMENT:
            return {
                ...state,
                shipment: action.payload
            }
        case SET_SHIPMENT_ITEMS:
            return {
                ...state,
                shipmentItems: action.payload
            }
        case SET_SHIPMENTS:
            return {
                ...state,
                shipments: action.payload
            }
        case SET_ERROR:
            return {
                ...state,
                error: action.payload,
            }
        case DELETE_SHIPMENT:
            return {
                ...state,
                shipmentDeleted: true,
                shipments: state.shipments ? state.shipments.filter(shipment => shipment._id !== action.payload) : []
            }
        case SET_SHIPMENT_DELETED:
            return {
                ...state,
                shipmentDeleted: action.payload
            }
        case SET_SHIPMENT_UPDATED:
            return {
                ...state,
                shipmentUpdated: action.payload
            }
        case SET_SUCCESS:
            return {
                ...state,
                success: action.payload,
            }
        default:
            return state;
    }
}

export default shipmentReducer;