import React, { FC, FormEvent, useEffect, useState } from 'react'

import { setSuccess, setError, Register } from '../store/actions/authActions';
import { useDispatch, useSelector } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

import UploadTruck from '../assets/images/default_truck_pic.svg';

import { RootState } from '../store';
import Loader from './Loader';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/bootstrap.css';
import PasswordValidation from './PasswordValidation';

const ClientSignUp: FC<any> = (props: any) => {

    let { authSection, showSignupPassword, setShowSignupPassword, showSignupConfirmPassword, setShowSignupConfirmPassword,
        firstName, setFirstName, lastName, setLastName, email, setEmail, companyLocation, setCompanyLocation, isCompany, setIsCompany,
        phoneNumber, setPhoneNumber, picture, image, uploadImage, workingField, setWorkingField, password, setPassword, confirmPassword,
        setConfirmPassword, companyName, setCompanyName, crNumber, setCrNumber } = props;

    const dispatch = useDispatch();
    const { success, error, loading } = useSelector((state: RootState) => state.auth);

    const translations = {
        'en': {
            'company_name': 'Company Name',
            'first-name': 'First Name',
            'last-name': 'Last Name',
            'crNumber': 'CR Number',
            'email': 'Email Address',
            'working-field': 'Working Field',
            'phone': 'Phone Number',
            'password': 'Password',
            'confirm-password': 'Confirm Password',
            'independent': "I'm an independent client and not a company",
            'company-location': 'Company Location',
            'upload': 'Upload Your Picture',
            'loading': 'Loading...',
            'submit': 'Create account',
        },
        'ar': {
            'company_name': 'اسم الشركة',
            'first-name': 'الاسم',
            'last-name': 'اسم العائلة',
            'crNumber': 'رقم السجل التجاري',
            'email': 'عنوان البريد الإلكتروني',
            'working-field': 'مجال العمل',
            'phone': 'رقم الهاتف',
            'password': 'كلمة المرور',
            'confirm-password': 'تأكيد كلمة المرور',
            'independent': "أنا عميل مستقل ولست شركة",
            'company-location': 'موقع الشركة',
            'upload': 'تحميل صورتك',
            'loading': 'جاري التحميل ...',
            'submit': 'إنشاء حساب',
        },
    }

    const { lang } = useSelector((state: RootState) => state.lang);
    const [content, setContent] = useState<any>(translations['en']);

    useEffect(() => {
        if (lang === 'ar') setContent(translations['ar'])
        else setContent(translations['en'])
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lang]);

    useEffect(() => {
        return () => {
            if (error) {
                dispatch(setError(''));
            }
            if (success) {
                dispatch(setSuccess(''));
            }
        }
    }, [success, error, dispatch]);

    const handleToggleCompany = () => {
        setIsCompany(!isCompany)
        setFirstName("")
        setLastName("")
        setCompanyName("")
        setCompanyLocation("")
        setCrNumber("")
    }


    const submitHandler = (e: FormEvent) => {
        e.preventDefault();
        if (error) {
            dispatch(setError(''));
        }
        if (success) {
            dispatch(setSuccess(''));
        }
        if (!isCompany) setCompanyLocation('')
        dispatch(
            Register({
                firstName,
                lastName,
                companyName,
                companyLocation,
                crNumber,
                email,
                phoneNumber,
                password,
                confirmPassword,
                picture,
                role: "client"
            })
        );
    }

    return (

        <div className={"cd-signin-modal__block js-signin-modal-block" + (authSection === "signup" ? " signup_block cd-signin-modal__block--is-selected" : "")} >
            <form className="cd-signin-modal__form" onSubmit={submitHandler}>
                <p className="cd-signin-modal__fieldset">
                    <input type="checkbox" id="is-individual" checked={!isCompany} className="is-individual cd-signin-modal__input" onChange={handleToggleCompany} disabled={loading} />
                    <label htmlFor="is-individual">{content['independent']}</label>
                </p>
                {isCompany ?
                    <>
                        <p className="cd-signin-modal__fieldset form-group">
                            <label className="cd-signin-modal__label cd-signin-modal__label--username cd-signin-modal__label--image-replace" htmlFor="client-signup-firstName">First Name</label>
                            <input className="form-control cd-signin-modal__input cd-signin-modal__input--full-width cd-signin-modal__input--has-padding cd-signin-modal__input--has-border"
                                id="client-signup-company-name"
                                type="text"
                                name="companyName"
                                required
                                value={companyName}
                                onChange={(e) => setCompanyName(e.currentTarget.value)}
                                placeholder={content['company_name']}
                                disabled={loading} />
                        </p>

                        <p className="cd-signin-modal__fieldset form-group">
                            <label className="cd-signin-modal__label cd-signin-modal__label--username cd-signin-modal__label--image-replace" htmlFor="client-signup-lastName">Last Name</label>
                            <input className="form-control cd-signin-modal__input cd-signin-modal__input--full-width cd-signin-modal__input--has-padding cd-signin-modal__input--has-border"
                                id="client-signup-cr-number"
                                type="text"
                                name="crNumber"
                                required
                                value={crNumber}
                                onChange={(e) => setCrNumber(e.currentTarget.value)}
                                placeholder={content['crNumber']}
                                disabled={loading} />
                        </p>

                        <p className="cd-signin-modal__fieldset form-group">
                            <label className="cd-signin-modal__label cd-signin-modal__label--email cd-signin-modal__label--image-replace" htmlFor="client-signup-email">Company Location</label>
                            <input className="form-control cd-signin-modal__input cd-signin-modal__input--full-width cd-signin-modal__input--has-padding cd-signin-modal__input--has-border"
                                id="company-location"
                                type="text"
                                name="companyLocation"
                                required
                                value={companyLocation}
                                onChange={(e) => setCompanyLocation(e.currentTarget.value)}
                                placeholder={content['company-location']}
                                disabled={loading} />
                        </p>
                    </>
                    :
                    <>
                        <p className="cd-signin-modal__fieldset form-group">
                            <label className="cd-signin-modal__label cd-signin-modal__label--username cd-signin-modal__label--image-replace" htmlFor="client-signup-firstName">First Name</label>
                            <input className="form-control cd-signin-modal__input cd-signin-modal__input--full-width cd-signin-modal__input--has-padding cd-signin-modal__input--has-border"
                                id="client-signup-firstName"
                                type="text"
                                name="firstName"
                                required
                                value={firstName}
                                onChange={(e) => setFirstName(e.currentTarget.value)}
                                placeholder={content['first-name']}
                                disabled={loading} />
                        </p>

                        <p className="cd-signin-modal__fieldset form-group">
                            <label className="cd-signin-modal__label cd-signin-modal__label--username cd-signin-modal__label--image-replace" htmlFor="client-signup-lastName">Last Name</label>
                            <input className="form-control cd-signin-modal__input cd-signin-modal__input--full-width cd-signin-modal__input--has-padding cd-signin-modal__input--has-border"
                                id="client-signup-lastName"
                                type="text"
                                name="lastName"
                                required
                                value={lastName}
                                onChange={(e) => setLastName(e.currentTarget.value)}
                                placeholder={content['last-name']}
                                disabled={loading} />
                        </p>
                    </>}

                <p className="cd-signin-modal__fieldset form-group">
                    <label className="cd-signin-modal__label cd-signin-modal__label--email cd-signin-modal__label--image-replace" htmlFor="client-signup-email">E-mail</label>
                    <input className="form-control cd-signin-modal__input cd-signin-modal__input--full-width cd-signin-modal__input--has-padding cd-signin-modal__input--has-border"
                        id="client-signup-email"
                        type="email"
                        name="email"
                        required
                        value={email}
                        onChange={(e) => setEmail(e.currentTarget.value)}
                        placeholder={content['email']}
                        disabled={loading} />
                </p>

                <p className="cd-signin-modal__fieldset form-group mb-4">
                    <label className="cd-signin-modal__label cd-signin-modal__label--password cd-signin-modal__label--image-replace" htmlFor="working-field">Working Field</label>
                    <input className="form-control cd-signin-modal__input cd-signin-modal__input--full-width cd-signin-modal__input--has-padding cd-signin-modal__input--has-border"
                        id="working-field"
                        type="text"
                        name="working-field"
                        required
                        value={workingField}
                        onChange={(e) => setWorkingField(e.currentTarget.value)}
                        placeholder={content['working-field']}
                        disabled={loading} />
                </p>

                <div className="cd-signin-modal__fieldset form-group mb-4 mt-0">
                    <PhoneInput
                        country={'om'}
                        countryCodeEditable={false}
                        enableSearch
                        disableSearchIcon
                        value={phoneNumber}
                        onChange={phone => setPhoneNumber("+" + phone)}
                        placeholder={content['phone']}
                        disabled={loading}
                    />
                </div>

                <div className="cd-signin-modal__fieldset form-group mb-4 mt-0">
                    <div className="form-group pwd">
                        <label className="cd-signin-modal__label cd-signin-modal__label--password cd-signin-modal__label--image-replace" htmlFor="signin-password">Password</label>
                        <input className="form-control pl-5"
                            id="password"
                            type={showSignupPassword ? "text" : "password"}
                            name="password"
                            required
                            value={password}
                            onChange={(e) => setPassword(e.currentTarget.value)}
                            placeholder={content['password']}
                            disabled={loading} />
                        <FontAwesomeIcon icon={showSignupPassword ? faEyeSlash : faEye} onClick={() => setShowSignupPassword(!showSignupPassword)} />
                    </div>
                    {password !== "" && <PasswordValidation password={password} />}
                </div>

                <div className="cd-signin-modal__fieldset form-group mb-4 mt-0">
                    <div className="form-group pwd">
                        <label className="cd-signin-modal__label cd-signin-modal__label--password cd-signin-modal__label--image-replace" htmlFor="signin-password">Password</label>
                        <input className="form-control pl-5"
                            id="client-signup-confirm-password"
                            type={showSignupConfirmPassword ? "text" : "password"}
                            name="confirm-password"
                            required
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.currentTarget.value)}
                            placeholder={content['confirm-password']}
                            disabled={loading} />
                        <FontAwesomeIcon icon={showSignupConfirmPassword ? faEyeSlash : faEye} onClick={() => setShowSignupConfirmPassword(!showSignupConfirmPassword)} />
                    </div>
                </div>

                <p className={"upload-asset form-group mt-5" + (loading ? " disabled" : "")}>
                    <label htmlFor="picture" className="w-100">
                        {content['upload']}
                        <div className="text-center">
                            <img className="my-3" src={image ? image : UploadTruck} alt="Select" width="100%" />
                        </div>
                    </label>
                    <input type="file" name="picture" id="picture" style={{ display: "none" }} onChange={uploadImage} accept="image/*" />
                </p>

                {error && <div className="alert alert-danger text-center">{error}</div>}
                {success && <div className="alert alert-success text-center">{success}</div>}
                {loading && <Loader height={75} width={75} />}

                <p className="cd-signin-modal__fieldset form-group">
                    <input className="bawsala-btn btn-primary w-100" type="submit" value={loading ? content['loading'] : content['submit']} disabled={loading} />
                </p>
            </form>
        </div>
    )
}

export default ClientSignUp;