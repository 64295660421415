import React, { FC, FormEvent, useEffect, useState } from "react";

import { login } from "../store/actions/authActions";
import { useDispatch, useSelector } from "react-redux";

import { RootState } from "../store";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

const Login: FC<any> = (props: any) => {
  let {
    authSection,
    changeAuthSection,
    showLoginPassword,
    setShowLoginPassword,
    email,
    setEmail,
    password,
    setPassword,
  } = props;

  const dispatch = useDispatch();
  const { loading } = useSelector((state: RootState) => state.auth);

  const translations = {
    en: {
      "email-placeholder": "Email Address",
      "password-placeholder": "Password",
      loading: "Loading...",
      submit: "Login",
      "password-forgotten": "Forgot your password?",
    },
    ar: {
      "email-placeholder": "عنوان البريد الإلكتروني",
      "password-placeholder": "كلمة المرور",
      loading: "جاري التحميل ...",
      submit: "تسجيل الدخول",
      "password-forgotten": "هل نسيت كلمة المرور؟",
    },
  };

  const { lang } = useSelector((state: RootState) => state.lang);
  const [content, setContent] = useState<any>(translations["en"]);

  useEffect(() => {
    if (lang === "ar") setContent(translations["ar"]);
    else setContent(translations["en"]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang]);

  const submitHandler = (e: FormEvent) => {
    e.preventDefault();
    dispatch(login({ email, password }));
  };

  return (
    <div
      className={
        "cd-signin-modal__block js-signin-modal-block" +
        (authSection === "login" ? " cd-signin-modal__block--is-selected" : "")
      }
    >
      <form className="cd-signin-modal__form" onSubmit={submitHandler}>
        <p className="cd-signin-modal__fieldset form-group">
          <label
            className="cd-signin-modal__label cd-signin-modal__label--email cd-signin-modal__label--image-replace"
            htmlFor="signin-email"
          >
            E-mail
          </label>
          <input
            className="form-control cd-signin-modal__input cd-signin-modal__input--full-width cd-signin-modal__input--has-padding cd-signin-modal__input--has-border"
            type="email"
            placeholder={content["email-placeholder"]}
            name="email"
            id="signin-email"
            required
            value={email}
            onChange={(e) => setEmail(e.currentTarget.value)}
            disabled={loading}
          />
        </p>

        <div className="cd-signin-modal__fieldset form-group my-4">
          <div className="form-group pwd">
            <label
              className="cd-signin-modal__label cd-signin-modal__label--password cd-signin-modal__label--image-replace"
              htmlFor="signin-password"
            >
              Password
            </label>
            <input
              className="form-control pl-5"
              id="client-signup-password"
              type={showLoginPassword ? "text" : "password"}
              name="password"
              required
              value={password}
              onChange={(e) => setPassword(e.currentTarget.value)}
              placeholder={content["password-placeholder"]}
              disabled={loading}
            />
            <FontAwesomeIcon
              icon={showLoginPassword ? faEyeSlash : faEye}
              onClick={() => setShowLoginPassword(!showLoginPassword)}
            />
          </div>
        </div>

        <p className="cd-signin-modal__fieldset form-group">
          <input
            className="bawsala-btn btn-primary w-100"
            type="submit"
            value={loading ? content["loading"] : content["submit"]}
            disabled={loading}
          />
        </p>
      </form>

      <p className="cd-signin-modal__bottom-message js-signin-modal-trigger">
        <a href="#0" onClick={() => changeAuthSection("reset")}>
          {content["password-forgotten"]}
        </a>
      </p>
    </div>
  );
};

export default Login;
