import React, { FC } from 'react'

const EmptyResults: FC<any> = (props: any) => {

    let { img, text, width, margin } = props

    return (
        <div className={"col-12 d-flex flex-column align-items-center mt-5" + (margin ? " my-5" : " mb-5")}>
            <img src={img} alt="Empty results" width={width ? width: 200} />
            <div className="mt-3">{text}</div>
        </div>
    )
}

export default EmptyResults;