import React, { FC, FormEvent, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../store";
import Login from "./Login";
import ClientSignUp from "./ClientSignup";
import PasswordForgotten from "./PasswordForgotten";

import "../styles/auth_popup.scss";

const AuthPopup: FC<any> = (props: any) => {
  let { setShowAuthModal, authSection, setAuthSection } = props;

  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [companyName, setCompanyName] = useState<string>("");
  const [crNumber, setCrNumber] = useState<string>("");
  const [companyLocation, setCompanyLocation] = useState<string>("");
  const [isCompany, setIsCompany] = useState<boolean>(true);
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [image, setImage] = useState<string>("");
  const [picture, setFile] = useState<any>(null);
  const [workingField, setWorkingField] = useState<string>("");

  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [showLoginPassword, setShowLoginPassword] = useState<boolean>(false);
  const [showSignupPassword, setShowSignupPassword] = useState<boolean>(false);
  const [showSignupConfirmPassword, setShowSignupConfirmPassword] =
    useState<boolean>(false);

  const translations = {
    en: {
      "sign-in": "Sign in",
      "sign-up": "New account",
      close: "Close",
    },
    ar: {
      "sign-in": "تسجيل الدخول",
      "sign-up": "حساب جديد",
      close: "إغلاق",
    },
  };

  const { lang } = useSelector((state: RootState) => state.lang);
  const [content, setContent] = useState<any>(translations["en"]);

  useEffect(() => {
    if (lang === "ar") setContent(translations["ar"]);
    else setContent(translations["en"]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang]);

  const changeAuthSection = (section: string) => {
    setShowLoginPassword(false);
    setShowSignupPassword(false);
    setAuthSection(section);
    setFirstName("");
    setLastName("");
    setEmail("");
    setPassword("");
    setConfirmPassword("");
  };

  const uploadImage = (e: FormEvent<HTMLInputElement>) => {
    if (e.currentTarget.files) {
      setImage(URL.createObjectURL(e.currentTarget.files[0]));
      setFile(e.currentTarget.files[0]);
    }
  };

  return (
    <div
      className={"cd-signin-modal js-signin-modal cd-signin-modal--is-visible"}
    >
      <div className="exm" onClick={() => setShowAuthModal(false)}></div>
      <div className="cd-signin-modal__container">
        <ul className="cd-signin-modal__switcher js-signin-modal-switcher js-signin-modal-trigger">
          <li>
            <a
              href="#0"
              className={authSection === "login" ? "cd-selected" : ""}
              onClick={() => changeAuthSection("login")}
            >
              {content["sign-in"]}
            </a>
          </li>
          <li>
            <a
              href="#0"
              className={authSection === "signup" ? "cd-selected" : ""}
              onClick={() => changeAuthSection("signup")}
            >
              {content["sign-up"]}
            </a>
          </li>
        </ul>

        <Login
          email={email}
          setEmail={setEmail}
          password={password}
          setPassword={setPassword}
          authSection={authSection}
          changeAuthSection={changeAuthSection}
          showLoginPassword={showLoginPassword}
          setShowLoginPassword={setShowLoginPassword}
        />

        <ClientSignUp
          firstName={firstName}
          setFirstName={setFirstName}
          lastName={lastName}
          setLastName={setLastName}
          email={email}
          setEmail={setEmail}
          companyName={companyName}
          setCompanyName={setCompanyName}
          crNumber={crNumber}
          setCrNumber={setCrNumber}
          companyLocation={companyLocation}
          setCompanyLocation={setCompanyLocation}
          isCompany={isCompany}
          setIsCompany={setIsCompany}
          phoneNumber={phoneNumber}
          setPhoneNumber={setPhoneNumber}
          picture={picture}
          image={image}
          uploadImage={uploadImage}
          workingField={workingField}
          setWorkingField={setWorkingField}
          password={password}
          setPassword={setPassword}
          confirmPassword={confirmPassword}
          setConfirmPassword={setConfirmPassword}
          authSection={authSection}
          showSignupPassword={showSignupPassword}
          setShowSignupPassword={setShowSignupPassword}
          showSignupConfirmPassword={showSignupConfirmPassword}
          setShowSignupConfirmPassword={setShowSignupConfirmPassword}
        />

        <PasswordForgotten
          email={email}
          setEmail={setEmail}
          authSection={authSection}
          changeAuthSection={changeAuthSection}
          setShowAuthModal={setShowAuthModal}
        />

        <a href="#0" className="cd-signin-modal__close js-close">
          {content["close"]}
        </a>
      </div>
    </div>
  );
};

export default AuthPopup;
