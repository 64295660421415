import { ContactAction, SET_LOADING, ContactState, SET_ERROR, SET_SUCCESS } from '../types/contact';

const initState: ContactState = {
    loading: false,
    error: '',
    success: '',
}

const contactReducer = (state = initState, action: ContactAction) => {
    switch (action.type) {
        case SET_LOADING:
            return {
                ...state,
                loading: action.payload
            }
        case SET_ERROR:
            return {
                ...state,
                error: action.payload,
            }
        case SET_SUCCESS:
            return {
                ...state,
                success: action.payload,
            }
        default:
            return state;
    }
}

export default contactReducer;