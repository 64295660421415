import React, { FC, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import Logo from "../../assets/logo.svg";
import LogoSmall from "../../assets/logo_small.svg";

import ".../../../src/styles/style.scss";
import ".../../../src/styles/metronic.scss";
import ".../../../src/styles/dashboard.scss";
import { useSelector } from "react-redux";
import { RootState } from "../../store";

const DashboardSideBar: FC<any> = (props: any) => {
  const { lang } = useSelector((state: RootState) => state.lang);

  let { toggled, setToggled, links } = props;

  const { notifications } = useSelector(
    (state: RootState) => state.notification
  );

  useEffect(() => {
    const savedToggled = localStorage.getItem("sidebarToggled");
    if (savedToggled && savedToggled === "true") setToggled(true);
    else setToggled(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ul
      className={
        "navbar-nav sidebar sidebar-dark accordion" +
        (toggled ? " toggled" : "")
      }
      id="accordionSidebar"
    >
      <li className="nav-item">
        <Link
          className={
            "sidebar-brand d-flex align-items-center justify-content-center"
          }
          to="/"
        >
          <img
            className="d-none d-md-flex "
            src={toggled ? LogoSmall : Logo}
            alt="Bawsala Logo"
            width={toggled ? "55%" : "65%"}
          />
          <img
            className="d-flex d-md-none "
            src={LogoSmall}
            alt="Bawsala Logo"
            width={"55%"}
          />
        </Link>
      </li>

      {links.map((item: any, index: any) => (
        <li
          className={`nav-item ${item.active ? "active" : ""}`}
          key={"side-item-" + index}
        >
          <Link
            className={
              "nav-link" +
              ((item.title === "My Notifications" ||
                item.title === "الإشعارات") &&
              notifications &&
              notifications.filter((n: any) => !n.isRead).length > 0
                ? " unread-notifs"
                : "")
            }
            to={item.prefix + item.link}
            key={"side-item-" + index}
          >
            <item.icon className={item.title === "Offers" ? "stroke" : ""} />
            <span>{item.title}</span>
            {(item.title === "My Notifications" ||
              item.title === "الإشعارات") &&
              notifications &&
              notifications.filter((n: any) => !n.isRead).length > 0 && (
                <span>
                  <b>
                    (
                    {notifications.filter((n: any) => !n.isRead).length < 10
                      ? `0${notifications.filter((n: any) => !n.isRead).length}`
                      : notifications.filter((n: any) => !n.isRead).length}
                    )
                  </b>
                </span>
              )}
          </Link>
        </li>
      ))}

      <div className="text-center d-none d-md-inline">
        <button
          className="rounded-circle border-0"
          id="sidebarToggle"
          onClick={() => {
            setToggled(!toggled);
            localStorage.setItem("sidebarToggled", (!toggled).toString());
          }}
        >
          <FontAwesomeIcon
            icon={
              !toggled
                ? lang === "ar"
                  ? faChevronRight
                  : faChevronLeft
                : lang === "ar"
                ? faChevronLeft
                : faChevronRight
            }
          />
        </button>
      </div>
    </ul>
  );
};

export default DashboardSideBar;
