export const SET_DRIVER = "SET_DRIVER";
export const SET_DRIVERS = "SET_DRIVERS";
export const SET_ERROR = "SET_ERROR";
export const SET_SUCCESS = "SET_SUCCESS";
export const SET_DRIVER_LOADING = "SET_DRIVER_LOADING";
export const SET_IS_ADDING_DRIVER = "SET_IS_ADDING_DRIVER";
export const DELETE_DRIVER = "DELETE_DRIVER";
export const SET_DRIVER_DELETED = "SET_DRIVER_DELETED";
export const SET_DRIVER_UPDATED = "SET_DRIVER_UPDATED"
export const RESET_DRIVER = "RESET_DRIVER";

export interface Driver {
    id: string;
    _id: string;
    carrierId: string;
    name: string;
    phoneNumber: string;
    picture: string;
}

export interface DriverState {
    driver: Driver | null;
    drivers: Driver[] | null;
    loading: boolean;
    isAdding: boolean;
    error: string;
    success: string;
    driverDeleted: boolean;
    driverUpdated: boolean;
}

export interface AddDriverData {
    name: string;
    phoneNumber: string;
    picture?: File;
}

export interface UpdateDriverData {
    name?: string;
    phoneNumber?: string;
    picture?: File;
}

//actions
interface SetDriverAction {
    type: typeof SET_DRIVER;
    payload: Driver;
}

interface ResetDriverAction {
    type: typeof RESET_DRIVER;
}

interface SetDeleteDriverAction {
    type: typeof DELETE_DRIVER;
    payload: string;
}

interface SetLoadingAction {
    type: typeof SET_DRIVER_LOADING;
    payload: boolean;
}

interface SetIsAddingAction {
    type: typeof SET_IS_ADDING_DRIVER;
    payload: boolean;
}

interface SetDriverDeletedAction {
    type: typeof SET_DRIVER_DELETED;
    payload: boolean;
}

interface SetDriverUpdatedAction {
    type: typeof SET_DRIVER_UPDATED;
    payload: boolean;
}

interface SetErrorAction {
    type: typeof SET_ERROR;
    payload: string;
}

interface SetSuccessAction {
    type: typeof SET_SUCCESS;
    payload: string;
}

interface SetDriversAction {
    type: typeof SET_DRIVERS;
    payload: any | any[];
}

export type DriverAction = SetLoadingAction | SetErrorAction | SetSuccessAction | SetDriverAction | SetDriversAction | SetIsAddingAction | SetDeleteDriverAction | SetDriverUpdatedAction | SetDriverDeletedAction | ResetDriverAction