import React, { FC, FormEvent, useEffect, useState } from "react";

import { useSelector } from "react-redux";

import "../styles/auth_popup.scss";
import { RootState } from "../store";
import toast from "react-hot-toast";
import axios from "axios";
import { emailRegex } from "../utils/const";
import { translations } from "../utils/translations";
import { getToastMessage } from "../utils/helper";

const PasswordForgotten: FC<any> = (props: any) => {
  let {
    authSection,
    changeAuthSection,
    setShowAuthModal,
    userType,
    email,
    setEmail,
  } = props;

  const { lang } = useSelector((state: RootState) => state.lang);
  const [content, setContent] = useState<any>(translations["en"]);

  useEffect(() => {
    if (lang === "ar") setContent(translations["ar"]);
    else setContent(translations["en"]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang]);

  const [loading, setLoading] = useState<boolean>(false);

  const submitHandler = async (e: FormEvent) => {
    setLoading(true);
    e.preventDefault();
    const toastId = toast.loading(content["loading"]);

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const body = JSON.stringify({ email });

    try {
      if (email === "") {
        setLoading(false);
        return toast.error(content["email_required"], {
          id: toastId,
        });
      } else if (!emailRegex.test(email)) {
        setLoading(false);
        return toast.error(content["invalid_email_format"], {
          id: toastId,
        });
      }
      let { data } = await axios.put(
        "/api/v0.1/auth/forgot-password",
        body,
        config
      );
      if (data) {
        toast.success(content["reset_email_sent"], {
          id: toastId,
        });
        setTimeout(() => {
          setShowAuthModal(false);
        }, 1000);
      }
      setLoading(false);
    } catch (err: any) {
      setLoading(false);
      return toast.error(getToastMessage(err, lang as "en" | "ar"), {
        id: toastId,
      });
    }
  };

  return (
    <div
      className={
        "cd-signin-modal__block js-signin-modal-block" +
        (authSection === "reset" ? " cd-signin-modal__block--is-selected" : "")
      }
    >
      <p className="cd-signin-modal__message">
        {content["pwd_forgotten_text"]}
      </p>
      <form className="cd-signin-modal__form" onSubmit={submitHandler}>
        <p className="cd-signin-modal__fieldset form-group mb-4">
          <label
            className="cd-signin-modal__label cd-signin-modal__label--email cd-signin-modal__label--image-replace"
            htmlFor={userType + "-reset-email"}
          >
            {content["email"]}
          </label>
          <input
            className="form-control cd-signin-modal__input cd-signin-modal__input--full-width cd-signin-modal__input--has-padding cd-signin-modal__input--has-border"
            id={userType + "-reset-email"}
            type="email"
            placeholder={content["email"]}
            onChange={(e) => setEmail(e.currentTarget.value)}
          />
        </p>
        <p className="cd-signin-modal__fieldset form-group">
          <input
            className="bawsala-btn btn-primary w-100"
            type="submit"
            value={content["pwd_forgotten_submit"]}
            disabled={loading}
          />
        </p>
      </form>
      <p className="cd-signin-modal__bottom-message js-signin-modal-trigger">
        <a href="#0" onClick={() => changeAuthSection("login")}>
          {content["back_to_login"]}
        </a>
      </p>
    </div>
  );
};

export default PasswordForgotten;
