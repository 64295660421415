import { UsersAction, SET_LOADING, UsersState, SET_ERROR, SET_SUCCESS, SET_PARTNERS, SET_CLIENTS, SET_PARTNER, SET_CLIENT } from '../types/users';

const initState: UsersState = {
    partner: null,
    client: null,
    partners: null,
    clients: null,
    loading: false,
    error: '',
    success: '',
}

const userReducer = (state = initState, action: UsersAction) => {
    switch (action.type) {
        case SET_LOADING:
            return {
                ...state,
                loading: action.payload
            }
        case SET_PARTNER:
            return {
                ...state,
                partner: action.payload
            }
        case SET_CLIENT:
            return {
                ...state,
                client: action.payload
            }
        case SET_PARTNERS:
            return {
                ...state,
                partners: action.payload
            }
        case SET_CLIENTS:
            return {
                ...state,
                clients: action.payload
            }
        case SET_ERROR:
            return {
                ...state,
                error: action.payload,
            }
        case SET_SUCCESS:
            return {
                ...state,
                success: action.payload,
            }
        default:
            return state;
    }
}

export default userReducer;