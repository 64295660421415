import React, { FC, useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLanguage, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';
import { setLanguage } from '../../store/actions/langActions';
import { ReactComponent as IconProfile } from '../../assets/icons/profile_icon.svg';

const UserOptions: FC<{ userOptions: boolean, closeOptions: any, logOut: any }> = ({ userOptions, closeOptions, logOut }) => {

  const translations = {
    'en': {
      "profile": "My profile",
      "language": "العربية",
      "logout": "Logout"
    },
    'ar': {
      "profile": "الملف الشخصي",
      "language": "English",
      "logout": "تسجيل الخروج"
    },
  }

  const { user } = useSelector((state: RootState) => state.auth);
  const { lang } = useSelector((state: RootState) => state.lang);

  const [content, setContent] = useState<any>(translations['en']);

  useEffect(() => {
    if (lang === 'ar') setContent(translations['ar'])
    else setContent(translations['en'])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang]);

  const dispatch = useDispatch();

  const toggleLang = () => {
    if (lang === "ar") dispatch(setLanguage("en"))
    else dispatch(setLanguage("ar"))
    closeOptions()
  }

  return (
    <ClickAwayListener onClickAway={closeOptions}>
      <div className={"dropdown-menu dropdown-menu-right shadow animated--grow-in" + (userOptions ? " show" : "")} aria-labelledby="userDropdown">
        <Link className="dropdown-item" to={`/${user?.role}/profile`} onClick={() => closeOptions()}>
          <IconProfile className="fa-fw" fill='#d1d3e2 '/>
          {content['profile']}
        </Link>
        <div className="dropdown-divider"></div>
        <Link className="dropdown-item" to="#" onClick={() => toggleLang()}>
          <FontAwesomeIcon icon={faLanguage} className="fa-fw text-gray-400" />
          {content['language']}
        </Link>
        <div className="dropdown-divider"></div>
        <Link className="dropdown-item" to="#" onClick={logOut}>
          <FontAwesomeIcon icon={faSignOutAlt} className="fa-fw text-gray-400" />
          {content['logout']}
        </Link>
      </div>
    </ClickAwayListener>
  )
}

export default UserOptions