export const SET_LOADING = "SET_LOADING";
export const SET_GLOBAL_LOADING = "SET_GLOBAL_LOADING";
export const SET_ERROR = "SET_ERROR";
export const SET_SUCCESS = "SET_SUCCESS";
export const LOG_OUT = "LOG_OUT";
export const SET_USER = "SET_USER";
export const SET_LOGIN_SUCCESS = "SET_LOGIN_SUCCESS";

export interface User {
  id: string;
  _id: string;
  firstName: string;
  lastName: string;
  companyName: string;
  crNumber: string;
  phoneNumber: string;
  email: string;
  role: string;
  picture: string;
  isCompany: boolean;
  isContracted: boolean;
  companyLocation: string;
  workingField: string;
  thawaniId: string;
  activePaymentIntent: string;
  rating?: number;
  fcm_token?: string;
  credit?: number;
}

export interface AuthState {
  user: User | null;
  authenticated: boolean;
  loading: boolean;
  globalLoading: boolean;
  error: string;
  success: string;
}

export interface SignUpData {
  firstName: string;
  lastName: string;
  companyName: string;
  companyLocation?: string;
  crNumber: string;
  workingField?: string;
  email: string;
  phoneNumber: string;
  picture?: File;
  role: string;
  password: string;
  confirmPassword: string;
}

export interface LogInData {
  email: string;
  password: string;
}

//actions
interface SetUserAction {
  type: typeof SET_USER;
  payload: User;
}

interface SetLoginSuccessAction {
  type: typeof SET_LOGIN_SUCCESS;
  payload: any;
}

interface SetLoadingAction {
  type: typeof SET_LOADING;
  payload: boolean;
}

interface SetGlobalLoadingAction {
  type: typeof SET_GLOBAL_LOADING;
  payload: boolean;
}
interface LogOutAction {
  type: typeof LOG_OUT;
}

interface SetErrorAction {
  type: typeof SET_ERROR;
  payload: string;
}

interface SetSuccessAction {
  type: typeof SET_SUCCESS;
  payload: string;
}

export type AuthAction =
  | SetLoadingAction
  | SetGlobalLoadingAction
  | SetUserAction
  | LogOutAction
  | SetErrorAction
  | SetSuccessAction
  | SetLoginSuccessAction;
