import React, { FC } from 'react';
import { useLottie } from 'lottie-react';
import animationData from '../assets/lottie/loader.json';

interface LoaderProps {
    height: number,
    width: number,
    style?: object
}


const Loader: FC<LoaderProps> = (props: LoaderProps) => {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        },
        style: {
            width: props.width,
            height: props.height,
        }
    };
    const { View } = useLottie(defaultOptions);

    return (
        <div style={{
            height: "100%",
            width: "100%",
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        }}>
            {View}
        </div>
    );
}

export default Loader;