export const SET_NOTIFICATIONS = "SET_NOTIFICATIONS";
export const SET_ERROR = "SET_ERROR";
export const SET_SUCCESS = "SET_SUCCESS";
export const SET_NOTIFICATION_LOADING = "SET_NOTIFICATION_LOADING";
export const SET_NOTIFICATION_READ = "SET_NOTIFICATION_READ";
export const RESET_NOTIFICATIONS = "RESET_NOTIFICATIONS";

export interface Notification {
    _id: string;
    to: string;
    userType: string;
    notificationType: string;
    content: string;
    objectId: string;
    isRead: boolean;
    created_at: string;
}

export interface NotificationState {
  notifications: Notification[] | null;
  loading: boolean;
  error: string;
  success: string;
  notificationRead: boolean;
}

export interface AddNotificationData {
    to: string;
    userType: string;
    notificationType: string;
    content: string;
    objectId: string;
}

//actions
interface ResetNotificationsAction {
  type: typeof RESET_NOTIFICATIONS;
}

interface SetLoadingAction {
  type: typeof SET_NOTIFICATION_LOADING;
  payload: boolean;
}

interface SetNotificationRead {
  type: typeof SET_NOTIFICATION_READ;
  payload: boolean;
}

interface SetErrorAction {
  type: typeof SET_ERROR;
  payload: string;
}

interface SetSuccessAction {
  type: typeof SET_SUCCESS;
  payload: string;
}

interface SetNotificationsAction {
  type: typeof SET_NOTIFICATIONS;
  payload: any | any[];
}

export type NotificationAction =
  | SetLoadingAction
  | SetErrorAction
  | SetSuccessAction
  | SetNotificationsAction
  | ResetNotificationsAction
  | SetNotificationRead;
