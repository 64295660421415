import { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import CarrierAuthPopup from '../components/CarrierAuthModal';
import AuthPopup from '../components/ClientAuthModal';
import Footer from '../components/Footer';
import MainHeader from '../pages/LandingPage/MainHeader';

import { RootState } from '../store';

interface Props extends RouteProps {
    component: any;
}

const PublicRoute: FC<Props> = ({ component: Component, ...rest }) => {
    const { authenticated, user } = useSelector((state: RootState) => state.auth);

    const [showAuthModal, setShowAuthModal] = useState<boolean>(false)
    const [showCarrierAuthModal, setShowCarrierAuthModal] = useState<boolean>(false)
    const [authSection, setAuthSection] = useState<string>("login")

    return (
        <div>
            <MainHeader
                showAuthModal={showAuthModal}
                setShowAuthModal={setShowAuthModal}
                showCarrierAuthModal={showCarrierAuthModal}
                setShowCarrierAuthModal={setShowCarrierAuthModal}
                setAuthSection={setAuthSection} />
            <Route {...rest} render={props => !authenticated ? <Component {...props} /> :
                user?.role === "admin" ? <Redirect to="/admin/home" />
                    : (user?.role === "carrier" || user?.role === "hybrid") ? <Redirect to="/carrier/home" />
                        : user?.role === "client" && <Redirect to="/client/home" />} />
            <Footer />
            {showAuthModal && <AuthPopup
                setShowAuthModal={setShowAuthModal}
                authSection={authSection}
                setAuthSection={setAuthSection}
            />}
            {showCarrierAuthModal && <CarrierAuthPopup
                setShowCarrierAuthModal={setShowCarrierAuthModal}
                setAuthSection={setAuthSection}
                showAuthModal={showAuthModal}
                setShowAuthModal={setShowAuthModal}
                showCarrierAuthModal={showCarrierAuthModal}
            />}
        </div>
    );
}

export default PublicRoute;