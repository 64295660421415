import { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import AddContractModal from "../../../components/AddContractModal";
import { RootState } from "../../../store";
import Loader from "../../../components/Loader";
import axios from "axios";
import { tokenConfig } from "../../../store/actions/authActions";
import { translations } from "../../../utils/translations";
import ContractCard from "../../../components/ContractCard";
import EmptyResults from "../../../components/EmptyResults";
import EmptyContracts from "../../../assets/illustrations/confused.png";

import { Contract } from "../../../store/types/contract";
import Pagination from "../../../components/Pagination";
import ContractsSort from "../../../components/ContractsSort";

const ContractRequests: FC = () => {
  const { lang } = useSelector((state: RootState) => state.lang);

  const [content, setContent] = useState<any>(translations["en"]);

  useEffect(() => {
    if (lang === "ar") setContent(translations["ar"]);
    else setContent(translations["en"]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang]);

  const [loading, setLoading] = useState<boolean>(false);
  const [contracts, setContracts] = useState<any>(null);
  const [modalShow, setModalShow] = useState<boolean>(false);

  const getMyContracts = async () => {
    setLoading(true);
    try {
      let { data } = await axios.get(`/api/v0.1/contracts/my-contracts`, {
        ...tokenConfig(),
        timeout: 5000,
      });
      setContracts(data);
      setPagination((prev) => ({ ...prev, data, currentData: data }));
    } catch (err) {
      setLoading(false);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (!contracts) getMyContracts();
  }, []);

  const initPaginatationState = {
    data: contracts ?? [],
    offset: 0,
    numberPerPage: 10,
    pageCount: 0,
    currentData: [] as Contract[],
  };
  const [pagination, setPagination] = useState(initPaginatationState);

  return (
    <div className="container metronic-theme">
      {loading ? (
        <Loader height={200} width={200} style={{ marginTop: 30 }} />
      ) : (
        <div className="container">
          <div className="scene-card flex-column flex-md-row">
            <div className="mb-4 mb-md-0">
              <h3 className="scene-title text-center text-md-left mb-2">
                {content["my_contracts"]}
              </h3>
              <div className="scene-subtitle">
                {content["manage_my_contracts"]}
              </div>
            </div>
            <div>
              <h3 className="scene-title text-center text-md-right mb-2">
                {contracts
                  ? contracts.length < 10
                    ? "0" + contracts.length
                    : contracts.length
                  : 0}
              </h3>
              <div className="scene-subtitle">{content["total_contracts"]}</div>
            </div>
          </div>
          {/* <ContractsSort
            setSortBy={setSortBy}
            handleChange={handleChange}
            client
          /> */}
          <ContractsSort setModalShow={setModalShow} />

          {loading ? (
            <Loader height={200} width={200} style={{ marginTop: 30 }} />
          ) : (
            <div className="row">
              {/* <div className="col-md-2">
                <div className="status-filters d-flex flex-md-column mb-3">
                  <div
                    className={
                      "mr-md-5" + (data.status === "" ? " selected" : "")
                    }
                    onClick={() => {
                      handleChange("", "status");
                    }}
                  >
                    {content["all"]}
                  </div>
                  <div
                    className={
                      "mr-md-5" +
                      (data.status === "receiving_offers" ? " selected" : "")
                    }
                    onClick={() => {
                      handleChange("receiving_offers", "status");
                    }}
                  >
                    {content["pending"]}
                  </div>
                  <div
                    className={
                      "mr-md-5" + (data.status === "planned" ? " selected" : "")
                    }
                    onClick={() => {
                      handleChange("planned", "status");
                    }}
                  >
                    {content["planned"]}
                  </div>
                  <div
                    className={
                      "mr-md-5" + (data.status === "going" ? " selected" : "")
                    }
                    onClick={() => {
                      handleChange("going", "status");
                    }}
                  >
                    {content["going"]}
                  </div>
                  <div
                    className={
                      "mr-md-5" +
                      (data.status === "completed" ? " selected" : "")
                    }
                    onClick={() => {
                      handleChange("completed", "status");
                    }}
                  >
                    {content["completed"]}
                  </div>
                  <div
                    className={
                      "mr-md-5" +
                      (data.status === "reported" ? " selected" : "")
                    }
                    onClick={() => {
                      handleChange("reported", "status");
                    }}
                  >
                    {content["reported"]}
                  </div>
                  <div
                    className={
                      "mr-md-5" +
                      (data.status === "cancelled" ? " selected" : "")
                    }
                    onClick={() => {
                      handleChange("cancelled", "status");
                    }}
                  >
                    {content["cancelled"]}
                  </div>
                </div>
              </div> */}
              <div className="col-md-12">
                <div className="row mt-4">
                  {pagination.currentData &&
                  pagination.currentData.length > 0 ? (
                    pagination.currentData.map((contract: any) => (
                      <ContractCard
                        contract={contract}
                        key={"contract-" + contract._id}
                      />
                    ))
                  ) : (
                    <div className="col-12 text-center">
                      <EmptyResults
                        img={EmptyContracts}
                        text={content["no_contracts"]}
                      />
                    </div>
                  )}
                </div>
              </div>
              {contracts && contracts.length > 5 && (
                <Pagination
                  pagination={pagination}
                  setPagination={setPagination}
                  initPaginatationState={initPaginatationState}
                  sorted={contracts}
                />
              )}
            </div>
          )}
        </div>
      )}
      <AddContractModal
        show={modalShow}
        setModalShow={setModalShow}
        contracts={contracts}
        setContracts={setContracts}
        onHide={() => {
          setModalShow(false);
        }}
      />
    </div>
  );
};

export default ContractRequests;
