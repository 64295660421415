export const SET_OFFER = "SET_OFFER";
export const SET_OFFERS = "SET_OFFERS";
export const SET_ERROR = "SET_ERROR";
export const SET_SUCCESS = "SET_SUCCESS";
export const SET_OFFER_LOADING = "SET_OFFER_LOADING";
export const SET_IS_ADDING_OFFER = "SET_IS_ADDING_OFFER";
export const DELETE_OFFER = "DELETE_OFFER";
export const SET_OFFER_DELETED = "SET_OFFER_DELETED";
export const SET_OFFER_UPDATED = "SET_OFFER_UPDATED";
export const SET_OFFER_REJECTED = "SET_OFFER_REJECTED";
export const RESET_OFFER = "RESET_OFFER";
export const RESET_OFFERS = "RESET_OFFERS";

export interface Offer {
    id: string;
    _id: string;
    carrierId: string;
    deliveringTime: string;
    shipmentId: string;
    price: number;
    status: string;
    truckId: string;
    driverId: string
}

export interface OfferState {
    offer: Offer | null;
    offers: Offer[] | null;
    loading: boolean;
    isAdding: boolean;
    error: string;
    success: string;
    offerDeleted: boolean;
    offerUpdated: boolean;
    offerRejected: boolean;
}

export interface AddOfferData {
    deliveringTime: string;
    shipmentId: string;
    price: number;
    truckId: string;
    driverId: string
}

export interface UpdateOfferData {
    deliveringTime?: string;
    price?: number;
    status?: string;
    truckId?: string;
    driverId?: string
}

//actions
interface SetOfferAction {
    type: typeof SET_OFFER;
    payload: Offer;
}

interface ResetOfferAction {
    type: typeof RESET_OFFER;
}

interface ResetOffersAction {
    type: typeof RESET_OFFERS;
}

interface SetDeleteOfferAction {
    type: typeof DELETE_OFFER;
    payload: string;
}

interface SetLoadingAction {
    type: typeof SET_OFFER_LOADING;
    payload: boolean;
}

interface SetIsAddingAction {
    type: typeof SET_IS_ADDING_OFFER;
    payload: boolean;
}

interface SetOfferDeletedAction {
    type: typeof SET_OFFER_DELETED;
    payload: boolean;
}

interface SetOfferRejectedAction {
    type: typeof SET_OFFER_REJECTED;
    payload: boolean;
}

interface SetOfferUpdatedAction {
    type: typeof SET_OFFER_UPDATED;
    payload: boolean;
}

interface SetErrorAction {
    type: typeof SET_ERROR;
    payload: string;
}

interface SetSuccessAction {
    type: typeof SET_SUCCESS;
    payload: string;
}

interface SetOffersAction {
    type: typeof SET_OFFERS;
    payload: any | any[];
}

export type OfferAction = SetLoadingAction | SetErrorAction | SetSuccessAction | SetOfferAction | SetOffersAction | SetIsAddingAction | SetDeleteOfferAction | SetOfferUpdatedAction | SetOfferDeletedAction | ResetOfferAction | ResetOffersAction | SetOfferRejectedAction