import { ThunkAction } from 'redux-thunk';
import { LangAction, SET_LANGUAGE } from '../types/lang';
import { RootState } from '..';

// Set language
export const setLanguage = (value: string): ThunkAction<void, RootState, null, LangAction> => {
    return dispatch => {
        if (value === "ar") {
            document.getElementsByTagName('html')[0].setAttribute("dir", "rtl");
            document.getElementsByTagName('html')[0].setAttribute("lang", "ar");
            localStorage.setItem("language", "ar")
        } else if (value === "en") {
            document.getElementsByTagName('html')[0].setAttribute("dir", "ltr");
            document.getElementsByTagName('html')[0].setAttribute("lang", "en");
            localStorage.setItem("language", "en")
        }
        dispatch({
            type: SET_LANGUAGE,
            payload: value
        });
    }
}