export const translations = {
  en: {
    wrong_credentials: "Invalid email or password!",
    wrong_password: "Invalid password!",
    require_reset_password:
      "Your password seems to be outdated, please try to reset it!",
    account_blocked:
      "Your account is blocked, contact the support for more inforamtion!",
    login_success: "Signed in successfully",
    email_exist: "Email Address already in use, please try another one!",
    phone_exist: "Phone number already in use, please try another one!",
    invalid_email_format: "Invalid Email Format!",
    invalid_phone_format: "Invalid Phone Format!",
    invalid_pass_format: "Please enter a valid password!",
    pass_match: "Passwords do not match!",
    signup_success: "You have been registered successfully!",
    unknown_error: "An error has occured, please try again!",
    dashboard: "Dashboard",
    shipments: "Shipments",
    my_shipments: "My Shipments",
    requests: "New requests",
    the_offers: "Offers",
    offers: "Offers",
    my_offers: "My Offers",
    partners: "Partners",
    clients: "Clients",
    drivers: "Drivers",
    my_drivers: "My Drivers",
    trucks: "Trucks",
    my_trucks: "My Trucks",
    bills: "Bills",
    my_bills: "My Bills",
    my_notifications: "My Notifications",
    notifications: "Notifications",
    see_all: "Show All Notifications",
    add_driver: "Add a Driver",
    edit_driver: "Edit a Driver",
    name: "Name",
    upload_driver_picture: "Upload Driver Picture",
    make_offer: "Make Offer",
    edit_offer: "Edit Offer",
    price: "Price",
    delivering_time: "Delivering Time",
    driver: "Driver",
    driver_busy: "This driver already has a confirmed shipment in this date",
    truck: "Truck",
    truck_busy: "This truck already has a confirmed shipment in this date",
    driver_must:
      "You must have at least one driver to make offers, please go to",
    drivers_page: "Drivers Page",
    driver_must_add: "and add a driver first!",
    truck_must: "You must have at least one truck to make offers, please go to",
    trucks_page: "Trucks Page",
    trucks_must_add: "and add a truck first!",
    drivers_trucks_must:
      "You must have at least one truck and one driver to make offers",
    drivers_add_from: "You can add drivers from",
    trucks_add_from: "You can add trucks from",
    add_truck: "Add a Truck",
    edit_truck: "Edit a truck",
    truckType: "Truck Type",
    brand: "Brand",
    make: "Model",
    plateNumber: "Plate Number",
    truck_size: "Truck Size",
    upload_truck_picture: "Upload Truck Picture",
    upload_truck_document: "Upload Truck Document",
    invoice: "Invoice",
    invoice_number: "Invoice No:",
    today: "Today",
    last_seven_days: "Last 7 Days",
    last_thirty_days: "Last 30 Days",
    warning: "Warning!",
    are_you_sure: "Are you sure you want to proceed?",
    irreversible_action: "(This action is irreversible)",
    drag_marker: "Drag marker to precise location",
    no_details: "No details available for input: '",
    enter_pickup: "Enter a Pick-up location",
    enter_dropoff: "Enter a Drop-Off location",
    infos: "Information!",
    sure_reject: "Are you sure you want to reject this offer?",
    action_sort: "This action will mark this shipment as",
    action_sort_planned:
      "planned and the selected offer's carrier will be notified to proceed the shipping",
    action_sort_cancelled:
      "cancelled and carriers won't be able to submit anymore",
    action_sort_completed: "completed and no additional actions can be done",
    "confirm?": "Confirm?",
    offer: "Offer",
    order_date: "Order Date",
    vehicule_type: "Vehicule Type",
    asap: "As Soon As Possible",
    view_details: "View Details",
    receiving_offers: "Receiving Offers",
    pending: "Pending",
    planned: "Planned",
    going: "On Going",
    delivered: "Delivered",
    completed: "Completed",
    reported: "Reported",
    cancelled: "Cancelled",
    shipment_details: "Shipment Details",
    track_delivering: "Track Delivering",
    shipment_path: "Shipment path",
    from: "From",
    to: "To",
    shipment_type: "Shipment type",
    other_items: "Other Items",
    items_types: "Items types",
    adr: "Including ADR",
    no_adr: "No ADR",
    stackable: "Stackable",
    no_stackable: "Not Stackable",
    distance: "Distance",
    my_offer: "My Offer",
    add_offer: "Add Offer",
    or: "OR",
    processing_offer: "Let the shipper know that you are preparing an offer",
    remove_offer: "Remove Offer",
    reject_offer: "Reject Offer",
    date: "Date",
    carrier_offer: "Carrier Offer",
    award_shipment: "Award Shipment",
    shipment_null: "shipment has been cancelled or offered to a carrier",
    list_offers: "List Of Offers",
    person_preparing: " Person is preparing an offer for this shipment",
    persons_preparing: " Persons are preparing an offer for this shipment",
    no_offers: "No offers submitted",
    scheduled: "Scheduled",
    newest: "Newest",
    oldest: "Oldest",
    most_offers: "By offers number: Most first",
    fewest_offers: "By offers number: Fewest first",
    add_shipment: "Add new shipment",
    save_as_template: "Save as template",
    save_template: "Save this shipment as template",
    template_name: "Template Name",
    total_drivers: "Total Drivers",
    new_driver: "New Driver",
    phone_number: "Phone Number",
    no_drivers: "No Drivers Found",
    new_truck: "New Truck",
    type: "Type",
    max_weight: "Max Weight",
    document: "Document",
    no_trucks: "No Trucks Found",
    available_shipments: "Available Shipments",
    browse_shipments: "Browse All Available Shipments",
    manage_shipments: "Manage My shipments",
    total_shipments: "Total Shipments",
    manage_offers: "Manage All Submitted Offers",
    total_offers: "Total Offers",
    processing: "Processing",
    accepted: "Accepted",
    rejected: "Rejected",
    no_offers_found: "No Offers Found",
    choose_payment: "Choose your payment method",
    use: "Use",
    use_other_card: "Use another card",
    enter_card_infos: "Enter card information",
    not_reached: " is not reachable by land from ",
    create_request: "Create a Transport Request",
    select_template: "Select from templates",
    no_templates: "You have no saved templates",
    route: "Route",
    pickup_location: "Pick-up Location",
    location_type: "Location Type",
    pickup_location_type: "Pick-up Location Type",
    dropoff_location: "Drop-off Location",
    dropoff_location_type: "Drop-off Location Type",
    cwrwof: "Commercial with ramp but without forklift",
    cworwf: "Commercial without ramp but with forklift",
    cwo: "Commercial without ramp or forklift",
    cw: "Commercial with ramp and with forklift",
    rwf: "Residential with forklift",
    rwof: "Residential without forklift",
    truck_infos: "Truck Information",
    add_transport_item: "Add Transport Item",
    schedule_shipment: "Schedule My shipment",
    select_dates: "Select Dates",
    select_dates_note:
      "Please note: Pickup and drop-off dates are not guaranteed or binding.",
    pickup_date: "Pick-up Date",
    submit_request: "Submit your request",
    your_transportation_request: "Your Transportation Request",
    estimated_pickup: "Estimated Pickup",
    go_back_to: "Please go back to your",
    shipments_list: "shipments list",
    select_shipment_edit: "first and select a shipment to edit!",
    edit_transport_item: "Edit Transport Item",
    update_request: "Update your request",
    manage_my_shipments: "Manage my shipments",
    transport_item: "Transport Item ",
    container: "Container",
    pallet: "Pallet",
    box: "Box",
    roll: "Roll",
    other: "Other",
    description: "Description",
    length: "Length",
    width: "Width",
    height: "Height",
    weight: "Weight",
    weight_per_item: "Weight per item",
    cargo_stackable: "Cargo items are NOT stackable",
    adr_goods: "Including ADR goods",
    all_notifications: "My all notifications list",
    unread_notifications: "My unread notifications list",
    unread_only: "Unread only",
    no_notifications: "You have no notifications",
    no_notifications_found: "No Notifications Found",
    personal_infos: "Personal Inforamtion",
    personal_infos_update: "Update your personal information",
    user_infos: "User Inforamtion",
    avatar: "Avatar",
    change_avatar: "Change avatar",
    cancel_avatar: "Cancel avatar",
    allowed_files: "Allowed file types: png, jpg, jpeg.",
    first_name: "First Name",
    last_name: "Last Name",
    working_field: "Working Field",
    carrier: "Carrier",
    company: "Company",
    company_info: "Carrier Information",
    company_name: "Company Name",
    company_location: "Company Location",
    crNumber: "CR Number",
    contact_infos: "Contact Information",
    no_phone_sharing: "We'll never share your phone with anyone else.",
    email: "Email Address",
    no_email_sharing: "Your email address will not be shared for public.",
    bill_history: "My Bill History",
    bill_completed_shipemnts: "My Completed Shipments List",
    no_completed_shipments: "You have no completed shipments yet!",
    bills_history: "Bills History",
    bill_details: "Bill Details",
    active: "(Active)",
    loading_document: "Loading document...",
    donwlond_invoice: "Download Invoice",
    total_amount: "Total Amount",
    included_shipments: "Included Shipments",
    no_shipments: "No Shipments Found",
    no_bills: "No Bills Found!",
    no_user_bills:
      "You have no bills yet, you need to have at least one completed shipment!",
    no_active_bill: "No active bill for this period",
    all: "All",
    id: "ID #",
    km: "km",
    m: "m",
    cm: "cm",
    kg: "kg",
    tn: "t",
    omr: "OMR",
    create: "Create",
    update: "Update",
    confirm: "Confirm",
    delete: "Delete",
    loading: "Loading...",
    cancel: "Cancel",
    close: "Close",
    saving: "Saving...",
    save_changes: "Save Changes",
    no_clients: "No Clients Found",
    no_partners: "No Partners Found",
    security_standards: "Security Standards",
    opal_standard: "OPAL Standard",
    upload_opal_picture: "Upload OPAL Document",
    other_standard: "Other Standard",
    upload_standard_picture: "Upload Standard Document",
    done_shipments: "Completed Shipments",
    rate: "Rate",
    rate_carrier: "Rate Carrier",
    rate_desc: "Rate you experience with this carrier",
    rate_message: "Write something about your expereince...",
    shipment_requirements: "Shipment requirements",
    shipment_requirements_provided: "Provided shipment requirements",
    clearance: "Clearance",
    insurance: "Insurance",
    warehouse: "Warehouse",
    other_requirements: "Other requirements",
    other_requirements_placeholder:
      "Mention any other requirement you want the carriers to take in consideration (100 Character Limit)",
    all_shipments: "All shipments",
    manage_all_shipments: "Manage all shipments",
    recent_shipments: "Recently Added Shipments",
    recent_users: "Recently Registered Users",
    popular_trucks: "Most in demand Truck Types",
    shipments_per_status: "Shipments per status",
    popular_workfields: "Popular Work Fields",
    users: "Users",
    workFields: "Work Fields",
    all_offers: "All Offers",
    manage_partners: "Manage Partners",
    total_partners: "Total Partners",
    independent: "Individual",
    manage_clients: "Manage Clients",
    total_clients: "Total Clients",
    account_type: "Account Type",
    manage_drivers: "Manage Drivers",
    manage_trucks: "Manage Trucks",
    total_trucks: "Total Trucks",
    cta_subtitle:
      "Do you need a truck for your removals or for the transport of goods?",
    download_our_app: "Download our application now!",
    upload_work_certificate: "Upload work certificate",
    workCertificate: "Work certificate",
    picture: "صورة",
    opal: "Opan standard",
    standard: "Other standard",
    export: "Export Data",
    profile_updated: "Profile successfully updated",
    invalid_token: "Expired or invalid token",
    password_updated: "Your password has been successfully changed!",
    email_sent: "The email has been sent, please follow the instructions",
    no_user_email: "No user found for this email",
    email_already_verified: "Email already verified",
    email_required: "Email must not be empty!",
    reset_email_sent:
      "Please, follow the link sent to your email address to change your password!",
    new_password: "New password",
    password_placeholder: "Enter password",
    confirm_password: "Confirm password",
    confirm_password_placeholder: "Enter password again",
    reset_pwd_title: "Oops! you forgot your password",
    reset_pwd_desc: "Create your new password now",
    reset_pwd_submit: "Continue",
    pwd_forgotten_text:
      "Lost your password? Please enter your email address. You will receive a link to create a new password.",
    pwd_forgotten_submit: "Reset Password",
    back_to_login: "Back to log-in",
    dispute: "Dispute",
    open_dispute: "Open dispute",
    dispute_sent: "Your dispute has been sent successfully",
    solve_dispute: "Solve dispute",
    dispute_issue: "A dispute due to:",
    dispute_solved: "Dispute solved successfully",
    not_received: "Shipment not received",
    item_missing: "Item or items missing",
    item_broken: "Item or items broken",
    comment: "Commentary",
    mobile_user: "Please use the mobile application for this user type",
    select_option: "Please select an option",
    epayment: "Credit/Debit Card",
    account_credit: "Bawsala balance",
    account_credit_value: "Your current bawsala balance is: ",
    offer_amount: "You will be charged: ",
    shipment_not_editable:
      "Action not allowed, shipment might have been cancelled or already awarded to a carrier!",
    credit_insufficient:
      "Your bawsala balance is less than the offer amount, please choose another method or contact our support",
    credit_updated: "Balance updated successfully",
    manage_credits: "Manage Balances",
    edit_credit: "update Balance",
    amount: "Amount",
    transport_agreement: "Transport Agreement",
    transport_agreements: "Transport Agreements",
    view_agreement: "View Transport Agreement",
    agreement_approved: "Transport Agreement has been approved successfully",
    agreement_refused: "Transport Agreement has been refused successfully",
    sign_continue: "Sign and confirm",
    approve: "Approve",
    refuse: "Refuse",
    approved: "Approved",
    refused: "Refused",
    no_agreement_comment:
      "No comment added! which means the driver finds the items identical to the shipment description",
    decision: "Decision",
    contracts: "Contracts",
    total_contracts: "Total Contracts",
    new_contract: "New Contract",
    no_contracts: "No Contracts Found",
    upload_contract_file: "Upload Contract File",
    add_contract: "Add a Contract",
    averageNumberOfShipments: "Average number of shipments",
    averageNumberOfShipments_short: "Number of shipments",
    period: "Period",
    priceOffered: "Offered Price",
    priceOfferedRelated: "Price for",
    averageShipmentDistance: "Approximate average distance per shipment",
    averageShipmentDistance_short: "Approximate distance",
    contract: "Contract",
    day: "Day",
    week: "Week",
    month: "Month",
    year: "Year",
    shipment: "Shipment",
    edit_contract: "Edit a Contract",
    ongoing: "On Going",
    status: "Status",
    contract_details: "Contract Details",
    contract_null: "Contract has been cancelled or offered to a carrier",
    my_contracts: "My Contracts",
    manage_my_contracts: "Manage my contracts",
    startDate: "Start Date",
    award_contract: "Accept Offer",
    contractDuration: "Contract Duration",
    available_contracts: "Available Contracts",
    browse_contracts: "Browse All Available Contracts",
    initial_acceptance: "Initial Acceptance",
    use_same_contract: "Use same contract",
    upload_signed_contract: "Upload Signed Contract",
    offer_contract: "Offer Contract",
    client_contract: "Client Contract",
    signed_contract: "Signed Contract",
    download: "Download",
  },
  ar: {
    wrong_credentials: "بريد إلكتروني أو كلمة مرور غير صالحة!",
    wrong_password: "كلمة المرور غير صالحة!",
    require_reset_password:
      "يبدو أن كلمة المرور الخاصة بك قديمة , يرجى محاولة إعادة تعيينها!",
    account_blocked: "تم حظر حسابك , اتصل بالدعم لمزيد من المعلومات!",
    login_success: "تم تسجيل الدخول",
    email_exist: "عنوان البريد الإلكتروني قيد الاستخدام, يرجى تجربة عنوان آخر!",
    phone_exist: "رقم الهاتف قيد الاستخدام, يرجى تجربة رقم آخر!",
    invalid_email_format: "بريد إلكتروني غير صالح!",
    invalid_phone_format: "صيغة الهاتف غير صالحة!",
    invalid_pass_format: "الرجاء إدخال كلمة مرور صالحة!",
    pass_match: "كلمة المرور غير مطابقة!",
    signup_success: "لقد تم تسجيلك بنجاح!",
    unknown_error: "لقد حدث خطأ , يرجى المحاولة مرة أخرى!",
    dashboard: "لوحة المعلومات",
    shipments: "الشحنات",
    my_shipments: "شحناتي",
    requests: "الشحنات المتاحة",
    the_offers: "العروض",
    offers: "عروض",
    my_offers: "عروضي",
    partners: "الشركاء",
    clients: "العملاء",
    drivers: "السائقين",
    my_drivers: "السائقين",
    trucks: "الشاحنات",
    my_trucks: "الشاحنات الخاصة بي",
    bills: "الفواتير",
    my_bills: "الفواتير الخاصة بي",
    my_notifications: "الإشعارات الخاصة بي",
    notifications: "الإشعارات",
    see_all: "إظهار كافة الإشعارات",
    add_driver: "إضافة سائق جديد",
    edit_driver: "تحديث معلومات السائق",
    name: "الاسم",
    upload_driver_picture: "تحميل صورة السائق",
    make_offer: "تقديم عرض",
    edit_offer: "تحديث العرض",
    price: "السعر",
    delivering_time: "وقت التسليم",
    driver: "السائق",
    driver_busy: "هذا السائق لديه بالفعل مهمة مؤكدة في هذا التاريخ",
    truck: "الشاحنة",
    truck_busy: "هذه الشاحنة لديها بالفعل مهمة مؤكدة في هذا التاريخ",
    driver_must:
      "يجب أن يكون لديك سائق واحد على الأقل لتقديم العروض , يرجى الانتقال إلى",
    drivers_page: "صفحة السائقين",
    driver_must_add: "وأضف سائق أولاً!",
    truck_must:
      "يجب أن يكون لديك شاحنة واحدة على الأقل لتقديم العروض , يرجى الانتقال إلى",
    trucks_page: "صفحة الشاحنات",
    trucks_must_add: "وإضافة شاحنة أولاً!",
    drivers_trucks_must:
      "يجب أن يكون لديك شاحنة واحدة وسائق واحد على الأقل لتقديم العروض",
    drivers_add_from: "يمكنك إضافة سائقين من",
    trucks_add_from: "يمكنك إضافة شاحنات من",
    add_truck: "إضافة شاحنة جديدة",
    edit_truck: "تحديث معلومات الشاحنة",
    truckType: "نوع الشاحنة",
    brand: "العلامة التجارية",
    make: "الطراز",
    plateNumber: "رقم اللوحة",
    truck_size: "حجم الشاحنة",
    upload_truck_picture: "تحميل صورة الشاحنة",
    upload_truck_document: "تحميل مستند الشاحنة",
    invoice: "الفاتورة",
    invoice_number: "رقم الفاتورة:",
    today: "اليوم",
    last_seven_days: "آخر 7 أيام",
    last_thirty_days: "آخر 30 يومًا",
    warning: "تحذير!",
    are_you_sure: "هل أنت متأكد أنك تريد المتابعة؟",
    irreversible_action: "(هذا الإجراء لا رجوع فيه)",
    drag_marker: "اسحب العلامة إلى الموقع الدقيق",
    no_details: "لا توجد تفاصيل متاحة للنص المدخل: '",
    enter_pickup: "أدخل موقع الاستلام",
    enter_dropoff: "أدخل موقع التسليم",
    infos: "معلومات!",
    sure_reject: "هل أنت متأكد أنك تريد رفض هذا العرض؟",
    action_sort: "هذا الإجراء سيميز هذه الشحنة على أنها",
    action_sort_planned:
      "مخططة, سوف يتم اخطار شركة النقل بالعرض المحدد لمتابعة الشحن",
    action_sort_cancelled: "ملغاة ولن تتمكن شركات الشحن من إضافة عروض بعد الآن",
    action_sort_completed: "مكتملة ولا يمكن القيام بأي إجراءات إضافية",
    "confirm?": "تأكيد؟",
    offer: "عرض",
    order_date: "تاريخ الطلب",
    vehicule_type: "نوع المركبة",
    asap: "في أسرع وقت ممكن",
    view_details: "عرض التفاصيل",
    receiving_offers: "تلقي العروض",
    pending: "في الانتظار",
    planned: "مخططة",
    going: "قيد الشحن",
    completed: "مكتملة",
    delivered: "تم التوصيل",
    reported: "مبلغ عنها",
    cancelled: "ملغاة",
    shipment_details: "تفاصيل المهمة",
    track_delivering: "تتبع التسليم",
    shipment_path: "مسار الشحن",
    from: "من",
    to: "إلى",
    shipment_type: "نوع الشحنة",
    other_items: "عناصر أخرى",
    items_types: "أنواع العناصر ",
    adr: "يتضمن مواد خطرة",
    no_adr: "لا يتضمن مواد خطرة",
    stackable: "قابل للتكديس",
    no_stackable: "غير قابل للتكديس",
    distance: "المسافة",
    my_offer: "عرضي",
    add_offer: "إضافة عرض",
    or: "أو",
    processing_offer: "دع الشاحن يعرف أنك تحضر عرضًا",
    remove_offer: "إزالة العرض",
    reject_offer: "رفض العرض",
    date: "التاريخ",
    carrier_offer: "عرض الشاحن",
    award_shipment: "تسليم الشحنة",
    shipment_null: "تم إلغاء الشحنة أو تم تسليمها إلى شاحن",
    list_offers: "قائمة العروض",
    person_preparing: " شخص يقوم بإعداد عرض لهذه الشحنة",
    persons_preparing: " أشخاص يعدون عرضًا لهذه الشحنة",
    no_offers: " لم يتم تقديم اي عروض",
    scheduled: "مجدولة",
    newest: "الأحدث",
    oldest: "الأقدم",
    most_offers: "حسب عدد العروض: الأكثر أولاً",
    fewest_offers: "حسب عدد العروض: الأقل أولاً",
    add_shipment: "إضافة شحنة جديدة",
    save_as_template: "حفظ كنموذج",
    save_template: "احفظ هذه الشحنة كنموذج",
    template_name: "اسم النموذج",
    total_drivers: "إجمالي السائقين",
    new_driver: "سائق جديد",
    phone_number: "رقم الهاتف",
    no_drivers: "لم يتم العثور على سائقين",
    new_truck: "شاحنة جديدة",
    type: "النوع",
    max_weight: "الوزن الأقصى",
    document: "المستند",
    no_trucks: "لم يتم العثور على شاحنات",
    available_shipments: "الشحنات المتاحة",
    browse_shipments: "تصفح كافة الشحنات المتاحة",
    manage_shipments: "إدارة الشحنات الخاصة بي",
    total_shipments: "إجمالي الشحنات",
    manage_offers: "إدارة كافة العروض المقدمة",
    total_offers: "إجمالي العروض",
    processing: "قيد الإعداد",
    accepted: "مقبولة",
    rejected: "مرفوضة",
    no_offers_found: "لم يتم العثور على عروض",
    choose_payment: "اختر طريقة الدفع",
    use: "استخدام",
    use_other_card: "استخدم بطاقة أخرى",
    enter_card_infos: "أدخل معلومات البطاقة",
    not_reached: " لا يمكن الوصول إليه عن طريق البر من ",
    create_request: "إنشاء طلب نقل",
    select_template: "اختر من النماذج",
    no_templates: "ليس لديك نماذج محفوظة",
    route: "الطريق",
    pickup_location: "موقع الاستلام",
    location_type: "نوع الموقع",
    pickup_location_type: "نوع موقع الاستلام",
    dropoff_location: "موقع التسليم",
    dropoff_location_type: "نوع موقع التسليم",
    cwrwof: "تجاري بمنحدر ولكن بدون رافعة شوكية",
    cworwf: "تجاري بدون منحدر ولكن برافعة شوكية",
    cwo: "تجاري بدون منحدر أو رافعة شوكية",
    cw: "تجاري بمنحدر ورافعة شوكية",
    rwf: "سكني برافعة شوكية",
    rwof: "سكني بدون رافعة شوكية",
    truck_infos: "معلومات الشاحنة",
    add_transport_item: "إضافة عنصر النقل",
    schedule_shipment: "جدولة الشحن",
    select_dates: "تحديد التواريخ",
    select_dates_note: "ملاحظة: تواريخ الاستلام والتوصيل ليست مضمونة أو ملزمة.",
    pickup_date: "تاريخ الاستلام",
    submit_request: "أرسل طلبك",
    your_transportation_request: "طلب النقل الخاص بك",
    estimated_pickup: "مكان الاستلام المقدَّر",
    go_back_to: "الرجاء الرجوع إلى",
    shipments_list: "قائمة المهام",
    select_shipment_edit: "أولاً وحدد مهمة لتعديلها!",
    edit_transport_item: "تعديل عنصر النقل",
    update_request: "تحديث طلبك",
    manage_my_shipments: "إدارة الشحنات الخاصة بي",
    transport_item: "عنصر النقل ",
    container: "حاوية",
    pallet: "لوح تحميل",
    box: "صندوق",
    roll: "لفافة",
    other: "أخرى",
    description: "الوصف",
    length: "الطول",
    width: "العرض",
    height: "الارتفاع",
    weight: "الوزن",
    weight_per_item: "وزن كل عنصر",
    cargo_stackable: "سلع غير قابلة للتكديس",
    adr_goods: "يتضمن مواد خطرة",
    all_notifications: "قائمة جميع الإشعارات",
    unread_notifications: "قائمة الإشعارات غير المقروءة",
    unread_only: "الغير مقروءة فقط",
    no_notifications: "ليس لديك أي إشعارات",
    no_notifications_found: "لم يتم العثور على أي إشعارات",
    personal_infos: "معلومات شخصية",
    personal_infos_update: "تحديث معلوماتك الشخصية",
    user_infos: "معلومات المستخدم",
    avatar: "الصورة الرمزية",
    change_avatar: "تغيير الصورة الرمزية",
    cancel_avatar: "إلغاء الصورة الرمزية",
    allowed_files: "أنواع الملفات المسموح بها: png, jpg, jpeg.",
    first_name: "الاسم الأول",
    last_name: "اسم العائلة",
    working_field: "مجال العمل",
    carrier: "الشاحن",
    company: "شركة",
    company_info: "معلومات الشاحن",
    company_name: "اسم الشركة",
    company_location: "موقع الشركة",
    crNumber: "رقم السجل التجاري",
    contact_infos: "معلومات الاتصال",
    no_phone_sharing: "لن نشارك هاتفك أبدًا مع أي شخص آخر.",
    email: "عنوان البريد الإلكتروني",
    no_email_sharing: "لن تتم مشاركة عنوان بريدك الإلكتروني للعامة.",
    bill_history: "سجل فاتورتي",
    bill_completed_shipemnts: "قائمة الشحنات المكتملة",
    no_completed_shipments: "ليس لديك شحنات مكتملة بعد!",
    bills_history: "تاريخ الفواتير",
    bill_details: "تفاصيل الفاتورة",
    active: "(نشطة)",
    loading_document: "جارٍ تحميل المستند ...",
    donwlond_invoice: "تحميل الفاتورة",
    total_amount: "المبلغ الإجمالي",
    included_shipments: "الشحنات المدرجة",
    no_shipments: "لم يتم العثور على شحنات",
    no_bills: "لم يتم العثور على فواتير!",
    no_user_ills:
      "ليس لديك فواتير بعد , يجب أن تكون لديك شحنة واحدة مكتملة على الأقل!",
    no_active_bill: "لا يوجد فاتورة نشطة لهذه الفترة",
    all: "الكل",
    id: "رقم التعريف #",
    km: "كم",
    m: "م",
    cm: "سم",
    kg: "كغ",
    tn: "طن",
    omr: "ر.ع",
    create: "انشاء",
    update: "تحديث",
    confirm: "تأكيد",
    delete: "حذف",
    loading: "جاري التحميل ...",
    cancel: "إلغاء",
    close: "إغلاق",
    saving: "يتم الحفظ ...",
    save_changes: "حفظ التغييرات",
    no_clients: "لم يتم العثور على عملاء",
    no_partners: "لم يتم العثور على شركاء",
    security_standards: "معايير الأمان",
    opal_standard: "معيار OPAL",
    upload_opal_picture: "تحميل مستند OPAL",
    other_standard: "معيار آخر",
    upload_standard_picture: "تحميل مستند المعيار",
    done_shipments: "الشحنات المكتملة",
    rate: "تقييم",
    rate_carrier: "تقييم الناقل",
    rate_desc: "قيم تجربتك مع هذا الناقل",
    rate_message: "اكتب شيئًا عن تجربتك ...",
    shipment_requirements: "متطلبات الشحن",
    shipment_requirements_provided: "متطلبات الشحن المتوفرة",
    clearance: "التخليص",
    insurance: "التأمين",
    warehouse: "المستودع",
    other_requirements: "متطلبات اخرى",
    other_requirements_placeholder:
      "اذكر أي متطلبات أخرى تريد أن تأخذها شركات النقل في الاعتبار (100 حرف كحد أقصى)",
    all_shipments: "جميع الشحنات",
    manage_all_shipments: "إدارة كافة الشحنات",
    recent_shipments: "الشحنات المضافة حديثًا",
    recent_users: "المستخدمون المسجلون مؤخرًا",
    Popular_trucks: "أنواع الشاحنات الأكثر طلبًا",
    shipments_per_status: "الشحنات حسب الحالة",
    popular_workfields: "مجالات العمل الأكثر شعبية",
    users: "مستخدمون",
    workFields: "مجالات العمل",
    all_offers: "جميع العروض",
    manage_partners: "إدارة الشركاء",
    total_partners: "إجمالي الشركاء",
    independent: "مستقل",
    manage_clients: "إدارة العملاء",
    total_clients: "إجمالي العملاء",
    account_type: "نوع الحساب",
    manage_drivers: "إدارة السائقين",
    manage_trucks: "إدارة الشاحنات",
    total_trucks: "إجمالي الشاحنات",
    cta_subtitle: "هل تحتاج إلى شاحنة لنقل البضائع الخاصة بك؟",
    download_our_app: "حمل تطبيقنا الآن!",
    upload_work_certificate: "تحميل شهادة العمل",
    workCertificate: "شهادة العمل",
    picture: "صورة",
    opal: "معيار OPAL",
    standard: "معيار آخر",
    export: "تحميل البيانات",
    profile_updated: "تم تحديث الملف الشخصي بنجاح",
    invalid_token: "رمز منتهي الصلاحية أو غير صالح",
    password_updated: "تم تغيير كلمة المرور الخاصة بك بنجاح!",
    email_sent: "تم إرسال البريد الإلكتروني, يرجى اتباع التعليمات",
    no_user_email: "لم يتم العثور على مستخدم لهذا البريد الإلكتروني",
    email_already_verified: "تم التحقق من البريد الإلكتروني بالفعل",
    email_required: "لا يجب أن يكون البريد الإلكتروني فارغًا!",
    reset_email_sent:
      "من فضلك , اتبع الرابط المرسل إلى عنوان بريدك الإلكتروني لتغيير كلمة المرور الخاصة بك!",
    new_password: "كلمة اﻟﺴﺮ",
    password_placeholder: "أدﺧﻞ ﻛﻠﻤﺔ اﻟﺴﺮ",
    confirm_password: "ﺗﺄﻛﻴﺪ ﻛﻠﻤﺔ اﻟﺴﺮ",
    confirm_password_placeholder: "أدﺧﻞ ﻛﻠﻤﺔ اﻟﺴﺮ ﻣﺮة أﺧﺮى",
    reset_pwd_title: "أوﺑﺲ! ﻧﺴﻴﺖ ﻛﻠﻤﺔ اﻟﺴﺮ اﻟﺨﺎﺻﺔ ﺑﻚ",
    reset_pwd_desc: "أنشئ كلمة المرور الجديدة الآن",
    reset_pwd_submit: "ﺗﻐﻴﻴﺮ ﻛﻠﻤﺔ اﻟﻤﺮور",
    pwd_forgotten_text:
      "فقدت كلمة المرور الخاصة بك؟ الرجاء إدخال عنوان البريد الإلكتروني الخاص بك. سوف تتلقى رابطًا لإنشاء كلمة مرور جديدة.",
    pwd_forgotten_submit: "إعادة تعيين كلمة المرور",
    back_to_login: "رجوع لتسجيل الدخول",
    dispute: "نزاع",
    open_dispute: "فتح نزاع",
    dispute_sent: "تم إرسال النزاع الخاص بك بنجاح",
    solve_dispute: "حل النزاع",
    dispute_issue: "نزاع بسبب:",
    dispute_solved: "تم حل النزاع بنجاح",
    not_received: "لم يتم استلام الشحنة",
    item_missing: "عنصر أو عناصر مفقودة",
    item_broken: "عنصر أو عناصر تالفة",
    comment: "تعقيب",
    mobile_user: "يُرجى استخدام تطبيق الهاتف المحمول لنوع المستخدم هذا",
    select_option: "يرجى تحديد خيار",
    epayment: "بطاقة الائتمان/الخصم",
    account_credit: "رصيد بوصلة",
    account_credit_value: "رصيدك الحالي في بوصلة هو: ",
    offer_amount: "سيتم الخصم من رصيدك: ",
    shipment_not_editable:
      "الإجراء غير مسموح به , ربما تم إلغاء الشحنة أو منحها بالفعل لشركة نقل!",
    credit_insufficient:
      "رصيد بوصلة الخاص بك أقل من مبلغ العرض, يرجى اختيار طريقة أخرى أو الاتصال بالدعم",
    credit_updated: "تم تحديث الرصيد بنجاح",
    manage_credits: "إدارة الأرصدة",
    edit_credit: "تحديث الرصيد",
    amount: "المبلغ",
    transport_agreement: "اتفاقية النقل",
    transport_agreements: "اتفاقيات النقل",
    view_agreement: "عرض اتفاقية النقل",
    agreement_approved: "تمت الموافقة على اتفاقية النقل بنجاح",
    agreement_refused: "تم رفض اتفاقية النقل بنجاح",
    sign_continue: "توقيع تأكيد",
    approve: "موافقة",
    refuse: "رفض",
    approved: "تمت الموافقة عليه",
    refused: "تم رفضه",
    no_agreement_comment:
      "لم تتم إضافة أي تعليق! مما يعني أن السائق وجد العناصر مطابقة لوصف الشحنة",
    decision: "القرار",
    contracts: "العقود",
    total_contracts: "إجمالي العقود",
    new_contract: "عقد جديد",
    no_contracts: "لم يتم العثور على أي عقود",
    upload_contract_file: "تحميل ملف العقد",
    add_contract: "إضافة عقد",
    averageNumberOfShipments: "متوسط عدد الشحنات",
    averageNumberOfShipments_short: "عدد الشحنات",
    period: "الفترة",
    priceOffered: "السعر المعروض",
    priceOfferedRelated: "سعر لـ",
    averageShipmentDistance: "متوسط المسافة التقريبية لكل شحنة",
    averageShipmentDistance_short: "المسافة التقريبية",
    contract: "عقد",
    day: "يوم",
    week: "أسبوع",
    month: "شهر",
    year: "سنة",
    shipment: "شحنة",
    edit_contract: "تعديل العقد",
    ongoing: "مستمرة",
    status: "الحالة",
    contract_details: "تفاصيل العقد",
    contract_null: "تم إلغاء العقد أو عرضه على شركة النقل",
    my_contracts: "عقودي",
    manage_my_contracts: "إدارة عقودي",
    startDate: "تاريخ البدء",
    award_contract: "قبول العرض",
    contractDuration: "مدة العقد",
    available_contracts: "العقود المتاحة",
    browse_contracts: "تصفح كافة العقود المتاحة",
    initial_acceptance: "القبول المبدئي",
    use_same_contract: "استخدم نفس العقد",
    upload_signed_contract: "تحميل العقد الموقع",
    offer_contract: "العقد التابع للعرض",
    client_contract: "العقد المقدم من قبل العميل",
    signed_contract: "العقد الموقع",
    download: "تحميل",
  },
};
