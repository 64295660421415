import { NotificationState, RESET_NOTIFICATIONS, NotificationAction, SET_NOTIFICATION_LOADING, SET_ERROR, SET_SUCCESS, SET_NOTIFICATIONS, SET_NOTIFICATION_READ } from '../types/notification';

const initState: NotificationState = {
    notifications: null,
    loading: false,
    error: '',
    success: '',
    notificationRead: false,
}

const notificationReducer = (state = initState, action: NotificationAction) => {
    switch (action.type) {
        case SET_NOTIFICATION_LOADING:
            return {
                ...state,
                loading: action.payload
            }
        case RESET_NOTIFICATIONS:
            return {
                ...state,
                notifications: null
            }
        case SET_NOTIFICATIONS:
            return {
                ...state,
                notifications: action.payload
            }
        case SET_ERROR:
            return {
                ...state,
                error: action.payload,
            }
        case SET_NOTIFICATION_READ:
            return {
                ...state,
                notificationRead: action.payload
            }
        case SET_SUCCESS:
            return {
                ...state,
                success: action.payload,
            }
        default:
            return state;
    }
}

export default notificationReducer;