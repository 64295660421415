export const SET_LOADING = "SET_LOADING";
export const SET_ERROR = "SET_ERROR";
export const SET_SUCCESS = "SET_SUCCESS";

export interface ContactState {
    loading: boolean;
    error: string;
    success: string;
}

//actions
interface SetErrorAction {
    type: typeof SET_ERROR;
    payload: string;
}

interface SetSuccessAction {
    type: typeof SET_SUCCESS;
    payload: string;
}

interface SetLoadingAction {
    type: typeof SET_LOADING;
    payload: boolean;
}

export type ContactAction = SetLoadingAction | SetSuccessAction | SetErrorAction